import { getGlobalMessagesAtom, removeGlobalMessageAtom } from "@store/globalMessage";
import { useAtomValue, useSetAtom } from "jotai";
import { useEffect } from "react";
import { Message } from "semantic-ui-react";

const GlobalMessage = ({ message }) => {
    const removeMessage = useSetAtom(removeGlobalMessageAtom);

    const formatDate =
        (date) =>
            new Date(date)
                .toLocaleTimeString()
        ;

    useEffect(() => {
        if (!message.meta.autoDismiss) {
            return;
        }

        const messageTimeout =  message.type === "negative" ? 60000 : 10000;
        const timeout = setTimeout(() => {
            removeMessage(message.meta.id);
        }, messageTimeout);

        return () => clearTimeout(timeout);
    }, [
        message.meta.id,
        message.meta.autoDismiss,
        message.type,
        removeMessage,
    ]);

    return (
        <Message
            key={message.meta.id}
            content={`${formatDate(message.meta.added)} - ${message.content}`}
            header={message.header}
            negative={"negative" === message.type}
            info={"info" === message.type}
            positive={"positive" === message.type}
            warning={"warning" === message.type}
            onClick={() => removeMessage(message.meta.id)}
            style={{cursor: "pointer"}}
        />
    );
};

export const GlobalMessages = () => {
    const messages = useAtomValue(getGlobalMessagesAtom);

    if (0 === messages.length) {
        return null;
    }

    return (
        <div style={{ zIndex: 10, position: "fixed", right: "20px", bottom: "20px" }}>
            {messages.map((message) =>
                <GlobalMessage
                    key={message.meta.id}
                    message={message}
                />
            )}
        </div>
    );
};