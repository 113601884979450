import ConfirmationModal from "@components/ConfirmationModal";
import CustomTable from "@components/CustomTable";
import { useFetchEventCollectorTemplates } from "@hooks/useFetchEventCollectorTemplates";
import NMService from "@services/nm.service";
import { addGlobalMessageAtom } from "@store/globalMessage";
import { useSetAtom } from "jotai";
import { useMemo, useState } from "react";
import { Breadcrumb, Button, Divider } from "semantic-ui-react";
import EventCollectorTemplateForm from "./EventCollectorTemplateForm";

const EventCollectorTemplates = () => {
    const addGlobalMessage = useSetAtom(addGlobalMessageAtom);
    const [template, setTemplate] = useState(undefined);
    const [view, setView] = useState(undefined);
    const viewActions = { FORM: 'form', DELETE: 'delete' }

    const {
        data,
        isFetching,
        refetch
    } = useFetchEventCollectorTemplates({
        options: {
            refetchOnMount: "always",
        },
    });

    const columns = useMemo(
        () => [
            {
                id: "name",
                Header: "Name",
                accessor: "name"
            },
            {
                id: "description",
                Header: "Description",
                accessor: 'description'
            },
            {
                id: "regexlist",
                Header: "RegEx list",
                accessor: d => d?.regexlist?.join(", ") || "-"
            },
        ],
        []
    );
    const tableHooks = (hooks) => {
        hooks.visibleColumns.push((columns) => [
            ...columns,
            {
                Header: () => null,
                id: "actions",
                Cell: ({ row }) =>
                    <div style={{ textAlign: 'center' }}>
                        <Button
                            circular
                            icon="settings"
                            type="button"
                            onClick={() => {
                                setTemplate(row.original);
                                setView(viewActions.FORM)
                            }}
                        />
                        <Button
                            circular
                            icon="trash"
                            type="button"
                            onClick={() => {
                                setTemplate(row.original);
                                setView(viewActions.DELETE)
                            }}
                        />
                    </div>,
                width: 2,
                disableGlobalFilter: true,
                disableFilters: true,
            },
        ]);
    };

    const deleteTemplate = () => {
        NMService.deleteECTemplate(template.uuid)
            .then((r) =>
                addGlobalMessage({
                    header: `Template ${template.name} deleted successfully`,
                    content: "Template deleted successfully",
                    type: "positive",
                })
            )
            .catch(e => null)
            .finally(() => {
                setView(undefined)
                setTemplate(undefined);
                refetch()
            });
    };

    return (
        <>
            <Breadcrumb>
                <Breadcrumb.Section>Event collector</Breadcrumb.Section>
                <Breadcrumb.Divider>/</Breadcrumb.Divider>
                <Breadcrumb.Section active>Templates</Breadcrumb.Section>
            </Breadcrumb>
            <Divider clearing />
            <CustomTable
                key={isFetching}
                data={data}
                active={template?.uuid}
                columns={columns}
                hideSearch={true}
                tableHooks={tableHooks}
                footerButton={() =>
                    <Button
                        floated="right"
                        primary
                        size="tiny"
                        type="button"
                        content="Add new template"
                        onClick={() => {
                            setTemplate(undefined);
                            setView(viewActions.FORM)
                        }}
                    />
                }
            />

            {view === viewActions.DELETE && (
                <ConfirmationModal
                    open={!!template?.name}
                    header="Delete template?"
                    content={`Are you sure you want to delete template: ${template?.name}`}
                    onConfirm={() => {
                        data.splice(template.id, 1);
                        deleteTemplate();
                    }}
                    onDismiss={() => setView(undefined)}
                />
            )}

            {view === viewActions.FORM && (
                <EventCollectorTemplateForm
                    key={`${template?.uuid || "new"}`}
                    data={template}
                    close={() => {
                        refetch()
                        setView(undefined)
                        setTemplate(undefined);
                    }}
                />
            )}
        </>)

}

export default EventCollectorTemplates;