import ConfirmationModal from '@components/ConfirmationModal';
import { useUserInfo } from '@hooks/useAuth';
import { useFetchVisPanelTemplates } from '@hooks/useFetchVisPanelTemplates';
import NMService from '@services/nm.service';
import { useState } from 'react';
import { Button, Divider, Header, Icon, Table } from 'semantic-ui-react';
import VisualizationPanelTemplateForm from './VisualizationPanelTemplateForm';

const VisualizationPanelTemplates = (props) => {
    const { userdata: { role, tenantid } } = useUserInfo();
    const [state, setState] = useState({
        selectedVisPanelTemplateId: '',
        showSegment: false,
        showForm: false,
        showDeleteModal: false,
    });

    const { data, isLoading, refetch } = useFetchVisPanelTemplates({
        moduleId: props.moduleId,
        options: {
            refetchOnMount: 'always',
        }
    });

    const deleteVisPanelTemplate = () => {
        NMService.deleteSWModuleVisPanelTemplate(props.moduleId, state.selectedVisPanelTemplateId).then(() =>
                setState({
                    showDeleteModal: false,
                    showSegment: true,
                    showForm: false,
                    selectedVisPanelTemplateId: '',
                })).catch(e => null)
            .finally(() => refetch());
    }

    const enabled = (global) => 
        (role === 'tenantadmin'
            && ((tenantid === "public" && !!(global ?? true))
            || (tenantid !== "public" && !!!(global ?? false))))
        || (role === 'admin' && !!!(global ?? false))

    return (isLoading ? null :
        <>
            <Header
                as="h4"
                style={{ cursor: 'pointer', marginTop: '1rem', marginBottom: '0' }}
                onClick={() => setState(p => ({ ...p, showSegment: !p.showSegment }))}
            >
                Visualization panel templates
                <span>
                    <Icon
                        name="add"
                        color="blue"
                        disabled={!enabled(undefined)}
                        style={{ zIndex: 5, marginLeft: '1rem', marginRight: '0' }}
                        onClick={(e) => {
                            e.stopPropagation()
                            setState(p => ({
                                ...p,
                                showForm: true,
                                showSegment: true,
                                selectedVisPanelTemplateId: '',
                            }))
                        }}
                    />
                </span>
                <Icon
                    name={`caret ${state.showSegment ? 'up' : 'down'}`}
                    size="large"
                    style={{ float: "right", marginRight: "0" }}
                />
            </Header>
            <Divider style={{ marginTop: '0.5rem', marginBottom: '0.5rem' }} />
            {state.showSegment && data.length > 0 && (
                <Table singleline="true" collapsing style={{ marginLeft: "2rem" }}>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell width={1}/>
                            <Table.HeaderCell>Panel template ID</Table.HeaderCell>
                            <Table.HeaderCell>Panel template name</Table.HeaderCell>
                            <Table.HeaderCell width={2}>Action</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>

                    <Table.Body>
                        {data.map((row) => (
                            <Table.Row key={row.templateid}>
                                <Table.Cell>
                                    {row?.global &&
                                        <Icon
                                            name="globe"
                                            circular
                                            inverted
                                            title="Global template"
                                            color='green'
                                            disabled
                                        />
                                    }
                                </Table.Cell>
                                <Table.Cell>{row.templateid}</Table.Cell>
                                <Table.Cell>{row.templatename}</Table.Cell>
                                <Table.Cell>
                                    <Button
                                        circular
                                        icon='edit'
                                        size='tiny'
                                        onClick={() => {
                                            setState((prevState) => ({
                                                ...prevState,
                                                showForm: true,
                                                selectedVisPanelTemplateId: row.templateid,
                                            }));
                                        }}
                                    />
                                    <Button
                                        circular
                                        icon='trash'
                                        size='tiny'
                                        disabled={!enabled(row?.global)}
                                        onClick={() => {
                                            setState({
                                                showDeleteModal: true,
                                                showSegment: true,
                                                showForm: false,
                                                selectedVisPanelTemplateId: row.templateid,
                                            });
                                        }}
                                    />
                                </Table.Cell>
                            </Table.Row>
                        ))}
                    </Table.Body>
                </Table>
            )}
            
            {state.showSegment && (state.showForm || data.length === 0) && (
                <VisualizationPanelTemplateForm
                    key={state.selectedVisPanelTemplateId}
                    moduleid={props.moduleId}
                    version={props.version}
                    disabled={!enabled(data.find(e => e.templateid === state.selectedVisPanelTemplateId)?.global)}
                    selectedVisPanelTemplateId={state.selectedVisPanelTemplateId}
                    visPanelTemplates={data}
                    closeForm={() => setState(prevState => ({ ...prevState, showForm: false, selectedVisPanelTemplateId: '' }))}
                    refreshDashboards={refetch}
                />
            )}

            <ConfirmationModal
                open={state.showDeleteModal && state.selectedVisPanelTemplateId}
                header="Delete panel template"
                content="Are you sure you want to delete selected visualization panel template?"
                onConfirm={deleteVisPanelTemplate}
                onDismiss={() =>
                    setState({
                        showDeleteModal: false,
                        showSegment: true,
                        showForm: false,
                        selectedVisPanelTemplateId: '',
                    })}
            />
        </>
    );
}

export default VisualizationPanelTemplates;
