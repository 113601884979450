import ConfirmationModal from '@components/ConfirmationModal';
import NMService from '@services/nm.service';
import { addGlobalMessageAtom } from '@store/globalMessage';
import { useSetAtom } from 'jotai';
import { useEffect, useReducer } from 'react';
import { Breadcrumb, Button, Divider } from 'semantic-ui-react';
import DhcpSubnetForm from './DhcpSubnetForm';
import DhcpSubnetTable from './DhcpSubnetTable';

const reducer = (state, action) => {
    switch (action.type) {
        case 'setState':
            return { ...state, ...action.payload };
        default:
            throw new Error();
    }
};

const DhcpSubnets = (props) => {
    const addGlobalMessage = useSetAtom(addGlobalMessageAtom);
    const [state, dispatchLocal] = useReducer(reducer, {
        subnets: [],
        selectedSubnet: {},
        showForm: false,
        showDeleteModal: false,
    });

    useEffect(() => {
        fetchDhcpSubnets();
    }, []);

    const fetchDhcpSubnets = async () =>
        await NMService.getDhcpSubnets().then((response) => {
            let subnets = [];
            try {
                subnets = response.data
                    .map((e) => {
                        const { enabled, relay, pools, optiondata, ...rest } = e;
                        let parsedRelay = {};
                        try {
                            parsedRelay['ip-addresses'] = relay.map((ip) => ({ var: ip }));
                        } catch {
                            parsedRelay = { 'ip-addresses': [] }
                        }

                        let parsedOptionData = [];
                        try {
                            parsedOptionData = JSON.parse(optiondata);
                        } catch {
                            parsedOptionData = []
                        }

                        return {
                            ...rest,
                            relay: parsedRelay,
                            pools: pools.map((pool) => {
                                const [startIpAddress, endIpAddress] = pool.split('-');
                                return { startIpAddress: startIpAddress, endIpAddress: endIpAddress }
                            }),
                            optiondata: parsedOptionData,
                            enabled: enabled ? true : false,
                        };
                    }).sort((a, b) => a.subnet > b.subnet ? -1 : (a.subnet < b.subnet ? 1 : 0))
            } catch {
                subnets = [];
            }

            dispatchLocal({
                type: 'setState',
                payload: {
                    subnets: subnets,
                    selectedSubnet: {},
                    showForm: false,
                    showDeleteModal: false,
                },
            });
        }).catch(e => null)

    const toggle = (selectedSubnet = {}, showForm = false, showDeleteModal = false) =>
        dispatchLocal({
            type: 'setState',
            payload: {
                selectedSubnet: selectedSubnet,
                showDeleteModal: showDeleteModal,
                showForm: showForm,
            },
        });

    const deleteSubnet = () =>
        NMService.deleteDhcpSubnet(state.selectedSubnet.uuid).then(() =>
            addGlobalMessage({
                header: 'DHCP option data is deleted',
                content: 'DHCP option data is successfully deleted',
                type: 'positive',
            })).then(() => fetchDhcpSubnets())
            .catch(e => null)

    return (
        <>
            <Breadcrumb style={{ marginTop: '1rem' }}>
                <Breadcrumb.Section>Configuration</Breadcrumb.Section>
                <Breadcrumb.Divider/>
                <Breadcrumb.Section>DHCP</Breadcrumb.Section>
                <Breadcrumb.Divider/>
                <Breadcrumb.Section active>Subnets</Breadcrumb.Section>
            </Breadcrumb>
            <Divider clearing style={{ marginBottom: '1.5rem' }} />
            <DhcpSubnetTable
                data={state.subnets}
                toggle={toggle}
                selected={state?.selectedSubnet}
                addNew={() => 
                    <Button
                        type="button"
                        floated='right'
                        primary
                        size="tiny"
                        content="Add new subnet"
                        onClick={() => {
                            dispatchLocal({
                                type: 'setState',
                                payload: { selectedSubnet: {}, showForm: 'add' }
                            });
                        }}
                    />
                }
            />
            {state.showForm && (
                <DhcpSubnetForm
                    key={state.selectedSubnet.uuid || "new_subnet"}
                    type={state.showForm}
                    data={state.selectedSubnet}
                    toggle={toggle}
                    updateData={fetchDhcpSubnets}
                />
            )}
            <ConfirmationModal
                open={state.showDeleteModal}
                header="Delete DHCP subnet"
                content="Are you sure you want to delete subnet?"
                onConfirm={() => deleteSubnet()}
                onDismiss={() => toggle()}
            />
        </>
    );
};

export default DhcpSubnets;