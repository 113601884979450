import { PermissionsGate } from '@layouts/PermissionGate';
import { editProbeAtom } from '@store/editProbe';
import { useAtomValue } from 'jotai';
import { useMemo } from 'react';
import { Button, Table } from 'semantic-ui-react';

export default function NamespaceRouteTable(props) {
    const probeAtomState = useAtomValue(editProbeAtom);
    const routes = useMemo(() => props.data, [props.data])

    return (
        <Table celled compact>
            <Table.Header>
                <Table.Row textAlign="center">
                    <Table.HeaderCell width="4">Destination</Table.HeaderCell>
                    <Table.HeaderCell width="4">Via</Table.HeaderCell>
                    <Table.HeaderCell width="3">Dev</Table.HeaderCell>
                    <Table.HeaderCell width="3">Metric</Table.HeaderCell>
                    <Table.HeaderCell width="2">Actions</Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {routes.length > 0 ? (
                    routes.map((e, index) => (
                        <Table.Row key={`${e.name}_${index}`} textAlign="center">
                            <Table.Cell>{e.destination}</Table.Cell>
                            <Table.Cell>{e.via || "-"}</Table.Cell>
                            <Table.Cell>{e.dev || "-"}</Table.Cell>
                            <Table.Cell>{e.metric}</Table.Cell>
                            <Table.Cell style={{ padding: "10px 0 10px 10px" }} >
                                <PermissionsGate hasPermission={probeAtomState?.permissions?.editbasicdata}>
                                    <Button
                                        style={{ margin: "0 1px" }}
                                        circular
                                        icon="settings"
                                        size="mini"
                                        onClick={() => props.toggleForm('edit', e)}
                                    />
                                </PermissionsGate>
                                <PermissionsGate hasPermission={probeAtomState?.permissions?.editbasicdata}>
                                    <Button
                                        style={{ margin: "0 1px" }}
                                        circular
                                        icon="trash"
                                        size="mini"
                                        onClick={() => props.deleteRoute(true, e)}
                                    />
                                </PermissionsGate>
                            </Table.Cell>
                        </Table.Row>
                    ))
                ) : (
                    <Table.Row textAlign="center">
                        <Table.Cell colSpan="100%">No data available in table</Table.Cell>
                    </Table.Row>
                )}
            </Table.Body>

            <Table.Footer>
                <Table.Row>
                    <Table.HeaderCell colSpan="7" style={{ padding: '0.5rem' }}>
                        <PermissionsGate hasPermission={probeAtomState?.permissions?.editbasicdata}>
                            <Button primary size="tiny" floated="right" onClick={() => props.toggleForm('add')}>
                                Add new route
                            </Button>
                        </PermissionsGate>
                    </Table.HeaderCell>
                </Table.Row>
            </Table.Footer>
        </Table>
    );
}
