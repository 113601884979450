import ErrorPopup from "@components/ErrorPopup";
import { Checkbox, Dropdown, Form, Icon, Input, Label } from "semantic-ui-react";

export const useFormFields = ({ register, errors, setValue, watch }) => {
    const get = (from, s) =>
           s.replace(/\[([^\[\]]*)\]/g, '.$1.') //eslint-disable-line
            .split('.')
            .filter(t => t !== '')
            .reduce((prev, curr) => prev && prev[curr], from)

    // "o = {}" argument defaults:
    // validate = null, notRequired = false,    width = null,       inputType = "text"
    // infoText = null  disabled = false,       readOnly = false,   decimal = false
    // unit = null,     min = null,             max = null,         mono = false
    // rows = 4,        hideLabel = true        unitLeft = false
    const renderInput = (label, accessor, o = {}) => (
        <Form.Input
            key={accessor}
            required={!!!o.notRequired}
            style={{ margin: 0, padding: 0 }}
            error={Boolean(get(errors, accessor))}
            width={o.width ?? null}
        >
            <span style={{ display: "inline-block", width: "100%" }}>
                {!!o.infoText && (
                    <Icon
                        style={{ display: "inline-block", maxHeight: "19px", maxWidth: "19px" }}
                        name="question"
                        size="small"
                        title={o.infoText}
                        circular
                    />
                )}
                {!!o.hideLabel ? null :
                    <>
                        {`${label}${!!o.readOnly ? " (read only)" : ''}`}&ensp;
                        {!!!o.notRequired && !!!o.readOnly && <span style={{ color: "red" }}>*</span>}
                        {get(errors, accessor) && !!get(errors, accessor).message && (
                            <ErrorPopup content={get(errors, accessor).message}/>
                        )}
                    </>}
                <Input
                    labelPosition={!!o.unit ? (!!o.unitLeft ? 'left' : "right") : null}
                    disabled={!!o.disabled}
                    readOnly={!!o.readOnly}
                >
                    {!!o.unit && !!o.unitLeft && <Label basic content={o.unit} />}
                    {o.inputType === "textarea" ? (
                        <textarea
                            {...register(accessor, { validate: o.validate ?? null, required: !!!o.notRequired })}
                            placeholder={label}
                            rows={o.rows ?? 4}
                            style={!!o.mono ? { fontFamily: 'monospace, monospace' } : null}
                        />
                    ) : (
                        <input
                            {...register(accessor, { validate: o.validate ?? null, required: !!!o.notRequired, valueAsNumber: o.inputType === 'number' })}
                            placeholder={label}
                            type={o.inputType ?? 'text'}
                            min={o.min ?? null}
                            max={o.max ?? null}
                            step={!!o.decimal ? "any" : null}
                        />
                    )}
                    {!!o.unit && !!!o.unitLeft && <Label basic content={o.unit} />}
                </Input>
                {!!o.hideLabel ?
                    <>
                        {get(errors, accessor) && !!get(errors, accessor).message && (
                            <ErrorPopup content={get(errors, accessor).message} style={{ position: 'absolute', top: '22%', right: !!o.unit ? '2.375rem' : '0.375rem' }}/>
                        )}
                    </> : null}
            </span>
        </Form.Input>
    );

    // "o = {}" argument defaults:
    // toggle = false,  width = null,   infoText = null,    disabled = false    radio = false
    const renderCheckbox = (label, accessor, o = {}) => (
        <Form.Input key={accessor + label} error={Boolean(get(errors, accessor))} width={o.width ?? null}>
            <span style={{ display: "inline-block", width: "100%" }}>
                {!!o.infoText && (
                    <Icon
                        style={{ display: "inline-block" }}
                        name="question"
                        size="small"
                        title={o.infoText}
                        circular
                    />
                )}
                {label && !!!o.radio ? <>{label} <br/></> : null}
                <Checkbox
                    radio={!!o.radio}
                    label={!!o.radio ? label : null}
                    style={{ marginTop: '0.5rem' }}
                    disabled={!!o.disabled}
                    toggle={!!o.toggle}
                    {...register(accessor)}
                    type={"checkbox"}
                    onClick={(e) => setValue(accessor, !!o.radio ? o.checked : !watch(accessor))}
                    checked={!!o.checked ? watch(accessor) === o.checked : watch(accessor)}
                />
            </span>
        </Form.Input>
    );

    // "o = {}" argument defaults:
    // disabled = false,    notRequired = false,     width = null,      infoText = null,
    // multiple = false,    clearable = false,       hideLabel = false  noPlaceholder = false
    // onChange = undefined
    const renderDropdown = (label, accessor, options, o = {}) => (
        <Form.Field key={accessor} width={o.width ?? null} error={Boolean(get(errors, accessor))}>
            {!!o.hideLabel ? null :
                <span style={{ display: "inline-block", width: "100%" }}>
                    {!!o.infoText && (
                        <Icon
                            style={{ display: "inline-block" }}
                            name="question"
                            size="small"
                            title={o.infoText}
                            circular
                        />
                    )}
                    {label}&ensp;
                    {!!!o.notRequired && <span style={{ color: "red" }}>*</span>}
                    {get(errors, accessor) && !!get(errors, accessor).message && (
                        <ErrorPopup content={get(errors, accessor).message}/>
                    )}
                </span>}
            <Dropdown
                {...register(accessor, { required: !!!o.notRequired })}
                onChange={(e, { value }) => { if (o.onChange !== undefined){ o.onChange() } setValue(accessor, value)}}
                required={!!!o.notRequired}
                fluid
                disabled={!!o.disabled || options.length === 0}
                placeholder={!!o.noPlaceholder ? null : options.length === 0 ? "No options available" : "Select option..."}
                selection
                multiple={!!o.multiple}
                clearable={!!o.multiple || !!o.clearable}
                selectOnBlur={false}
                value={watch(accessor)}
                options={options || []}
            />
        </Form.Field>
    );

    return {
        renderInput,
        renderDropdown,
        renderCheckbox,
    };
};
