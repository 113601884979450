import NMService from "@services/nm.service";
import { useQuery } from "@tanstack/react-query";

export const useFetchResponders = ({
    probeid,
    moduleid,
    select,
    enabled,
    options
} = {}) => {
    const query = useQuery({
        queryKey: ['getResponders', probeid, moduleid],
        queryFn: () => 
            NMService.getResponderList(moduleid, probeid)
            .then(r => r.data)
            .catch(e => []),
        // enabled: ["twamp", "twampmulti", "networkbwclient", "csvoice", "smsprobe"].some(e => moduleid === e) && !!probeid && !!moduleid,
        staleTime: Infinity,
        select,
        enabled: enabled,
        ...options
    })
    return { ...query, data: query.data ?? [] }
}