import ConfirmationModal from '@components/ConfirmationModal';
import NMService from '@services/nm.service';
import { addGlobalMessageAtom } from '@store/globalMessage';
import { managedNodesAtom } from '@store/managedNodes';
import { useAtom, useSetAtom } from 'jotai';
import { useMemo } from 'react';
import ManagedProbesTable from './ManagedProbesTable';

const ManagedProbes = (props) => {
    const data = useMemo(() => props.data || [], [props.data]);
    const [state, dispatch] = useAtom(managedNodesAtom);
    const addGlobalMessage = useSetAtom(addGlobalMessageAtom);

    const reloadNode = async () => {
        await NMService.executeOnDemand({
            nodeid: state.selectedNodeData.id,
            command: 'reboot'
        }).then(() => {
            dispatch({ type: 'close-modal', value: {} })
            addGlobalMessage({
                header: 'Node was reloaded successfully!',
                content: `Node with HW ID "${state.selectedNodeData.hwid}" was reloaded.`,
                type: 'positive',
            })
            props.refetch();
        }).catch(e => null)
    }

    const deleteNode = async () => {
        await NMService.deleteNode(state.selectedNodeData.id).then(() => {
            dispatch({ type: 'close-modal', value: {} })
            addGlobalMessage({
                header: 'Node deleted successfully!',
                content: `Node with HW ID "${state.selectedNodeData.hwid}" is unmanaged now.`,
                type: 'positive',
            })
            props.refetch();
        }).catch(e => null)
    }

    return (
        <>
            <ManagedProbesTable
                type={props.type}
                new={props.new}
                data={data}
                refreshData={props.refetch}
            />

            <ConfirmationModal
                open={state.showModal === 'delete'}
                header="Delete node"
                content={`Are you sure you want to delete node with ID ${state.selectedNodeData.id}?`}
                onConfirm={deleteNode}
                onDismiss={() => dispatch({ type: 'close-modal', value: {} })}
            />

            <ConfirmationModal
                open={state.showModal === 'reload'}
                header="Reload node"
                content={`Are you sure you want to reload node with ID ${state.selectedNodeData.id}?`}
                onConfirm={reloadNode}
                onDismiss={() => dispatch({ type: 'close-modal', value: {} })}
            />
        </>
    );
}



export default ManagedProbes;
