import CustomHeader from "@components/CustomHeader";
import { yupResolver } from "@hookform/resolvers/yup";
import { useFetchProbeModule } from "@hooks/useFetchProbeModule";
import { useFetchSnmpHostGroup } from "@hooks/useFetchSnmpHostGroup";
import { useFetchSnmpSecurityProfile } from "@hooks/useFetchSnmpSecurityProfile";
import { useFetchSnmpTemplate } from "@hooks/useFetchSnmpTemplate";
import { useFormFields } from "@hooks/useFormFields";
import { PermissionsGate } from "@layouts/PermissionGate";
import NMService from "@services/nm.service";
import { snmpActions, snmpAtom } from "@store/snmpModule";
import { useQuery } from "@tanstack/react-query";
import { prepareConfig } from "@utils/configurator/prepareConfig";
import { useAtom } from "jotai";
import _ from 'lodash';
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { Form, Grid, Header, Icon, Segment } from "semantic-ui-react";
import * as yup from 'yup';
import SnmpTable from "./SnmpTable";


const SnmpFormSchema = yup.object().shape({
    default_snmp_version: yup.string()
        .required("Field is required."),
    default_community: yup.string()
        .required("Field is required."),
    default_period: yup.number()
        .required("Field is required.")
        .min(30, "Field length should be greater than 30.")
        .max(172800, "Field should be less than 172800."),
    default_port: yup.number()
        .required("Field is required.")
        .min(1, "Field length should be greater than 1.")
        .max(65535, "Field length should be less than 65535."),
    auto_black_list_repeats: yup.number()
        .required("Field is required.")
        .min(1, "Field should be greater than 1.")
        .max(128, "Valueshould be less than 128."),
    auto_black_list_duration: yup.number()
        .required("Field is required.")
        .min(1800, "Field should be greater than 1800.")
        .max(604800, "Field should be less than 604800."),
    workers: yup.number()
        .required("Field is required.")
        .min(1, "Field should be greater than 1.")
        .max(4096, "Field should be less than 4096."),
    timeout: yup.number()
        .required("Field is required.")
        .min(1, "Field should be greater than 1.")
        .max(60, "Field should be less than 60."),
    name: yup.string().when('isHostVisible', {
        is: (exists) => !!exists,
        then: (schema) => schema
            .required("Field is required."),
    }),
    host: yup.string().when('isHostVisible', {
        is: (exists) => !!exists,
        then: (schema) => schema
            .required("Field is required."),
    }),
});


const SnmpModule = (props) => {
    const [show, setShow] = useState(true);
    const [state, dispatch] = useAtom(snmpAtom);
    const { AddHost, EditHost, DeleteHost, ClearShowType } = snmpActions;
    const { probeid, moduleid, instanceid, hardlinked, hasPermission } = props;
    const methods = useForm({
        resolver: yupResolver(SnmpFormSchema)
    });
    const {
        register,
        setValue,
        getValues,
        handleSubmit,
        reset,
        watch,
        formState: { errors },
    } = methods;
    const { renderInput, renderDropdown, renderCheckbox } = useFormFields({ register, errors, setValue, watch });

    const hostsWatch = watch('hosts') || [];
    const specifyPingWatch = watch('specify_ping');
    const snmpVersions = [{ key: 'v1', value: 'v1', text: 'v1' }, { key: 'v2c', value: 'v2c', text: 'v2c' }, { key: 'v3', value: 'v3', text: 'v3' }]

    const {
        data: nodeModuleData,
        isLoading: nodeModuleIsLoading,
        refetch: refetchNodeModuleData
    } = useFetchProbeModule({
        probeid,
        moduleid,
        instanceid,
        enabled: !props.template,
        select: (data) => {
            if (data && data.hasOwnProperty('config')) {
                const config = JSON.parse(data.config);
                return config;
            }
            return {}
        },
        options: {
            refetchOnMount: 'always'
        }
    });

    const {
        data: templateModuleData = {},
        isLoading: templateModuleIsLoading,
        refetch: refetchTemplateModuleData,
    } = useQuery({
        queryKey: ['getNMTemplateModule', probeid, moduleid, instanceid],
        queryFn: () =>
                NMService.getNMTemplateModule(probeid, moduleid, instanceid)
                .then(r => r.data)
                .catch(e => {}),
        enabled: !!props.template && !!probeid && !!moduleid && !!instanceid,
        refetchOnMount: 'always'
    });

    const {
        data: snmpTemplates = [],
        isLoading: snmpTemplatesIsLoading,
    } = useFetchSnmpTemplate({
        options: {
            refetchOnMount: 'always'
        },
        select: (e) => (e || []).map(e => ({ key: e.template_name, value: e.template_name, text: e.template_name }))});

    const {
        data: snmpHostGroups = [],
        isLoading: snmpHostGroupsIsLoading,
    } = useFetchSnmpHostGroup({
        options: {
            refetchOnMount: 'always'
        },
        select: (e) => (e || []).map(e => ({ key: e.uuid, value: e.name, text: e.name }))
    });

    const {
        data: snmpSecurityProfiles = [],
        isLoading: snmpSecurityProfilesIsLoading,
    } = useFetchSnmpSecurityProfile({
        options: {
            refetchOnMount: 'always'
        },
        select: (e) => (e || []).map(e => ({ key: e.uuid, value: e.name, text: e.name }))
    });

    const templateSubmit = (values) =>
        NMService.updateNMTemplateModuleConfig(values).then(() => {
            props.formSubmitted()
            refetchTemplateModuleData()
        }).catch(e => null)

    const onSubmit = async (values) => {
        const { name, host, group, security_profile, templates, port, community, snmp_version, check_ping, ...rest } = values;
        let { hosts = [], isHostVisible, specify_ping, ...common } = rest;

        const snmpHost = {
            name, host, group, security_profile, templates, port, community, snmp_version,
            ...specifyPingWatch && { check_ping: check_ping }
        };

        if (state.showType === EditHost) {
            const foundIndex = hosts.findIndex(e => e.host === state.selectedHost.host);
            if (foundIndex > -1) {
                hosts[foundIndex] = snmpHost;
            } else {
                hosts.push(snmpHost)
            }
        } else if (state.showType === AddHost) {
            hosts.push(snmpHost)
        }

        const config = prepareConfig({ ...common, hosts });

        if (_.isEqual(config, props.template ? prepareConfig(JSON.parse(templateModuleData.config || '{}')) : prepareConfig(nodeModuleData))) {
            props.noConfigChangeMessage()
            return
        }

        let data;
        if (props.template) {
            data = {
                templateid: props.probeid,
                moduleid: props.moduleid,
                instanceid: props.instanceid,
                config: JSON.stringify(config),
            }
        } else {
            data = {
                nodeid: props.probeid,
                moduleid: props.moduleid,
                instanceid: props.instanceid,
                config: JSON.stringify(config),
            }
        }
        props.template ? templateSubmit(data) : await props.onFormSubmit(data);
        props.template ? await refetchTemplateModuleData() : await refetchNodeModuleData();
        dispatch({ type: ClearShowType })
    };

    const isLoading = (templateModuleIsLoading && nodeModuleIsLoading) || snmpHostGroupsIsLoading || snmpTemplatesIsLoading || snmpSecurityProfilesIsLoading;

    useEffect(() => {
        if (!(nodeModuleIsLoading && templateModuleIsLoading)) {
            let data = props.template ? JSON.parse(templateModuleData.config || '{}') : nodeModuleData;
            const {
                default_snmp_version,
                default_period,
                default_port,
                auto_black_list_repeats,
                auto_black_list_duration,
                workers,
                timeout
            } = data;

            reset({
                ...data,
                default_snmp_version: default_snmp_version || 'v2c',
                default_period: default_period || 300,
                default_port: default_port || 161,
                auto_black_list_repeats: auto_black_list_repeats || 5,
                auto_black_list_duration: auto_black_list_duration || 3600,
                workers: workers || 32,
                timeout: timeout || 8,
                
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [nodeModuleIsLoading, templateModuleIsLoading]);

    useEffect(() => {
        const values = getValues();
        const check_ping = state.selectedHost.check_ping;
        const specify_ping = (check_ping === false || check_ping === true) ? true : false;

        if (state.showType === DeleteHost) {
            const isHostVisible = getValues('isHostVisible');
            const filteredHosts = nodeModuleData.hosts.filter(e => e.host !== state.selectedHost.host);
            reset({ ...values, specify_ping, hosts: filteredHosts, isHostVisible: isHostVisible ?? false, })
        }

        if (state.showType === EditHost) {
            reset({ ...values, ...state.selectedHost, specify_ping, isHostVisible: true, })
        }

        if (state.showType === AddHost) {
            reset({
                ...values,
                specify_ping,
                isHostVisible: true,
                name: "",
                host: "",
                group: "",
                security_profile: "",
                templates: [],
                port: 0,
                community: "",
                snmp_version: ""
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.showType, state.selectedHost]);

    return isLoading ? null : (
        <>
            <CustomHeader
                title='Common parameters'
                toggle={() => setShow(p => !p)}
                show={show}
            />
            <FormProvider {...methods}>
                <Form hidden={!show} onSubmit={handleSubmit(onSubmit)} style={{ pointerEvents: !hardlinked ? 'auto' : 'none' ,marginLeft: '1rem', marginRight: '1rem' }}>
                    <Form.Group widths={5}>
                        {renderDropdown('Default SNMP version', 'default_snmp_version', snmpVersions)}
                        {renderInput('Default community', 'default_community')}
                    </Form.Group>
                    <Form.Group widths={5}>
                        {renderInput('Workers', 'workers', { inputType: 'number', decimal: true })}
                        {renderInput('Timeout', 'timeout', { inputType: 'number' })}
                    </Form.Group>
                    <Form.Group widths={5}>
                        {renderInput('Default period', 'default_period', { inputType: 'number', decimal: true })}
                        {renderInput('Default port', 'default_port', { inputType: 'number' })}
                        {renderInput('Auto black list repeats', 'auto_black_list_repeats', { inputType: 'number' })}
                        {renderInput('Auto black list duration', 'auto_black_list_duration', { inputType: 'number' })}
                    </Form.Group>
                    <Grid>
                        <Grid.Row>
                            <Grid.Column>
                                <Segment basic style={{ padding: '0.5rem 0', marginTop: '2rem' }}>
                                    <SnmpTable data={hostsWatch ?? []} refetch={props.template ? refetchTemplateModuleData : refetchNodeModuleData} />
                                </Segment>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                    {[AddHost, EditHost].includes(state.showType) &&
                        <>
                            <Header as="h4" dividing>
                                {(state.showType === AddHost ? 'Add new' : 'Edit') + ' host'}
                                <span>
                                    <Icon
                                        link
                                        name={"close"}
                                        style={{ float: "right" }}
                                        onClick={() => dispatch({ type: ClearShowType })}
                                    />
                                </span>
                            </Header>
                            <Form.Group widths={5}>
                                {renderInput('Name', 'name')}
                                {renderInput('Address', 'host', { disabled: state.showType === EditHost })}
                                {renderDropdown('Group', 'group', snmpHostGroups, { notRequired: true })}
                            </Form.Group>
                            <Form.Group>
                                {renderDropdown('Security profile', 'security_profile', snmpSecurityProfiles, { width: 4, notRequired: true })}
                                {renderDropdown('Templates', 'templates', snmpTemplates, { multiple: true, width: 4, notRequired: true })}
                                {renderInput('Port', 'port', { inputType: 'number', width: 2, notRequired: true })}
                                {renderInput('Community', 'community', { width: 3, notRequired: true })}
                                {renderDropdown('SNMP version', 'snmp_version', snmpVersions, { width: 3, notRequired: true })}
                            </Form.Group>
                            <Form.Group widths={6}>
                                {renderCheckbox("Specify ping", "specify_ping", { toggle: true })}
                                {specifyPingWatch && renderCheckbox("Check ping", "check_ping")}
                            </Form.Group>
                        </>}
                    {!hardlinked ?
                        <Form.Group>
                            <PermissionsGate hasPermission={hasPermission}>
                                <Form.Button
                                    type="submit"
                                    size="small"
                                    primary
                                    content='Update'
                                />
                            </PermissionsGate>
                            <PermissionsGate hasPermission={hasPermission}>
                                <Form.Button
                                    type="button"
                                    size="small"
                                    content='Reset'
                                    onClick={props.template ? refetchTemplateModuleData : refetchNodeModuleData}
                                />
                            </PermissionsGate>
                            <Form.Button
                                type="button"
                                size="small"
                                content="Switch view"
                                onClick={props.switchView}
                            />
                        </Form.Group>: null}
                </Form>
            </FormProvider>
        </>
    )
}

export default SnmpModule;