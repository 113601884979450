import DateFormat from '@components/DateFormat';
import { AnchorStyleButton } from '@layouts/AnchorStyleButton';
import { InstaceActions, multiTenantAtom } from '@store/multiTenant';
import { useAtom } from 'jotai';
import { useMemo } from 'react';
import { useFilters, useGlobalFilter, usePagination, useTable } from 'react-table';
import { Button, Dropdown, Grid, Header, HeaderSubheader, Icon, Menu, Popup, Table } from 'semantic-ui-react';

const MultiTenantTable = (props) => {
    const [{selectedTenantUuid}, dispatch] = useAtom(multiTenantAtom);
    const data = useMemo(() => props.data, [ props.data ]);
    const columns = useMemo(
        () => [
            {
                accessor: 'name',
                Cell: ({ row }) => (
                    <>
                        {row.original.status.toUpperCase() === 'READY' ? 
                            <AnchorStyleButton
                                onClick={() => dispatch({
                                    type: InstaceActions.SetTenant,
                                    value: row.original.uuid
                                })}
                            >
                                {row.original.name}
                            </AnchorStyleButton>
                            :
                            <Popup
                                content="Tenant status not ready"
                                on={"hover"}
                                trigger={
                                    <span>
                                        <AnchorStyleButton disabled={true}>
                                            {row.original.name}
                                        </AnchorStyleButton>
                                    </span>
                                }
                            />
                        }
                    </>
                )
            },
            {
                accessor: 'domain',
            },
            {
                accessor: 'status',
            },
            {
                accessor: 'contactname',
            },
            {
                accessor: 'contactemail',
            },
            {
                accessor: 'contactphonenumber',
            }
        ],
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    const tableHooks = (hooks) => {
        hooks.visibleColumns.push((columns) => [
            ...columns,
            {
                Header: () => null,
                id: 'actions',
                Cell: ({ row }) => {
                    const isActive = Boolean(row.original.active);
                    const status = row.original.status;
                    return (
                        <div style={{ textAlign: 'center' }}>
                            <Button
                                circular
                                icon="edit"
                                disabled={ status === 'delete' }
                                onClick={
                                    () => dispatch(
                                        { type: InstaceActions.EditTenant, value: row.original.uuid }
                                    )
                                }
                            />
                            {row.original.uuid !== "public" &&
                                <>
                                    <Button
                                        circular
                                        title={`${isActive ? "Deactivate" : "Activate"} tenant`}
                                        color={isActive ? "red" : "green"}
                                        icon={isActive ? "ban" : "check"}
                                        disabled={['delete', 'pending'].includes(status) }
                                        onClick={
                                            () => dispatch(
                                                {
                                                    type: isActive ?
                                                        InstaceActions.DeActivateTenant :
                                                        InstaceActions.ActivateTenant,
                                                    value: row.original.uuid
                                                }
                                            )
                                        }
                                    />
                                    <Button
                                        circular
                                        icon="trash"
                                        disabled={['delete', 'pending'].includes(status) }
                                        onClick={
                                            () => dispatch(
                                                { type: InstaceActions.DeleteTenant, value: row.original.uuid }
                                            )
                                        }
                                    />
                                </>
                            }
                        </div>
                    );
                },
                disableGlobalFilter: true,
                disableFilters: true,
                width: 2,
            },
        ]);
    };

    const {
        getTableProps,
        getTableBodyProps,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        visibleColumns,
        state: { pageIndex, pageSize },
    } = useTable(
        {
            columns,
            data,
            initialState: { pageSize: 10, pageIndex: 0 },
        },
        useFilters,
        useGlobalFilter,
        usePagination,
        tableHooks,
    );

    return (
        <>
            <Header as="h1" style={{ margin: '1rem 0', textAlign: 'center' }}>
                Tenants
            </Header>
            {props.suspendMeasurements?.suspend && (
                <HeaderSubheader style={{ margin: '1rem 0', padding: '0.5rem', textAlign: 'center' }}>
                        <Icon name='warning sign' color='red' size="large"/> <br/>
                        All measurements suspended {props.suspendMeasurements?.expireTime === 'indefinitely' ? props.suspendMeasurements.expireTime : (<>until <DateFormat timestamp={props.suspendMeasurements.expireTime}/></>)}
                </HeaderSubheader>
            )}
            <Table celled structured size="small" {...getTableProps()}>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell width='3' rowSpan='2'>Name</Table.HeaderCell>
                        <Table.HeaderCell width='3' rowSpan='2'>Domain</Table.HeaderCell>
                        <Table.HeaderCell width='1' rowSpan='2'>Status</Table.HeaderCell>
                        <Table.HeaderCell width='7' colSpan='3' textAlign='center'>Contact</Table.HeaderCell>
                        <Table.HeaderCell width='2' rowSpan='2'></Table.HeaderCell>
                    </Table.Row>
                    <Table.Row>
                        <Table.HeaderCell width='2'>Name</Table.HeaderCell>
                        <Table.HeaderCell width='3'>Email</Table.HeaderCell>
                        <Table.HeaderCell width='2'>Number</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body {...getTableBodyProps()}>
                    {data.length ? page.map((row) => {
                        prepareRow(row);
                        return (
                            <Table.Row {...row.getRowProps()} active={row.original?.uuid === selectedTenantUuid}>
                                {row.cells.map((cell) => {
                                    return (
                                        <Table.Cell {...cell.getCellProps()}>
                                            {cell.render('Cell')}
                                        </Table.Cell>
                                    );
                                })}
                            </Table.Row>
                        );
                    }) :
                        <Table.Row>
                            <Table.Cell colSpan={visibleColumns.length}>
                                No defined tenants
                            </Table.Cell>
                        </Table.Row>
                    }
                </Table.Body>
                <Table.Footer>
                    <Table.Row>
                        <Table.HeaderCell colSpan={visibleColumns.length}>
                            <Grid>
                                <Grid.Row verticalAlign="middle" columns={3}>
                                    <Grid.Column>
                                        <span
                                            style={{
                                                border: 'none',
                                                background: 'none',
                                                verticalAlign: 'center',
                                            }}
                                        >
                                            Show
                                            <Dropdown
                                                inline
                                                value={pageSize}
                                                onChange={(_, { value }) => {
                                                    setPageSize(Number(value));
                                                }}
                                                style={{ margin: '0 0.5rem' }}
                                                options={[10, 20, 30, 40, 50].map((pageSize) => ({
                                                    key: pageSize,
                                                    value: pageSize,
                                                    text: pageSize,
                                                }))}
                                            />
                                            entries
                                        </span>
                                    </Grid.Column>
                                    <Grid.Column textAlign='center'>
                                        <Menu size="mini" pagination>
                                            <Menu.Item
                                                as="a"
                                                icon="angle double left"
                                                onClick={() => gotoPage(0)}
                                                disabled={!canPreviousPage}
                                            />
                                            <Menu.Item
                                                as="a"
                                                icon="angle left"
                                                onClick={() => previousPage()}
                                                disabled={!canPreviousPage}
                                            />
                                            <Menu.Item
                                                as="a"
                                                content={`${pageCount !== 0 ? (pageIndex + 1) : 0}/${pageCount}`}
                                            />
                                            <Menu.Item
                                                as="a"
                                                icon="angle right"
                                                onClick={() => nextPage()}
                                                disabled={!canNextPage}
                                            />
                                            <Menu.Item
                                                as="a"
                                                icon="angle double right"
                                                onClick={() => gotoPage(pageCount - 1)}
                                                disabled={!canNextPage}
                                            />
                                        </Menu>
                                    </Grid.Column>

                                    <Grid.Column textAlign='right'>
                                        {props.suspendMeasurementsButton}
                                        {props.newTenantButton}
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Table.HeaderCell>
                    </Table.Row>
                </Table.Footer>
            </Table>
        </>
    );
}

export default MultiTenantTable;
