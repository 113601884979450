import NMService from "@services/nm.service";
import { Fragment, useState } from "react";
import { Checkbox, Form, Segment } from "semantic-ui-react";
import eula from "./eula.json";

const EulaForm = (props) => {
    const [read, setRead] = useState(false);
    const text2 = { display: "pre-wrap", wordWrap: "break-word" };
    const text1 = { ...text2, fontSize: "1.1em" };

    const onSubmit = () => NMService.setEulaAgreementStatus()
                                    .then((r) => props.refetch())
                                    .catch(e => null)

    return (
        <Form onSubmit={() => onSubmit()}>
            <Segment
                vertical
                secondary
                style={{ overflow: "auto", height: "700px", padding: "2rem 2rem 0 0" }}
            >
                {eula.map((e, i) => (
                    <Fragment key={i}>
                        <div style={{ marginLeft: e.tabs * 3 + 2 + "rem" }}>
                            <span key={i} style={{ ...e.style, ...text1 }}>
                                {e.text}
                            </span>
                        </div>
                        {e.text2 && (
                            <>
                                <br />
                                <div style={{ marginLeft: e.tabs * 3 + 4 + "rem" }}>
                                    <span key={i} style={text2}>
                                        {e.text2}
                                    </span>
                                </div>
                            </>
                        )}
                        <br />
                        <br />
                    </Fragment>
                ))}
                <pre>{eula.text}</pre>
            </Segment>
            <Form.Group style={{ margin: "1rem" }}>
                <Checkbox
                    type={"checkbox"}
                    onClick={(e) => setRead((p) => !p)}
                    checked={read}
                    label="I have read the agreement"
                />
                <Form.Button
                    style={{ position: "absolute", right: "0" }}
                    primary={read}
                    disabled={!read}
                    content="Accept license agreement"
                    type="submit"
                />
            </Form.Group>
        </Form>
    );
};

export default EulaForm;
