import { useFetchEventCollectorSource } from '@hooks/useFetchEventCollectorSource';
import { useFetchEventCollectorSourceGroups } from '@hooks/useFetchEventCollectorSourceGroups';
import { useFetchEventCollectorTemplates } from '@hooks/useFetchEventCollectorTemplates';
import { useFormFields } from '@hooks/useFormFields';
import NMService from '@services/nm.service';
import { addGlobalMessageAtom } from '@store/globalMessage';
import { useSetAtom } from 'jotai';
import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { Form, Grid, Header, Icon, Table, TableHeader } from 'semantic-ui-react';

const EventCollectorSourceDetails = (props) => {
    const addGlobalMessage = useSetAtom(addGlobalMessageAtom);
    const uuid = useMemo(() => props.data?.uuid, [props.data?.uuid])
    const c = { padding: "3px" }
    const {
        data: source,
        isLoading,
        refetch
    } = useFetchEventCollectorSource({
        uuid: uuid,
        options: { refetchOnMount: "always" },
    });
    const {
        data: sourceGroups
    } = useFetchEventCollectorSourceGroups({
        enabled: source.sourcegroups !== undefined,
        select: e => e.map(l => (source?.sourcegroups || []).map(k => k.uuid).includes(l.uuid) ? null : ({ key: l.uuid, text: l.name, value: l.uuid })).filter(j => j),
        options: { refetchOnMount: "always" },
    });
    const {
        data: templates
    } = useFetchEventCollectorTemplates({
        enabled: source.templates !== undefined,
        select: e => e.map(l => (source?.templates || []).map(k => k.uuid).includes(l.uuid) ? null : ({ key: l.uuid, text: l.name, value: l.uuid })).filter(j => j),
        options: { refetchOnMount: "always" },
    });

    const {
        register,
        setValue,
        watch,
        reset,
        getValues,
        formState: { errors },
    } = useForm();
    const { renderDropdown } = useFormFields({ register, errors, setValue, watch });

    const onSubmitTemplate = () => {
        NMService.postECSource_Template({ sourceuuid: uuid, templateuuid: getValues('template') }).then((r) =>
            addGlobalMessage({
                header: "Template successfully assigned to source",
                content: "Template successfully assigned to source",
                type: "positive",
            })
        ).catch(e => null)
        .finally(() => {
            refetch()
            reset({ template: null })
        })
    }
    const onSubmitSourceGroup = () => {
        NMService.postECSource_SourceGroup({ sourceuuid: uuid, groupuuid: getValues('sourceGroup') }).then((r) =>
            addGlobalMessage({
                header: "Source group successfully assigned to source",
                content: "source group successfully assigned to source",
                type: "positive",
            })
        ).catch(e => null)
        .finally(() => {
            refetch()
            reset({ sourceGroup: null })
        })
    }

    const onDeleteTemplate = (tuuid) => {
        NMService.deleteECSource_Template(uuid, tuuid).then((r) =>
            addGlobalMessage({
                header: "Template successfully deleted from source",
                content: "Template successfully deleted from source",
                type: "positive",
            })
        ).catch(e => null)
        .finally(() => {
            refetch()
            reset({ template: null })
        })
    }
    const onDeleteSourceGroup = (sguuid) => {
        NMService.deleteECSource_SourceGroup(uuid, sguuid).then((r) =>
            addGlobalMessage({
                header: "Source group successfully deleted from source",
                content: "source group successfully deleted from source",
                type: "positive",
            })
        ).catch(e => null)
        .finally(() => {
            refetch()
            reset({ sourceGroup: null })
        })
    }

    return (isLoading ? null :
        <>
            <Header as="h4" dividing>
                Source info
                <Icon
                    name="close"
                    link
                    style={{ float: "right" }}
                    onClick={() => props.close()}
                />
            </Header>
            <Table celled structured size="small" compact>
                <TableHeader>
                    <Table.Row textAlign="center">
                        <Table.HeaderCell style={c}>Name</Table.HeaderCell>
                        <Table.HeaderCell style={c}>Description</Table.HeaderCell>
                        <Table.HeaderCell style={c}>Source ID</Table.HeaderCell>
                        <Table.HeaderCell style={c}>Source address</Table.HeaderCell>
                        <Table.HeaderCell style={c}>Protocol</Table.HeaderCell>
                        <Table.HeaderCell style={c}>Timestamp format</Table.HeaderCell>
                    </Table.Row>
                </TableHeader>
                <Table.Body>
                    <Table.Row textAlign="center">
                        <Table.Cell style={c}>{source.name}</Table.Cell>
                        <Table.Cell style={c}>{source.description ? source.description : '-'}</Table.Cell>
                        <Table.Cell style={c}>{source.sourceid ? source.sourceid : '-'}</Table.Cell>
                        <Table.Cell style={c}>{source.sourceaddress ? source.sourceaddress : '-'}</Table.Cell>
                        <Table.Cell style={c}>{source.protocol}</Table.Cell>
                        <Table.Cell style={c}>{source.tsformat}</Table.Cell>
                    </Table.Row>
                </Table.Body>
            </Table>
            <Form>
                <Grid>
                    <Grid.Row columns={2}>
                        <Grid.Column>
                            <Table celled structured size="small" compact>
                                <TableHeader>
                                    <Table.Row textAlign="center">
                                        <Table.HeaderCell style={c} colSpan={4}>Templates</Table.HeaderCell>
                                    </Table.Row>
                                    <Table.Row textAlign="center">
                                        <Table.HeaderCell style={c}>Name</Table.HeaderCell>
                                        <Table.HeaderCell style={c}>Description</Table.HeaderCell>
                                        <Table.HeaderCell style={c}>RegEx list</Table.HeaderCell>
                                        <Table.HeaderCell style={c} width={1}></Table.HeaderCell>
                                    </Table.Row>
                                </TableHeader>
                                <Table.Body>
                                    {source.templates.map((e, i) =>
                                        <Table.Row textAlign="center" key={i}>
                                            <Table.Cell style={c}>{e.name}</Table.Cell>
                                            <Table.Cell style={c}>{e.description ? e.description : '-'}</Table.Cell>
                                            <Table.Cell style={c}>{e.regexlist?.length === 0 ? "-" : e.regexlist?.join(", ")}</Table.Cell>
                                            <Table.HeaderCell style={c}><Icon name='trash alternate' color='blue' link onClick={() => onDeleteTemplate(e.uuid)}/></Table.HeaderCell>
                                        </Table.Row>
                                    )}
                                </Table.Body>
                            </Table>
                            <Form.Group style={{ marginTop: '1rem' }}>
                                {renderDropdown('', 'template', templates, { hideLabel: true, width: 6, clearable: true })}
                                <Form.Button size="small" primary content="Add template" onClick={() => onSubmitTemplate()} disabled={templates.length === 0}/>
                            </Form.Group>
                        </Grid.Column>
                        <Grid.Column>
                            <Table celled structured size="small" compact>
                                <TableHeader>
                                    <Table.Row textAlign="center">
                                        <Table.HeaderCell style={c} colSpan={3}>Source groups</Table.HeaderCell>
                                    </Table.Row>
                                    <Table.Row textAlign="center">
                                        <Table.HeaderCell style={c}>Name</Table.HeaderCell>
                                        <Table.HeaderCell style={c}>Description</Table.HeaderCell>
                                        <Table.HeaderCell style={c} width={1}></Table.HeaderCell>
                                    </Table.Row>
                                </TableHeader>
                                <Table.Body>
                                    {source.sourcegroups.map((e, i) =>
                                        <Table.Row textAlign="center" key={i}>
                                            <Table.Cell style={c}>{e.name}</Table.Cell>
                                            <Table.Cell style={c}>{e.description ? e.description : '-'}</Table.Cell>
                                            <Table.HeaderCell style={c}><Icon name='trash alternate' color='blue' link onClick={() => onDeleteSourceGroup(e.uuid)}/></Table.HeaderCell>
                                        </Table.Row>
                                    )}
                                </Table.Body>
                            </Table>
                            <Form.Group style={{ marginTop: '1rem' }}>
                                {renderDropdown('', 'sourceGroup', sourceGroups, { hideLabel: true, width: 6, clearable: true } )}
                                <Form.Button size="small" primary content="Add source group" onClick={() => onSubmitSourceGroup()} disabled={sourceGroups.length === 0}/>
                            </Form.Group>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Form>
        </>
    );
}

export default EventCollectorSourceDetails;
