import { useFormFields } from "@hooks/useFormFields";
import { validateDayOfMonth, validateDayOfWeek, validateHour, validateMinute, validateMonth } from "@utils/crontabValidation";
import { Form } from "semantic-ui-react";

const Crontab = ({ register, errors, setValue, watch, title }) => {
    const { renderInput } = useFormFields({ register, errors, setValue, watch });

    return (
        <Form.Group widths={6}>
            <Form.Field style={{ paddingTop: '0.5rem', marginTop: '0.5rem', whiteSpace: "pre-wrap" }}>
                {title ? title : "Period definition"}
                <span style={{ fontSize: 13 }}>{"\n(UTC crontab format)"}</span>
            </Form.Field>
            {renderInput('minute', 'period.minute', { validate: (v) => validateMinute(v) })}
            {renderInput('hour', 'period.hour', { validate: (v) => validateHour(v) })}
            {renderInput('day of month', 'period.dayOfMonth', { validate: (v) => validateDayOfMonth(v) })}
            {renderInput('month', 'period.month', { validate: (v) => validateMonth(v) })}
            {renderInput('day of week', 'period.dayOfWeek', { validate: (v) => validateDayOfWeek(v) })}
        </Form.Group>)
}

export default Crontab;