import NMService from "@services/nm.service";
import { useQuery } from "@tanstack/react-query";

export const useFetchSwModuleDetails = ({ 
    moduleid, 
    version, 
    select,
    options 
} = {}) => {
    const query = useQuery({
        queryKey: ['getSWModuleDetails', moduleid, version],
        queryFn: () =>
            NMService.getSWModuleDetails(moduleid, version)
                .then(r => r.data)
                .catch(e => {}),
        enabled: !!moduleid && !!version,
        select,
        ...options
    })
    return { ...query, data: query.data ?? {} }
}