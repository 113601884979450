import ConfirmationModal from '@components/ConfirmationModal';
import CustomHeader from '@components/CustomHeader';
import { useFetchSnmpHostGroup } from '@hooks/useFetchSnmpHostGroup';
import { useFetchSnmpSecurityProfile } from '@hooks/useFetchSnmpSecurityProfile';
import { useFetchSnmpTemplate } from '@hooks/useFetchSnmpTemplate';
import NMService from '@services/nm.service';
import { addGlobalMessageAtom } from '@store/globalMessage';
import { globalSnmpHostGroupActions, globalSnmpHostGroupAtom } from '@store/globalSnmpHostGroups';
import { globalSnmpSecurityProfileActions, globalSnmpSecurityProfileAtom } from '@store/globalSnmpSecurityProfiles';
import { globalSnmpTemplateActions, globalSnmpTemplateAtom } from '@store/globalSnmpTemplate';
import { useAtom, useSetAtom } from 'jotai';
import { useState } from 'react';
import { Breadcrumb, Divider } from 'semantic-ui-react';
import HostGroupForm from './HostGroupForm';
import HostGroupTable from './HostGroupTable';
import SecurityProfileForm from './SecurityProfileForm';
import SecurityProfileTable from './SecurityProfileTable';
import TemplateForm from './TemplateForm';
import TemplateTable from './TemplateTable';

const Snmp = () => {
    const addGlobalMessage = useSetAtom(addGlobalMessageAtom);
    const [showHostGroups, setShowHostGroups] = useState(false);
    const [showSecurityProfiles, setShowSecurityProfiles] = useState(false);
    const [showTemplates, setShowTemplates] = useState(false);

    const [hostGroupsState, hostGroupsDispatch] = useAtom(globalSnmpHostGroupAtom);
    const {
        AddHost,
        EditHost,
        DeleteHost,
        ClearShowType: resetHostState
    } = globalSnmpHostGroupActions;

    const [securityProfilesState, securityProfilesDispatch] = useAtom(globalSnmpSecurityProfileAtom);
    const {
        AddSecurityProfile,
        EditSecurityProfile,
        DeleteSecurityProfile,
        ClearShowType: resetSecurityProfileState
    } = globalSnmpSecurityProfileActions;

    const [templateState, templateDispatch] = useAtom(globalSnmpTemplateAtom);
    const {
        AddTemplate,
        EditTemplate,
        DeleteTemplate,
        ClearShowType: resetTemplateState
    } = globalSnmpTemplateActions;

    const {
        data: snmpTemplates,
        isLoading: snmpTemplatesIsLoading,
        refetch: refetchSnmpTemplates
    } = useFetchSnmpTemplate({
        select: e => e.map(j => ({ ...j, uuid: j.template_name })),
        options: {
            refetchOnMount: 'always'
        }
    });

    const {
        data: snmpHostGroups,
        isLoading: snmpHostGroupsIsLoading,
        refetch: refetchSnmpHostGroups
    } = useFetchSnmpHostGroup({
        options: {
            refetchOnMount: 'always'
        }
    });

    const {
        data: snmpSecurityProfiles,
        isLoading: snmpSecurityProfilesIsLoading,
        refetch: refetchSnmpSecurityProfiles
    } = useFetchSnmpSecurityProfile({
        options: {
            refetchOnMount: 'always'
        }
    });

    const deleteSnmpHostGroup = () => {
        const uuid = hostGroupsState.selectedHost.uuid;
        if (!uuid) {
            return
        }

        NMService.deleteSnmpHostGroupByUuid(uuid).then(() => 
            addGlobalMessage({
                header: 'SNMP host group is deleted!',
                content: 'SNMP host group is successfully deleted!.',
                type: 'positive',
            }))
        .then(() => hostGroupsDispatch({ type: resetHostState }))
        .catch(e => null)
        .finally(() => refetchSnmpHostGroups())
    }

    const deleteSnmpSecurityProfile = () => {
        const uuid = securityProfilesState.selectedSecurityProfile.uuid;
        if (!uuid) {
            return
        }
        NMService.deleteSnmpSecurityProfileByUuid(uuid).then(r => 
            addGlobalMessage({
                header: 'SNMP security group successfully deleted',
                content: 'SNMP security group successfully deleted',
                type: 'positive',
            })
        )
        .then(() => securityProfilesDispatch({ type: resetSecurityProfileState }))
        .catch(e => null)
        .finally(() => refetchSnmpSecurityProfiles())
    }

    const deleteSnmpTemplate = () => {
        const name = templateState.selectedTemplate.template_name;
        if (!name) {
            return
        }
        NMService.deleteSnmpTemplateByName(name).then(r => 
            addGlobalMessage({
                header: 'SNMP security group successfully deleted',
                content: 'SNMP security group successfully deleted',
                type: 'positive',
            })
        )
        .then(() => templateDispatch({ type: resetTemplateState }))
        .catch(e => null)
        .finally(() => refetchSnmpTemplates())
    }

    const isLoading = snmpHostGroupsIsLoading || snmpTemplatesIsLoading || snmpSecurityProfilesIsLoading;
    if (isLoading) {
        return null;
    }

    return (
        <>
            <Breadcrumb style={{ marginTop: '1rem' }}>
                <Breadcrumb.Section>Configuration</Breadcrumb.Section>
                <Breadcrumb.Divider/>
                <Breadcrumb.Section active>SNMP</Breadcrumb.Section>
            </Breadcrumb>
            <Divider clearing style={{ marginBottom: '1.5rem' }} />
            <CustomHeader
                title='SNMP host groups'
                toggle={() => setShowHostGroups(p => !p)}
                show={showHostGroups}
            />
            {showHostGroups && <HostGroupTable data={snmpHostGroups} />}
            {(showHostGroups && [AddHost, EditHost].includes(hostGroupsState.showType)) &&
                <HostGroupForm />
            }
            <CustomHeader
                title='SNMP security profiles'
                toggle={() => setShowSecurityProfiles(p => !p)}
                show={showSecurityProfiles}
            />
            {showSecurityProfiles && <SecurityProfileTable data={snmpSecurityProfiles} />}
            {(showSecurityProfiles && [AddSecurityProfile, EditSecurityProfile].includes(securityProfilesState.showType)) &&
                <SecurityProfileForm refetch={refetchSnmpSecurityProfiles} />
            }
            <CustomHeader
                title='SNMP templates'
                toggle={() => setShowTemplates(p => !p)}
                show={showTemplates}
            />
            {showTemplates && <TemplateTable data={snmpTemplates} />}
            {(showTemplates && [AddTemplate, EditTemplate].includes(templateState.showType)) &&
                <TemplateForm />
            }
            <ConfirmationModal
                open={hostGroupsState.showType === DeleteHost}
                header="Delete SNMP host group"
                content="Are you sure you want to delete SNMP host group?"
                onConfirm={deleteSnmpHostGroup}
                onDismiss={() => hostGroupsDispatch({ type: resetHostState })}
            />
            <ConfirmationModal
                open={securityProfilesState.showType === DeleteSecurityProfile}
                header="Delete SNMP security profile"
                content="Are you sure you want to delete SNMP security profile?"
                onConfirm={deleteSnmpSecurityProfile}
                onDismiss={() => securityProfilesDispatch({ type: resetSecurityProfileState })}
            />
            <ConfirmationModal
                open={templateState.showType === DeleteTemplate}
                header="Delete SNMP template"
                content="Are you sure you want to delete SNMP template?"
                onConfirm={deleteSnmpTemplate}
                onDismiss={() => templateDispatch({ type: resetTemplateState })}
            />
        </>

    );
}

export default Snmp;