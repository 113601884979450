import React, { useState, useEffect } from 'react';
import { Icon } from 'semantic-ui-react';

function ErrorPopup(props) {
    const [content, setContent] = useState('');

    useEffect(() => {
        setContent(props.content);
    }, [props.content]);

    return (!content ? null : (
                <Icon
                    name="warning"
                    color="red"
                    circular
                    size='small'
                    title={content}
                    style={props.style !== undefined ? props.style : { margin: '0', position: 'absolute', right: '0' }}
                />))
}

export default ErrorPopup;