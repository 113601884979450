import { AuthProvider } from "@hooks/useAuth";
import { Suspense } from "react";
import { Await, useLoaderData, useOutlet } from "react-router-dom";
import { Dimmer, Loader } from 'semantic-ui-react';

export const AuthLayout = () => {
    const outlet = useOutlet();
    const { userPromise } = useLoaderData();

    return (
        <Suspense
            fallback={
                <Dimmer active inverted>
                    <Loader>Loading...</Loader>
                </Dimmer>
            }
        >
            <Await
                resolve={userPromise}
                children={(user) => (
                    <AuthProvider userData={user}>{outlet}</AuthProvider>
                )}
            />
        </Suspense>
    );
};
