import { useState } from "react";
import { Icon, Input } from "semantic-ui-react";
import { useAsyncDebounce } from "react-table";

const GlobalFilter = ({
    globalFilter,
    setGlobalFilter,
    resetPage,
    setMemo = undefined,
}) => {
    const [value, setValue] = useState(globalFilter);
    const onChange = useAsyncDebounce((value) => {
        if (resetPage !== undefined) {
            resetPage();
        }
        if (setMemo) {
            setMemo(value);
        }
        setGlobalFilter(value || undefined);
    }, 300);

    const clearInput = () => {
        setValue("");
        onChange("");
    };

    return (
        <Input
            placeholder="Search..."
            icon={
                <Icon
                    name={!value ? "search" : "close"}
                    actionPosition="left"
                    value={!!value}
                    link={!!value}
                    onClick={value ? clearInput : undefined}
                />
            }
            size="small"
            value={value || ""}
            onChange={(e) => {
                setValue(e.target.value);
                onChange(e.target.value);
            }}
        />
    );
};

export default GlobalFilter;