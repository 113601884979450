import NMService from "@services/nm.service";
import { addGlobalMessageAtom } from "@store/globalMessage";
import { useSetAtom } from "jotai";
import { useCallback, useEffect, useState } from "react";
import MLConfigTable from "./MLConfigTable";

const MLConfig = (props) => {
    const addGlobalMessage = useSetAtom(addGlobalMessageAtom);
    const [data, setData] = useState([]);
    const { hwid } = props.nodedata;
    const { moduleid, instanceid, version } = props.data;

    const parseConfig = (data) => {
        const payload = {
            ...data,
            hwid: hwid,
            module: moduleid,
            instanceid: instanceid,
            moduleversion: version,
        };
        postConfig(payload);
    };

    const postConfig = (payload) => {
        NMService
            .MLlearn(payload)
            .then((response) => {
                if (response.data.hasOwnProperty("error")) {
                    addGlobalMessage({
                        header: "Error while adding new ML model",
                        content: response.data.error.message || "",
                        type: "warning",
                    });
                } else if (response.data.rv === "OK") {
                    addGlobalMessage({
                        header: "Successfully added new ML model!",
                        content: "Successfully added new ML model!",
                        type: "positive",
                    });
                }
            })
            .catch(e => null)
            .finally(() => fetchModels());
    };

    const fetchModels = useCallback(() => {
        NMService
            .getMLModelsForNodemodule(hwid, props.data)
            .then((response) => setData(response.data.rv || []))
            .catch(e => null);
    }, [hwid, props.data]);

    useEffect(() => {
        fetchModels();
    }, [fetchModels]);

    return (
        <MLConfigTable
            parse={parseConfig}
            post={postConfig}
            data={{ hwid, moduleid, version, instanceid }}
            table={data}
        />
    );
};

export default MLConfig;
