import { ADMIN } from "@constants/roles";
import { useGetGlobalPermission } from "@hooks/useGetGlobalPermission";
import { PermissionsGate } from "@layouts/PermissionGate";
import NMService from "@services/nm.service";
import { addGlobalMessageAtom } from "@store/globalMessage";
import { useSetAtom } from "jotai";
import { useState } from "react";
import { Button, Form } from "semantic-ui-react";

const SWModuleUpload = (props) => {
    const hasPermission = useGetGlobalPermission(ADMIN);
    const addGlobalMessage = useSetAtom(addGlobalMessageAtom);
    const [newFile, setNewFile] = useState(undefined);
    const [loading, setLoading] = useState(false);

    const upload = () => {
        setLoading(true);
        const formData = new FormData();
        formData.append("file", newFile);
        NMService
            .uploadSWModule(formData)
            .then((r) => {
                let success = [];
                let failure = [];
                if (r.data?.length > 0) {
                    for (var module of r.data) {
                        !module.error ? success.push(module.module) : failure.push({ name: module.module, error: module.error })
                    }
                    if (success.length !== 0) {
                        addGlobalMessage({
                            header: "Successfully added module",
                            content: "Added: " + success.join(", "),
                            type: "positive",
                        })
                    }
                    if (failure.length !== 0) {
                        addGlobalMessage({
                            header: "Error adding module(s)",
                            content: "Failed: " + failure.map(e => e.name + ":" + e.error).join(", "),
                            type: "negative",
                        })
                    }
                } else {
                    addGlobalMessage({
                        header: "Error adding module(s)",
                        content: "Error uploading file",
                        type: "negative",
                    })
                }
            }).catch(e => null)
            .finally((r) => {
                setNewFile(undefined);
                setLoading(false);
                props.refresh();
            });
    };

    return (
        <Form onSubmit={upload}>
            <Form.Group style={{ margin: '0 0'}}>
                <PermissionsGate hasPermission={hasPermission}>
                    <Form.Field>
                        <Button
                            disabled={loading}
                            floated="right"
                            as="label"
                            htmlFor="file"
                            type="button"
                            size="tiny"
                            content={newFile === undefined ? "Choose tar file..." : newFile.name}
                        />
                        <input
                            type={"file"}
                            accept={".tar"}
                            id="file"
                            hidden
                            onChange={(e) => setNewFile(e.target.files[0])}
                        />
                    </Form.Field>
                </PermissionsGate>
                <Form.Button
                    loading={loading}
                    floated="right"
                    type="submit"
                    size="tiny"
                    primary={newFile !== undefined}
                    disabled={newFile === undefined}
                    content="Upload module"
                />
            </Form.Group>
        </Form>
    );
};

export default SWModuleUpload;
