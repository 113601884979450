import ConfirmationModal from '@components/ConfirmationModal';
import CustomTable from '@components/CustomTable';
import { useFetchGroups } from '@hooks/useFetchGroups';
import NMService from '@services/nm.service';
import { addGlobalMessageAtom } from '@store/globalMessage';
import { groupsActions, groupsAtom } from '@store/groups';
import { useAtom, useSetAtom } from 'jotai';
import { useEffect, useMemo } from 'react';
import { Button, Icon } from 'semantic-ui-react';
import GroupForm from './GroupForm';

const Groups = () => {
    const addGlobalMessage = useSetAtom(addGlobalMessageAtom);
    const [state, dispatch] = useAtom(groupsAtom);

    const {
        data,
        isLoading: groupListIsLoading,
        isFetching: groupListIsFetching,
        refetch: refetchGroupList
    } = useFetchGroups({
        options: {
            refetchOnMount: 'always'
        }
    });
    const columns = useMemo(
        () => [
            {
                id: 'name',
                Header: 'Name',
                accessor: 'name',
            },
            {
                id: 'description',
                Header: 'Description',
                accessor: 'description',
            }
        ],
        []
    );

    const tableHooks = (hooks) => {
        hooks.visibleColumns.push((columns) => [
            ...columns,
            {
                Header: () => null,
                id: 'actions',
                Cell: ({ row }) =>
                    <div style={{ textAlign: 'center' }}>
                        <Button
                            circular
                            icon="trash alternate"
                            onClick={() => dispatch({ type: groupsActions.DeleteGroup, value: row.original.name })}
                        />
                    </div>,
                disableGlobalFilter: true,
                disableFilters: true,
                width: 2,
            },
        ]);
    };

    const onDeleteGroup = async () => {
        await NMService.deleteGroup(state.selectedGroup).then(() => {
            addGlobalMessage({
                header: 'Group was deleted!',
                content: `Group ${state.selectedGroup} was successfully deleted.`,
                type: 'positive',
            })
            refetchGroupList();
        }).catch(e => null)
    }

    useEffect(() => {
        if (!groupListIsLoading && !groupListIsFetching) {
            dispatch({ type: groupsActions.ClearShowType })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [groupListIsLoading, groupListIsFetching])

    if (groupListIsLoading) {
        return null;
    }

    return (
        <>
            <CustomTable
                data={data}
                columns={columns}
                tableHooks={tableHooks}
                footerButton={() =>
                    <Button
                        floated="right"
                        icon
                        labelPosition="left"
                        primary
                        size="tiny"
                        onClick={() => dispatch({ type: groupsActions.AddGroup })}
                    >
                        <Icon name="user" /> Add Group
                    </Button>
                }
            />

            {state.showType === groupsActions.AddGroup && (
                <GroupForm refetch={refetchGroupList}/>
            )}

            {state.showType === groupsActions.DeleteGroup &&
                <ConfirmationModal
                    open={true}
                    header="Delete group"
                    content={`Are you sure you want to delete groupname ${state.groupname} ?`}
                    onConfirm={() => onDeleteGroup()}
                    onDismiss={() => dispatch({ type: groupsActions.ClearShowType })}
                />
            }
        </>
    );
}

export default Groups;
