import { useFormFields } from '@hooks/useFormFields';
import NMService from '@services/nm.service';
import { addGlobalMessageAtom } from '@store/globalMessage';
import { useSetAtom } from 'jotai';
import { useForm } from 'react-hook-form';
import { Button, Form, Header } from 'semantic-ui-react';

const DhcpOptionForm = (props) => {
    const addGlobalMessage = useSetAtom(addGlobalMessageAtom);
    const {
        handleSubmit,
        register,
        setValue,
        watch,
        formState: { errors },
    } = useForm();
    const { renderInput, renderCheckbox } = useFormFields({ register, errors, setValue, watch });


    const isUniqueValue = (key, value) => {
        if (!value) {
            return 'Field is mandatory';
        }

        const foundIndex = props.options.findIndex(e => e[key] === value);
        if (foundIndex !== -1) {
            return `Option ${key} must be unique`
        }
    }

    const onSubmit = (values) => {
        NMService.addDhcpOption(values).then(() => {
            props.updateData();
            addGlobalMessage({
                header: 'DHCP option data is created',
                content: 'DHCP option data is successfully created',
                type: 'positive',
            })
        }).catch(e => null)
    };

    return (
        <Form onSubmit={handleSubmit(onSubmit)} className="basic segment" style={{ margin: '1rem' }}>
            <Header dividing as='h4' style={{ margin: '2rem 0 1.5rem 0' }}>Add new option</Header>
            <Form.Group>
                {renderInput('Name', 'name', { width: 5, validate: (v) => isUniqueValue("name", v) })}
                {renderInput('Code', 'code', { width: 2, inputType: 'number', validate: (v) => isUniqueValue("name", v) })}
                {renderCheckbox('Enabled option', 'enabled', { toggle: true })}
            </Form.Group>
            <Form.Group>
                <Form.Button size="small" type="submit" primary content='Add'/>
                <Button size="small" type="button" onClick={() => props.toggle({}, false, false)} content='Cancel'/>
            </Form.Group>
        </Form>
    );
};

export default DhcpOptionForm;