import ConfirmationModal from '@components/ConfirmationModal';
import CustomTable from '@components/CustomTable';
import DateFormat from '@components/DateFormat';
import { ADMIN } from '@constants/roles';
import { useFetchLocalUsers } from '@hooks/useFetchLocalUsers';
import { useGetGlobalPermission } from '@hooks/useGetGlobalPermission';
import { PermissionsGate } from '@layouts/PermissionGate';
import NMService from '@services/nm.service';
import { addGlobalMessageAtom } from '@store/globalMessage';
import { localUserActions, localUsersAtom } from '@store/localUser';
import { useAtom, useSetAtom } from 'jotai';
import { useEffect, useMemo } from 'react';
import { Button, Icon } from 'semantic-ui-react';
import ChangePassword from './ChangePassword';
import LocalUserForm from './LocalUserForm';

const LocalUser = () => {
    const hasPermission = useGetGlobalPermission(ADMIN);
    const addGlobalMessage = useSetAtom(addGlobalMessageAtom);
    const [state, dispatch] = useAtom(localUsersAtom);

    const {
        data: userList,
        isLoading: userListIsLoading,
        isFetching: userListIsFetching,
        refetch: refetchUserList
    } = useFetchLocalUsers({ options: { refetchOnMount: 'always' }})

    const columns = useMemo(
        () => [
            {
                id: "username",
                Header: "Username",
                accessor: "username",
            },
            {
                id: "role",
                Header: "Role",
                accessor: "role",
            },
            {
                id: "fullname",
                Header: "Name",
                accessor: "fullname",
            },
            {
                id: "email",
                Header: "E-mail",
                accessor: "email",
            },
            {
                id: "active",
                Header: "Active",
                accessor: d =>  <Icon name={d.active === 1 ? "check" : 'close'} color={d.active === 1 ? 'green' : 'red'} />,
                style: { textAlign: 'center' }
            },
            {
                id: "created",
                Header: "Created",
                accessor: d => <DateFormat timestamp={d.created} />,
            },
        ], []);

    const tableHooks = (hooks) => {
        hooks.visibleColumns.push((columns) => [
            ...columns,
            {
                Header: () => null,
                id: "actions",
                Cell: ({ row }) =>
                    <div style={{ textAlign: 'center' }}>
                        <PermissionsGate hasPermission={hasPermission}>
                            <Button
                                circular
                                icon="settings"
                                onClick={() => dispatch({ type: localUserActions.EDIT_USER, value: row.original.username })}
                            />
                        </PermissionsGate>
                        <PermissionsGate hasPermission={hasPermission}>
                            <Button
                                circular
                                icon="key"
                                onClick={() => dispatch({ type: localUserActions.CHANGE_USER_PASSWORD, value: row.original.username })}
                            />
                        </PermissionsGate>
                        <PermissionsGate hasPermission={hasPermission}>
                            <Button
                                circular
                                icon="trash alternate"
                                onClick={() => dispatch({ type: localUserActions.DELETE_USER, value: row.original.username })}
                            />
                        </PermissionsGate>
                    </div>,
                width: 2,
                disableGlobalFilter: true,
                disableFilters: true,
            },
        ]);
    };

    const onDeleteUser = async () => {
        if (!state?.selectedUser) {
            return
        };

        await NMService.deleteUser(state.selectedUser).then(() => {
            addGlobalMessage({
                header: 'User is deleted!',
                content: `User ${state.selectedUser} is successfully deleted.`,
                type: 'positive',
            })
            refetchUserList()
        }).catch(e => null)
    }

    useEffect(() => {
        if (!userListIsLoading && !userListIsFetching) {
            dispatch({ type: localUserActions.RESET })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        userListIsLoading,
        userListIsFetching
    ])

    if (userListIsLoading) {
        return null;
    }

    return (
        <>
            <CustomTable
                data={userList}
                columns={columns}
                hideSearch={true}
                tableHooks={tableHooks}
                active={state?.selectedUser}
                footerButton={() =>
                    <PermissionsGate hasPermission={hasPermission}>
                        <Button
                            icon='user'
                            primary
                            floated="right"
                            labelPosition="left"
                            size="tiny"
                            content='Add user'
                            onClick={() => dispatch({ type: localUserActions.ADD_USER })}
                        />
                    </PermissionsGate>
                }
            />
            {[localUserActions.ADD_USER, localUserActions.EDIT_USER].includes(state.showType) && (
                <LocalUserForm />
            )}

            {state.showType === localUserActions.CHANGE_USER_PASSWORD && (
                <ChangePassword />
            )}

            {state.showType === localUserActions.DELETE_USER && (
                <ConfirmationModal
                    open={true}
                    header="Delete user"
                    content={`Are you sure you want to delete user ${state.selectedUser} ?`}
                    onConfirm={onDeleteUser}
                    onDismiss={() => dispatch({ type: localUserActions.RESET })}
                />
            )}
        </>
    );
};

export default LocalUser;
