import NMService from "@services/nm.service";
import { useQuery } from "@tanstack/react-query";

export const useFetchProbeModuleBundle = ({ 
    probeid,
    select,
    options
} = {}) => {
    const query = useQuery({
        queryKey: ['getModulesBundle', String(probeid)],
        queryFn: () =>
            NMService.getModulesBundle(probeid)
                .then(r => r.data)
                .catch(e => {}),
        staleTime: Infinity,
        enabled: !!probeid,
        select,
        ...options
    })
    return { ...query, data: query.data ?? {} }
}