import { ErrorMessage } from "@components/ErrorMessage";
import { yupResolver } from "@hookform/resolvers/yup";
import { useFetchProbeModuleBundle } from "@hooks/useFetchProbeModuleBundle";
import { useFetchSwModules } from "@hooks/useFetchSwModules";
import NMService from "@services/nm.service";
import { useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Dropdown, Form } from 'semantic-ui-react';
import * as yup from 'yup';

const assignToSchema = yup.object().shape({
    parent: yup.string().trim().required("Field is mandatory.")
})

const AssignTo = (props) => {
    const { nodeid, uuid } = useMemo(() => props.data, [props.data]);
    let frameworks = {}

    const formOptions = { resolver: yupResolver(assignToSchema)} 
    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm(formOptions);

    const {
        data: moduleBundle,
        isLoading: moduleBundleIsLoading
    } = useFetchProbeModuleBundle({ probeid: nodeid });

    const {
        data: swModelList,
        isLoading: swModelListIsLoading
    } = useFetchSwModules();

    const getFrameworkOptions = () => {
        const { assignedmodules } = moduleBundle;

        const parentOptions = [];
        assignedmodules.forEach(module => {
            const { uuid, moduleid, instanceid } = module;
            const isFramework = Boolean(swModelList.find(e => e.id === moduleid).data.framework);
            if (isFramework) {
                frameworks = ({...frameworks, [uuid]: {
                    nodeid: nodeid,
                    moduleid: moduleid,
                    instanceid: instanceid
                }})
                parentOptions.push({
                    key: uuid,
                    value: uuid,
                    text: `${moduleid} ( instance: ${instanceid} )`,
                });
            }
        })
        return parentOptions;
    }

    const onSubmit = (values) => {
        const { nodeid, moduleid, instanceid } = frameworks[values.parent]

        props.onFormSubmit({ uuid, parent: values.parent })
        if (moduleid === 'ethernetframework'){ return }

        NMService.getNodeModule(nodeid, moduleid, instanceid).then(
            r => {
                const newModule = `${props.data.moduleid}.${props.data.instanceid}`
                let conf = JSON.parse(r.data.config)
                if (moduleid === "mobileframework") {
                    conf = {...conf, modules: ([...(conf.modules ?? []), { name: newModule }])}
                } else if (moduleid !== "ethernetframework")  {
                    conf = {...conf, modules: ([...(conf.modules ?? []), newModule])}
                }
                return conf
            }
        ).then(r => NMService.updateNodeModuleConfig({...frameworks[values.parent], config: JSON.stringify(r)}).catch(e => null)
        ).catch(e => null)
    };

    return (moduleBundleIsLoading || swModelListIsLoading) ? null : (
        <Form className="basic segment" onSubmit={handleSubmit(onSubmit)}>
            <Form.Field align="left" width={6} error={Boolean(errors.parent?.type)}>
                <label>Framework</label>
                <Controller
                    name="parent"
                    control={control}
                    rules={{ required: true }}
                    render={({ field: { ref, ...field } }) => (
                        <Dropdown
                            {...field}
                            fluid
                            selection
                            placeholder="Please select a framework"
                            onChange={(_, { value }) => field.onChange(value)}
                            options={getFrameworkOptions()}
                        />
                    )}
                />
                {errors.parent && <ErrorMessage>{errors.parent.message}</ErrorMessage>}
            </Form.Field>
            <Form.Button size="small" primary content='Assign'/>
        </Form>
    );
}

export default AssignTo;