import React from 'react';
import styled from 'styled-components';

const ModuleBox = styled.div`
    border: ${props => !props.hardlinked  ? "1px solid #c3c7c0": "2px solid #4183c480"};
    border-radius: 5px;
    box-shadow: ${props => !props.hardlinked  ? "1px #e0dede": "2px #0000ff0d"};
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    margin-left: ${props => props.indentation}px;
    &:hover {
        background-color: ${props => !props.hardlinked  ?  "#0000000d" : "#4183c420"};
    }
    
    &:hover table {
        background-color: #0000000d;
    }
    
    &:hover table thead tr th {
        background-color: #0000000d;
    }
    
    &:hover table tfoot tr th {
        background-color: #0000000d;
    }
`

export const ModuleBoxLayout = (props) => {
    const indentation = props.level > 1 ? (props.level-1)*14 : 0;

    return (
        <ModuleBox hardlinked={props.hardlinked} indentation={indentation} onClick={props.onClick ?? null}>
            {props.children}
        </ModuleBox>
    )
}