import NMService from "@services/nm.service";
import { useQuery } from "@tanstack/react-query";

export const useFetchAutoProvisioningKeywords = ({ 
    select,
    options 
} = {}) => {
    const query = useQuery({
        queryKey: ['getAutoProvisioningKeywords'],
        queryFn: () => 
            NMService.getAutoProvisioningKeywords()
                .then(r => r.data)
                .catch(e => []),
        placeholderData: (data) => data ?? [],
        staleTime: Infinity,
        select,
        ...options
    })
    return { ...query, data: query.data ?? [] }
}