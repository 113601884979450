import { ADMIN } from "@constants/roles";
import { yupResolver } from "@hookform/resolvers/yup";
import { useGetGlobalPermission } from "@hooks/useGetGlobalPermission";
import { PermissionsGate } from "@layouts/PermissionGate";
import NMService from "@services/nm.service";
import { autoProvisionActions, autoProvisionAtom } from "@store/autoProvision";
import { addGlobalMessageAtom } from "@store/globalMessage";
import { useAtom, useSetAtom } from "jotai";
import { Fragment } from "react";
import { useForm } from "react-hook-form";
import { Button, Divider, Form, Grid, Header, Input } from "semantic-ui-react";
import styled from 'styled-components';
import * as yup from 'yup';

const ErrorMessage = styled.span`
  font-size: 12px;
  color: red;
`;

const autoProvisioningFormSchema = yup.object().shape({
    keyword: yup.string().trim()
        .required("Keyword is required.")
        .min(8, "Keyword length should be at least 8 characters.")
        .max(128, "Keyword cannot exceed more than 128 characters.")
        .matches(/^[a-zA-Z0-9_-]{8,128}$/, 'Only alphanumeric characters are allowed.'),
    validto: yup.date()
        .required("Timestamp is required.")
        .nullable()
        .min(new Date(), "Timestamp can not be in the past.")
})


const AutoProvisioningForm = (props) => {
    const addGlobalMessage = useSetAtom(addGlobalMessageAtom);
    const hasPermision = useGetGlobalPermission(ADMIN);
    const [, dispatch] = useAtom(autoProvisionAtom);

    const formOptions = { resolver: yupResolver(autoProvisioningFormSchema) };
    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors },
    } = useForm(formOptions);

    const onSubmit = async (values) => {
        let { keyword, validto } = values;

        let data = {
            keyword,
            validto: new Date(validto).toISOString(),
        };

        await NMService.addAutoProvisioningKeyword(data).then(() => {
            props.refetch();
            addGlobalMessage({
                header: `Auto-provisioning keyword was created!`,
                content: `Auto-provisioning keyword was successfully created.`,
                type: "positive",
            })
        })
        .catch(e => null);
    };

    return (
        <Fragment>
            <Header as="h4" style={{ marginTop: '2rem', marginBottom: '0' }}>
                Add new auto-provisioning keyword
            </Header>
            <Divider clearing style={{ marginTop: '0.5rem', marginBottom: '1rem' }} />
            <Form onSubmit={handleSubmit(onSubmit)}>
                <Grid padded>
                    <Grid.Row columns={3}>
                        <Grid.Column>
                            <Form.Field error={Boolean(errors.keyword)}>
                                <label>Keyword</label>
                                <Input
                                    {...register('keyword')}
                                    placeholder="Keyword"
                                    onChange={(_, { name, value }) => {
                                        setValue(name, value);
                                    }}
                                />
                                {errors.keyword && <ErrorMessage>{errors.keyword.message}</ErrorMessage>}
                            </Form.Field>
                        </Grid.Column>
                        <Grid.Column>
                            <Form.Field error={Boolean(errors.validto)}>
                                <label>Keyword expiry date</label>
                                <Input
                                    type="datetime-local"
                                    {...register('validto')}
                                    onChange={(_, { name, value }) => {
                                        setValue(name, value);
                                    }}
                                />
                            </Form.Field>
                            {errors.validto && <ErrorMessage>{errors.validto.message}</ErrorMessage>}
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row verticalAlign="middle">
                        <Grid.Column width={4}>
                            <PermissionsGate hasPermission={hasPermision}>
                                <Button size="small" type="submit" primary>
                                    Create
                                </Button>
                            </PermissionsGate>
                            <Button
                                size="small"
                                type="button"
                                onClick={() => dispatch({ type: autoProvisionActions.CloseForm })}
                            >
                                Cancel
                            </Button>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Form>
        </Fragment>
    );
}

export default AutoProvisioningForm;