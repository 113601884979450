import ConfirmationModal from '@components/ConfirmationModal';
import CustomTable from '@components/CustomTable';
import { ADMIN } from '@constants/roles';
import { useFetchReports } from '@hooks/useFetchReports';
import { useGetGlobalPermission } from '@hooks/useGetGlobalPermission';
import { PermissionsGate } from '@layouts/PermissionGate';
import NMService from '@services/nm.service';
import { addGlobalMessageAtom } from '@store/globalMessage';
import { reportActions, reportsAtom } from '@store/reports';
import { useAtom, useSetAtom } from 'jotai';
import { useMemo } from 'react';
import { Button, Divider, Header } from 'semantic-ui-react';
import ReportForm from './ReportForm';
import ReportInfo from './ReportInfo';


const Reports = () => {
    const addGlobalMessage = useSetAtom(addGlobalMessageAtom);
    const hasPermision = useGetGlobalPermission(ADMIN);
    const [state, dispatch] = useAtom(reportsAtom);

    const {
        data,
        isLoading: reportListIsLoading,
        refetch: refetchReportList
    } = useFetchReports({
        options: {
            refetchOnMount: 'always'
        }
    })

    const deleteReport = () => {
        NMService.deleteReport(state.selectedItem.id).then(() =>
            addGlobalMessage({
                header: 'Report was deleted!',
                content: 'Report was successfully deleted.',
                type: 'positive'
            }))
            .then(() => {
                refetchReportList();
                dispatch({ type: reportActions.CLEAR_SHOW_TYPE })
            }).catch(e => null)
    }

    const columns = useMemo(
        () => [
            {
                id: "name",
                Header: "Name",
                accessor: "name",
                width: 2,
            },
            {
                id: "description",
                Header: "Description",
                accessor: "description",
            },
        ],
        []
    );

    const tableHooks = (hooks) => {
        hooks.visibleColumns.push((columns) => [
            ...columns,
            {
                Header: () => null,
                id: "actions",
                Cell: ({ row }) =>
                    <div style={{ textAlign: 'center' }}>
                        <PermissionsGate hasPermission={hasPermision}>
                            <Button
                                circular
                                icon='info'
                                size='tiny'
                                type="button"
                                onClick={() => dispatch({ type: reportActions.SHOW_REPORT_INFO, value: row.original })}
                            />
                        </PermissionsGate>
                        <PermissionsGate hasPermission={hasPermision}>
                            <Button
                                circular
                                icon="trash"
                                size="tiny"
                                type="button"
                                onClick={() => dispatch({ type: reportActions.DELETE_REPORT, value: row.original })}
                            />
                        </PermissionsGate>
                    </div>,
                disableGlobalFilter: true,
                disableFilters: true,
                width: 2,
            },
        ]);
    };

    return reportListIsLoading ? null : (
        <>
            <Header as="h4" style={{ marginTop: '1.5rem', marginBottom: '0' }}>Reporting</Header>
            <Divider style={{ marginTop: '0.5rem', marginBottom: '0.5rem' }} />
            <CustomTable
                data={data}
                columns={columns}
                tableHooks={tableHooks}
                active={state?.selectedItem?.id}
                footerButton={() =>
                    <Button
                        primary
                        type="button"
                        size="tiny"
                        floated='right'
                        content="Add new report"
                        onClick={() => dispatch({ type: reportActions.ADD_NEW_REPORT })}
                    />
                }
            />
            {reportActions.ADD_NEW_REPORT === state.showType && (
                <ReportForm />
            )}

            {reportActions.SHOW_REPORT_INFO === state.showType && (
                <ReportInfo />
            )}

            <ConfirmationModal
                open={state.showType === reportActions.DELETE_REPORT}
                header="Delete report"
                content="Are you sure you want to delete report?"
                onConfirm={() => deleteReport()}
                onDismiss={() => dispatch({ type: reportActions.CLEAR_SHOW_TYPE })}
            />
        </>
    )
}


export default Reports;