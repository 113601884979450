import ConfirmationModal from "@components/ConfirmationModal";
import { useUserInfo } from "@hooks/useAuth";
import { useFetchProbeDetails } from "@hooks/useFetchProbeDetails";
import { useFetchProbeLicense } from "@hooks/useFetchProbeLicense";
import { useFetchProbeModuleBundle } from "@hooks/useFetchProbeModuleBundle";
import { useFetchRegistry } from "@hooks/useFetchRegistry";
import { useFetchSuspendedMeasurements } from "@hooks/useFetchSuspendedMeasurements";
import { useFetchSwModules } from "@hooks/useFetchSwModules";
import { useFetchValidLicenses } from "@hooks/useFetchValidLicenses";
import { AnchorStyleButton } from "@layouts/AnchorStyleButton";
import { PermissionsGate } from "@layouts/PermissionGate";
import NMService from "@services/nm.service";
import { editProbeAtom } from "@store/editProbe";
import { addGlobalMessageAtom } from "@store/globalMessage";
import { EXPIRY_DAYS_WARNING, getExpiryDays } from "@utils/LicenseExpiry";
import NMFieldValidator from "@utils/NMFieldValidator";
import { useAtom, useSetAtom } from "jotai";
import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router";
import { Breadcrumb, Card, Container, Divider, Grid, Icon, Input, Label, Loader, Select } from "semantic-ui-react";
import { getProbePermission } from "../../helpers/userPermission";
import AssignedModules from "./AssignedModules/AssignedModules";
import InstalledModules from "./InstalledModules/InstalledModules";
import NetworkManagment from "./NetworkManagment/NetworkManagment";
import ProbeData from "./ProbeData";
import ProbeDetails from "./ProbeDetails";
import ProbeLicense from "./ProbeLicense";
import ProbePermissions from "./ProbePermissions";
import UnassignedModules from "./UnassignedModules/UnassignedModules";
import VPN from "./VPN/VPN";
import { useGetGlobalPermission } from "@hooks/useGetGlobalPermission";
import { TENANT_ADMIN } from "@constants/roles";

const keywordOrder = ["micro", "small", "medium", "large"];

const getKeywordRank = (title) => {
    if (!title) return null;
    const keyword = title.split(" - ").pop().toLowerCase();
    return keywordOrder.indexOf(keyword);
};

const ProbeEdit = () => {
    const hasPermission = useGetGlobalPermission(TENANT_ADMIN);
    const { probeid } = useParams();
    const navigate = useNavigate();
    const {
        userdata: { role: userRole, groups: userGroups },
    } = useUserInfo();
    const selectedLicenseRef = useRef();
    const suspendMinutesRef = useRef();
    suspendMinutesRef.current = "";

    const addGlobalMessage = useSetAtom(addGlobalMessageAtom);
    const [probeState, setProbeState] = useAtom(editProbeAtom);
    const [showModal, setShowModal] = useState(undefined);
    const [licensesForUpgrade, setLicensesForUpgrade] = useState([]);

    const {
        setError,
        clearErrors,
        formState: { errors },
    } = useForm({});

    const {
        data: probeLicense,
        refetch: refetchProbeLicense,
        isLoading: probeLicenseIsLoading,
    } = useFetchProbeLicense({
        probeid,
        options: {
            refetchOnMount: "always",
        },
    });

    const { data: validLicenses = [] } = useFetchValidLicenses({
        options: {
            refetchOnMount: "always",
        },
        select: (e) => {
            let sorted = e.sort((a, b) => {
                const rankA = getKeywordRank(a.name);
                const rankB = getKeywordRank(b.name);

                if (rankA < rankB) return -1;
                if (rankA > rankB) return 1;

                if (a.remaining < b.remaining) return -1;
                if (a.remaining > b.remaining) return 1;

                const expiryDaysA = getExpiryDays(a.issueDate, a.warranty);
                const expiryDaysB = getExpiryDays(b.issueDate, b.warranty);

                if (expiryDaysA < expiryDaysB) return -1;
                if (expiryDaysA > expiryDaysB) return 1;
                return 0;
            });

            return sorted.map((l) => {
                let text = `${l.name.split(" - ").pop()} (${l.remaining})`;
                const expiryDays = getExpiryDays(l.issueDate, l.warranty);
                if (expiryDays != null) {
                    let date = new Date();
                    date.setDate(date.getDate() + expiryDays);
                    text += ` - expires on ${date.toLocaleDateString("hr-HR")}`;
                }
                return {
                    key: l.uuid,
                    value: l.uuid,
                    text: text,
                    name: l.name,
                };
            });
        },
    });

    useEffect(() => {
        if (!probeLicense || probeLicenseIsLoading) {
            return;
        }
        let currentLicenseRank = getKeywordRank(probeLicense.name);
        let shouldUpgradeOfSameTypeBeAllowed = false;
        // If the license is not expiring soon or has not expired yet, there is no need to allow an upgrade to the same type
        if (
            probeState.license.expiryDays &&
            probeState.license.expiryDays <= EXPIRY_DAYS_WARNING
        ) {
            shouldUpgradeOfSameTypeBeAllowed = true;
        }

        let upgradableLicenses = validLicenses.filter((l) => {
            if (shouldUpgradeOfSameTypeBeAllowed) {
                return getKeywordRank(l.name) >= currentLicenseRank;
            }
            return getKeywordRank(l.name) > currentLicenseRank;
        });
        setLicensesForUpgrade(upgradableLicenses);
    }, [probeLicense, validLicenses, probeState.license]); //eslint-disable-line

    const { data: probeDetails, isError: isDetailsError } =
        useFetchProbeDetails({
            probeid,
            options: {
                refetchOnMount: "always",
            },
        });

    const { data: registry } = useFetchRegistry({
        probeid,
        select: (data) => data.find((e) => e.nodeid === probeDetails.id),
        options: {
            refetchOnMount: "always",
            enabled: !!probeDetails.id,
        },
    });

    const {
        refetch: refetchModuleBundle,
        isLoading: probeModuleBundleIsLoading,
        isError: isModuleBundleError,
    } = useFetchProbeModuleBundle({
        probeid,
        options: {
            refetchOnMount: "always",
        },
    });

    const { isLoading: moduleListIsLoading } = useFetchSwModules({
        options: {
            refetchOnMount: "always",
        },
    });

    const { data: suspendMeasurements, refetch: refetchSuspendedMeasurements } =
        useFetchSuspendedMeasurements({
            params: {
                unit: "NODE",
                nodeID: probeid,
            },
            options: {
                refetchOnMount: "always",
            },
        });

    const reboot = () =>
        NMService.executeOnDemand({ nodeid: probeid, command: "reboot" })
            .then(() =>
                addGlobalMessage({
                    header: "Node scheduled for reboot!",
                    content: `Node with ID "${probeid}" scheduled for reboot.`,
                    type: "positive",
                })
            )
            .catch((e) => null)
            .finally(() => setShowModal(undefined));

    const updateNode = () =>
        NMService.executeOnDemand({ nodeid: probeid, command: "updateNode" })
            .then(() =>
                addGlobalMessage({
                    header: "Node scheduled for upgrade",
                    content: `Node with ID "${probeid}" scheduled for upgrade.`,
                    type: "positive",
                })
            )
            .catch((e) => null)
            .finally(() => setShowModal(undefined));

    const shutdown = () =>
        NMService.executeOnDemand({ nodeid: probeid, command: "shutdown" })
            .then(() =>
                addGlobalMessage({
                    header: "Node scheduled for shutdown!",
                    content: `Node with ID "${probeid}" scheduled for shutdown.`,
                    type: "positive",
                })
            )
            .catch((e) => null)
            .finally(() => setShowModal(undefined));

    const startBlinking = () =>
        NMService.executeOnDemand({ nodeid: probeid, command: "identify" })
            .then(() =>
                addGlobalMessage({
                    header: "Node identification was started",
                    content: `Identification for node with ID "${probeid}" started.`,
                    type: "positive",
                })
            )
            .catch((e) => null)
            .finally(() => setShowModal(undefined));

    const deleteNode = () =>
        NMService.deleteNode(probeid)
            .then(() => {
                navigate("/nodes");
                addGlobalMessage({
                    header: "Node was deleted successfully!",
                    content: `Node with HW ID "${probeDetails.hwid}" is unmanaged now.`,
                    type: "positive",
                });
            })
            .catch((e) => null)
            .finally(() => setShowModal(undefined));

    const assignLicenseToNode = () =>
        NMService.assignLicenseToNode(selectedLicenseRef.current, probeid)
            .then(() =>
                addGlobalMessage({
                    header: "License successfully applied",
                    content: "License successfully applied",
                    type: "positive",
                })
            )
            .catch((e) => null)
            .finally(() => {
                refetchProbeLicense();
                setShowModal(undefined);
            });

    const upgradeLicense = () =>
        NMService.upgradeLicense(selectedLicenseRef.current, probeid)
            .then(() =>
                addGlobalMessage({
                    header: "License successfully upgraded",
                    content: "License successfully upgraded",
                    type: "positive",
                })
            )
            .catch((e) => null)
            .finally(() => refetchProbeLicense());

    const createGraphs = () =>
        NMService.createDefaultGraphsForNode(probeid)
            .then((r) =>
                addGlobalMessage({
                    header: r.data?.message
                        ? r.data.message
                        : "Created default graphs!",
                    content: r.data?.message
                        ? r.data.message
                        : `Successfully created default graphs for node ID "${probeid}".`,
                    type: r.data?.message ? "warning" : "positive",
                })
            )
            .catch((e) => null)
            .finally(() => setShowModal(undefined));

    const removeLicenseFromProbe = () =>
        NMService.removeLicenseFromNode(probeid)
            .then(() =>
                addGlobalMessage({
                    header: "License successfully removed",
                    content: "License successfully removed",
                    type: "positive",
                })
            )
            .then(() => refetchModuleBundle())
            .catch((e) => null)
            .finally(() => {
                refetchProbeLicense();
                setShowModal(undefined);
            });

    const toggleSuspendMeasurements = () => {
        let params = {
            duration: Number(suspendMinutesRef.current),
            suspend: !suspendMeasurements?.suspend,
            unit: "node",
            nodeID: Number(probeid),
        };

        if (!suspendMeasurements?.suspend) {
            let validatorMessage = NMFieldValidator.validateInt(
                suspendMinutesRef.current,
                0,
                undefined,
                true
            );
            if (validatorMessage != null) {
                setError("suspendMeasurements", {
                    message: validatorMessage,
                    type: "manual",
                });
                return;
            }
        } else {
            delete params.duration;
        }

        NMService.suspendMeasurements(params)
            .then((r) =>
                addGlobalMessage({
                    header: r.data?.message
                        ? r.data.message
                        : `Successfully ${
                              suspendMeasurements?.suspend
                                  ? "resumed"
                                  : "suspended"
                          } measurements!`,
                    content: r.data?.message
                        ? r.data.message
                        : `Successfully ${
                              suspendMeasurements?.suspend
                                  ? "resumed"
                                  : "suspended"
                          } measurements for ${probeid}.`,
                    type: r.data?.message ? "warning" : "positive",
                })
            )
            .catch((e) => null)
            .finally(() => {
                setShowModal(undefined);
                clearErrors("suspendMeasurements");
                suspendMinutesRef.current = "";
                refetchSuspendedMeasurements();
                refetchModuleBundle();
            });
    };

    useEffect(() => {
        if (probeDetails.groups && probeDetails.permissions) {
            const probePermission = getProbePermission(
                userRole,
                userGroups,
                probeDetails.groups,
                probeDetails.permissions
            );
            setProbeState({
                type: "set-permissions",
                value: {
                    id: probeid,
                    permissions: probePermission,
                },
            });
        }
        return () => setProbeState({ type: "" });
        //eslint-disable-next-line
    }, [
        probeid,
        probeDetails?.groups,
        probeDetails?.permissions,
        userRole,
        userGroups,
    ]);

    useEffect(() => {
        if (!probeLicense || probeLicenseIsLoading) {
            // Waiting for probeLicense to be loaded or valid.
            return;
        }
        let date = new Date();
        date.setHours(0, 0, 0, 0);

        let expiryDays = getExpiryDays(
            probeLicense.issueDate,
            probeLicense?.warranty
        );

        setProbeState({
            type: "set-license",
            value: {
                uuid: probeLicense?.uuid ? probeLicense?.uuid : "",
                name: probeLicense?.uuid ? probeLicense?.name : "",
                quantity: probeLicense?.uuid ? probeLicense?.quantity : null,
                expiryDays,
            },
        });
    }, [probeLicense, probeState.license.uuid, probeLicenseIsLoading]); //eslint-disable-line

    const card = (name, content) => (
        <Card
            fluid
            style={{
                height: "5rem",
                backgroundColor: "rgba(65, 131, 196, 0.05)",
            }}
        >
            <Card.Content>
                <Card.Header content={name} />
                <Card.Meta content={content} textAlign="center" />
            </Card.Content>
        </Card>
    );

    if (
        !probeState.id ||
        !probeState.permissions ||
        moduleListIsLoading ||
        probeLicenseIsLoading ||
        probeModuleBundleIsLoading
    ) {
        if (isDetailsError || isModuleBundleError) {
            return (
                <Container
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <Icon
                        name="warning sign"
                        size="massive"
                        color="red"
                        style={{ position: "absolute", marginTop: "15rem" }}
                    />
                    <Label style={{ position: "absolute", marginTop: "30rem" }}>
                        Error while fetching data, please refresh page
                    </Label>
                </Container>
            );
        }
        return (
            <Loader
                size="big"
                active
                inline="centered"
                style={{ marginTop: "10rem" }}
            />
        );
    }

    return (
        <>
            <Breadcrumb style={{ marginTop: "1rem" }}>
                <Breadcrumb.Section>Nodes</Breadcrumb.Section>
                <Breadcrumb.Divider>/</Breadcrumb.Divider>
                <Breadcrumb.Section active>
                    {probeDetails.name ? "Managed node" : "Newly managed node"}
                </Breadcrumb.Section>
            </Breadcrumb>
            <ProbeLicense
                probeLicense={probeLicense}
                availableUpgradeLicense={licensesForUpgrade}
                hasPermission={probeState?.permissions?.editbasicdata}
                setShowModal={setShowModal}
            />
            <Divider hidden style={{ margin: "0 0 3rem 0" }} />

            <Grid>
                <Grid.Row>
                    <Grid.Column width={3}>
                        {card("ID", probeDetails.id)}
                    </Grid.Column>
                    <Grid.Column width={3}>
                        {card("HWID", probeDetails.hwid)}
                    </Grid.Column>
                    <Grid.Column width={3}>
                        {card("Name", probeDetails.name)}
                    </Grid.Column>
                    <Grid.Column width={7}>
                        <Card
                            style={{
                                width: "100%",
                                backgroundColor: "rgba(65, 131, 196, 0.05)",
                                minHeight: "5rem",
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                padding: "1rem",
                            }}
                        >
                            <Grid>
                                <Grid.Row verticalAlign="middle" textAlign="center" columns="equal" style={{ width: "100%", margin: 0, padding: "0.5rem 0" }} >
                                    {(!suspendMeasurements?.suspend || suspendMeasurements?.unit === "node") && (
                                        <Grid.Column>
                                            <PermissionsGate hasPermission={ probeState?.permissions?.editbasicdata } >
                                                <AnchorStyleButton
                                                    title={`${ suspendMeasurements?.suspend ? "Resume" : "Suspend" } measurements`}
                                                    onClick={() => setShowModal( "suspendMeasurements" ) }
                                                >
                                                    <Icon name={ suspendMeasurements?.suspend ? "play" : "pause" } />
                                                    {suspendMeasurements?.suspend ? "Resume" : "Suspend"}
                                                </AnchorStyleButton>
                                            </PermissionsGate>
                                        </Grid.Column>
                                    )}
                                    <Grid.Column>
                                        <PermissionsGate hasPermission={ probeState?.permissions?.editbasicdata } >
                                            <AnchorStyleButton title="Create default graphs" onClick={() => setShowModal("createGraphs")} >
                                                <Icon name="chart line" />
                                                Graph
                                            </AnchorStyleButton>
                                        </PermissionsGate>
                                    </Grid.Column>
                                    <Grid.Column>
                                        <PermissionsGate hasPermission={ probeState?.permissions?.editbasicdata } >
                                            <AnchorStyleButton title="Move node to unmanaged state" onClick={() => setShowModal("deleteNode") } >
                                                <Icon name="trash alternate outline" />
                                                Delete
                                            </AnchorStyleButton>
                                        </PermissionsGate>
                                    </Grid.Column>
                                </Grid.Row>
                                {!hasPermission ? null : 
                                    <Grid.Row verticalAlign="middle" textAlign="center" columns="equal" style={{ width: "100%", margin: 0, padding: "0.5rem 0" }} >
                                        <Grid.Column>
                                            <PermissionsGate hasPermission={ probeState?.permissions?.editbasicdata } >
                                                <AnchorStyleButton onClick={() => setShowModal("updateNode")} >
                                                    <Icon name="recycle" />
                                                    Upgrade
                                                </AnchorStyleButton>
                                            </PermissionsGate>
                                        </Grid.Column>
                                        <Grid.Column>
                                            <PermissionsGate hasPermission={ probeState?.permissions?.editbasicdata } >
                                                <AnchorStyleButton onClick={() => setShowModal("reboot") } >
                                                    <Icon name="sync" />
                                                    Reboot
                                                </AnchorStyleButton>
                                            </PermissionsGate>
                                        </Grid.Column>
                                        <Grid.Column>
                                            <PermissionsGate hasPermission={ probeState?.permissions?.editbasicdata } >
                                                <AnchorStyleButton onClick={() => setShowModal("shutdown") } >
                                                    <Icon name="shutdown" />
                                                    Shutdown
                                                </AnchorStyleButton>
                                            </PermissionsGate>
                                        </Grid.Column>
                                        {probeDetails.hasOwnProperty("devicemodel") && probeDetails.devicemodel.includes("rpi") && (
                                            <Grid.Column>
                                                <PermissionsGate hasPermission={ probeState?.permissions?.editbasicdata } >
                                                    <AnchorStyleButton title="Identify node" onClick={() => setShowModal( "blink" ) } >
                                                        <Icon name="lightbulb" />
                                                        Blink
                                                    </AnchorStyleButton>
                                                </PermissionsGate>
                                            </Grid.Column>
                                        )}
                                    </Grid.Row>}
                            </Grid>
                        </Card>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            <Divider hidden style={{ margin: "0 0 3rem 0" }} />
            <VPN id={probeDetails.id} />
            <NetworkManagment id={probeDetails.id} />
            <ProbeDetails probeId={probeDetails.id} />
            <ProbeData
                probeId={probeDetails.id}
                probeDetails={probeDetails}
                registry={registry}
            />
            <ProbePermissions probeId={probeDetails.id} />
            <InstalledModules probeId={probeDetails.id} />
            <AssignedModules probeId={probeDetails.id} />
            <UnassignedModules probeId={probeDetails.id} />

            <ConfirmationModal
                open={showModal === "updateNode"}
                header="Upgrade node"
                content="Are you sure you want to schedule node for upgrade?"
                onConfirm={updateNode}
                onDismiss={() => setShowModal(undefined)}
            />
            <ConfirmationModal
                open={showModal === "deleteNode"}
                header="Delete node"
                content={`Are you sure you want to delete node with ID ${probeid}?`}
                onConfirm={deleteNode}
                onDismiss={() => setShowModal(undefined)}
            />
            <ConfirmationModal
                open={showModal === "reboot"}
                header="Reboot node"
                content="Are you sure you want to schedule node for reboot?"
                onConfirm={reboot}
                onDismiss={() => setShowModal(undefined)}
            />
            <ConfirmationModal
                open={showModal === "shutdown"}
                header="Shutdown node"
                content="Are you sure you want to schedule node for shutdown?"
                onConfirm={shutdown}
                onDismiss={() => setShowModal(undefined)}
            />
            <ConfirmationModal
                open={showModal === "blink"}
                header="Node identification"
                content="Are you sure you want to start node identification?"
                onConfirm={startBlinking}
                onDismiss={() => setShowModal(undefined)}
            />
            <ConfirmationModal
                open={showModal === "assignLicense"}
                header="Apply license to node"
                content={
                    <>
                        Choose license:&emsp;
                        <Select
                            placeholder="Select license..."
                            options={validLicenses}
                            selectOnBlur={false}
                            style={{ width: "25rem" }}
                            onChange={(_, { value }) =>
                                (selectedLicenseRef.current = value)
                            }
                        />
                    </>
                }
                onConfirm={assignLicenseToNode}
                onDismiss={() => {
                    setShowModal(undefined);
                    selectedLicenseRef.current = null;
                }}
            />
            <ConfirmationModal
                open={showModal === "upgradeLicense"}
                header="Upgrade license"
                content={
                    <>
                        Choose license:&emsp;
                        <Select
                            placeholder="Select license..."
                            options={licensesForUpgrade}
                            selectOnBlur={false}
                            onChange={(_, { value }) =>
                                (selectedLicenseRef.current = value)
                            }
                        />
                    </>
                }
                onConfirm={upgradeLicense}
                onDismiss={() => {
                    setShowModal(undefined);
                    selectedLicenseRef.current = null;
                }}
            />
            <ConfirmationModal
                open={showModal === "removeLicense"}
                header="Remove license from node"
                content={
                    <>
                        Are you sure you want to remove license from node?
                        <br />
                        <br />
                        <pre style={{ color: "red" }}>
                            Removing the license will remove all modules!
                            <br />
                            Please save them as a template before removing
                            license!
                        </pre>
                    </>
                }
                onConfirm={removeLicenseFromProbe}
                onDismiss={() => setShowModal(undefined)}
            />
            <ConfirmationModal
                open={showModal === "createGraphs"}
                header="Create default graphs"
                content={
                    <>
                        Create default graphs for node {probeid}?<br />
                        <pre style={{ color: "red" }}>
                            This action will regenerate all existing default
                            dashboards
                        </pre>
                    </>
                }
                onConfirm={createGraphs}
                onDismiss={() => setShowModal(undefined)}
            />
            <ConfirmationModal
                open={showModal === "suspendMeasurements"}
                header={`${
                    suspendMeasurements?.suspend ? "Resume" : "Suspend"
                } measurements`}
                content={
                    <>
                        {suspendMeasurements?.suspend ? "Resume" : "Suspend"}{" "}
                        measurements for node {probeid}
                        <br />
                        <pre style={{ color: "red" }}>
                            This action will{" "}
                            {suspendMeasurements?.suspend
                                ? "resume"
                                : "suspend"}{" "}
                            all measurements for node {probeid}
                        </pre>
                        {!suspendMeasurements?.suspend && (
                            <Input>
                                <input
                                    placeholder={"Suspend time [minutes]"}
                                    type={"number"}
                                    min={0}
                                    style={{ width: 200 }}
                                    onChange={(e) =>
                                        (suspendMinutesRef.current =
                                            e.target.value)
                                    }
                                />

                                {errors.suspendMeasurements?.type ===
                                    "manual" && (
                                    <Label
                                        basic
                                        color="red"
                                        pointing="left"
                                        content={
                                            errors.suspendMeasurements.message
                                        }
                                    />
                                )}
                            </Input>
                        )}
                    </>
                }
                onConfirm={toggleSuspendMeasurements}
                onDismiss={() => {
                    setShowModal(undefined);
                    clearErrors("suspendMeasurements");
                    suspendMinutesRef.current = "";
                }}
            />
            <Divider
                hidden
                style={{ marginTop: "2rem", marginBottom: "1rem" }}
            />
        </>
    );
};

export default ProbeEdit;
