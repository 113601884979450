import React from 'react';

export function PermissionsGate ({ children, hasPermission }) {

    if (hasPermission) {
        return <React.Fragment>{ React.cloneElement(children)}</React.Fragment>;
    }

    return <React.Fragment>{React.cloneElement(children, { disabled: true, onClick: () => null })}</React.Fragment>;
}
