import CustomHeader from "@components/CustomHeader";
import { yupResolver } from "@hookform/resolvers/yup";
import { useFetchLocations } from "@hooks/useFetchLocations";
import { useFetchProbeDetails } from "@hooks/useFetchProbeDetails";
import { useFormFields } from "@hooks/useFormFields";
import { PermissionsGate } from "@layouts/PermissionGate";
import NMService from "@services/nm.service";
import { editProbeAtom } from "@store/editProbe";
import { addGlobalMessageAtom } from "@store/globalMessage";
import { strftime } from "@utils/strftime";
import { useAtomValue, useSetAtom } from 'jotai';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Form } from 'semantic-ui-react';
import * as yup from 'yup';

const probeDetailsFormSchema = yup.object().shape({
    name: yup.string().trim()
        .required("Name is required.")
        .min(2, "Name length should be at least 2 characters.")
        .max(64, "Name cannot exceed more than 64 characters.")
        .matches(/^[a-zA-Z0-9.-]{2,64}$/, "Name can contain ASCII letters, numbers, '.' and '-'."),
    description: yup.string().when('description', {
        is: (exists) => !!exists,
        then: (schema) => schema
            .min(1, "Description length should be at least 1 characters.")
            .max(128, "Description cannot exceed more than 128 characters.")
            .matches(
                /^[a-zA-Z0-9%_.,; /+\u00C0-\u017F-]{1,128}$/,
                "Name can contain ASCII letters with grave, numbers and symbols ( %_.,; ) "
            ),
    }),
    tags: yup.string().trim().when('tags', {
        is: (exists) => !!exists,
        then: (schema) => schema
    })
}, [
    ['tags', 'tags'], 
    ['description', 'description']
]);



const ProbeDetails = (props) => {
    const probeid = useMemo(() => props.probeId, [props.probeId]);
    const addGlobalMessage = useSetAtom(addGlobalMessageAtom);
    const probeAtomState = useAtomValue(editProbeAtom);
    const hasPermission = Boolean(probeAtomState?.permissions?.editbasicdata)
    const tagsInfo = "Up to 10 comma separated tags matching: /^[a-zA-Z0-9\u00C0-\u017F.-]{1,20}$/"

    const formOptions = { resolver: yupResolver(probeDetailsFormSchema) };
    const {
        reset,
        register,
        setValue,
        watch,
        handleSubmit,
        setError,
        clearErrors,
        formState: { errors }
    } = useForm(formOptions);

    const { renderInput, renderDropdown } = useFormFields({ register, errors, setValue, watch });

    const initialDataRef = useRef();
    const [showForm, setShowForm] = useState(false);

    const {
        data: locationList,
    } = useFetchLocations({
        options: {
            refetchOnMount: 'always'
        },
        select: e => e.map(({ id, name }) => ({ key: id, value: id, text: name }))
    });

    const {
        data: probeDetails,
        isLoading: probeDetailsIsLoading,
        refetch: refetchProbeDetails,
    } = useFetchProbeDetails({
        probeid: probeid
    });

    const onSubmit = (values) => {
        let tags = [];
        if (values.tags) {
            tags = values.tags.split(",").map((e) => e.trim()).filter((e) => e !== "");
            tags = Array.from(new Set(tags))
            if (!tags.every(e => /^[a-zA-Z0-9\u00C0-\u017F.-]{1,20}$/.test(e)) || tags.length > 10) {
                setError('tags', { type: 'validate', message: tagsInfo })
                return
            }
        }
        clearErrors('tags');

        const updateProbeData = {
            ...values,
            created: probeDetails.created,
            tags: tags
        };

        NMService.updateNode(updateProbeData).then(() =>
            addGlobalMessage({
                header: 'Node details are updated!',
                content: 'Node details are  successfully updated.',
                type: 'positive',
            }))
        .then(r => refetchProbeDetails())
        .catch(e => null)
    }

    const getFormattedProbeDetails = (data) => {
        const { id, hwid, devicemodel, name, description, tags, locationdata, created } = data;
        let timestamp = new Date(Date.parse(created));

        return {
            id, hwid, devicemodel, name, description,
            created: strftime('%Y-%m-%d %H:%M:%S', timestamp),
            location: locationdata.id,
            tags: tags.join(', ')
        }
    }

    useEffect(() => {
        if (!probeDetailsIsLoading) {
            initialDataRef.current = getFormattedProbeDetails(probeDetails);
            reset(initialDataRef.current)
        }
    }, [probeDetails, probeDetailsIsLoading, reset]);

    return (
        <>
            <CustomHeader
                title='Node details'
                toggle={() => setShowForm(p => !p)}
                show={showForm}
            />
            {showForm &&
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <div style={{ margin: "1rem" }}>
                        <Form.Group widths={3}>
                            {renderInput("Device model", "devicemodel", { notRequired: true, readOnly: true })}
                            {renderInput("Created", "created", { notRequired: true, readOnly: true })}
                        </Form.Group>
                        <Form.Group widths={3}>
                            {renderInput("Name", "name")}
                            {renderInput("Description", "description", { notRequired: true })}
                        </Form.Group>
                        <Form.Group widths={3}>
                            {renderInput("Tags", "tags", { notRequired: true, infoText: tagsInfo })}
                            {renderDropdown("Location", "location", locationList, { notRequired: true })}
                        </Form.Group>
                        <Form.Group>
                            <PermissionsGate hasPermission={hasPermission}>
                                <Form.Button type='submit' size='small' content='Update' primary />
                            </PermissionsGate>
                            <PermissionsGate hasPermission={hasPermission}>
                                <Form.Button type='button' size='small' content='Reset' onClick={() => reset(initialDataRef.current)}/>
                            </PermissionsGate>
                        </Form.Group>
                    </div>
                </Form>
            }
        </>
    );
};

export default ProbeDetails;