import ConfirmationModal from '@components/ConfirmationModal';
import CustomTable from '@components/CustomTable';
import { useFetchLocationParameters } from '@hooks/useFetchLocationParameters';
import NMService from '@services/nm.service';
import { addGlobalMessageAtom } from '@store/globalMessage';
import { locationParametersActions, locationParametersAtom } from '@store/locationParameters';
import { useAtom, useSetAtom } from 'jotai';
import { Fragment, useMemo } from 'react';
import { Breadcrumb, Button, Divider } from 'semantic-ui-react';
import LocationParameterForm from './LocationParameterForm';

const LocationParameters = () => {
    const addGlobalMessage = useSetAtom(addGlobalMessageAtom);
    const [state, dispatch] = useAtom(locationParametersAtom);

    const columns = useMemo(() => [
        {
            id: 'name',
            Header: 'Parameter name',
            accessor: 'name',
        },
        {   
            id: 'description',
            Header: 'Description',
            accessor: 'description',
        },
    ], []);

    const tableHooks = (hooks) => {
        hooks.visibleColumns.push((columns) => [
            ...columns,
            {
                Header: () => null,
                id: 'actions',
                Cell: ({ row }) =>
                    <div style={{ textAlign: 'center' }}>
                        <Button
                            circular
                            icon="trash"
                            onClick={() => dispatch({ type: locationParametersActions.DELETE_LOCATION_PARAMETER, value: row.original.name })}
                        />
                    </div>,
                width: 1,
                disableGlobalFilter: true,
                disableFilters: true,
            },
        ]);
    };

    const {
        data,
        isLoading,
        refetch
    } = useFetchLocationParameters({
        options: {
            refetchOnMount: 'always'
        }
    });

    const deleteLocationParameter = () =>
        NMService.deleteLocationParam(state.selectedLocation).then(() =>
            addGlobalMessage({
                header: 'Location parameter is deleted!',
                content: `Location parameter ${state.selectedLocation} is successfully deleted.`,
                type: 'positive',
            }))
        .then(() => dispatch({ type: locationParametersActions.CLEAR_TYPE }))
        .catch(e => null)
        .finally(() => refetch())

    return isLoading ? null : (
        <Fragment>
            <Breadcrumb style={{ marginTop: '1rem' }}>
                <Breadcrumb.Section>Locations</Breadcrumb.Section>
                <Breadcrumb.Divider>/</Breadcrumb.Divider>
                <Breadcrumb.Section active>Location parameters</Breadcrumb.Section>
            </Breadcrumb>
            <Divider clearing />
            <CustomTable
                data={data}
                columns={columns}
                tableHooks={tableHooks}
                active={state.selectedLocation?.id}
                footerButton={() =>
                    <Button
                        floated="right"
                        primary
                        size="tiny"
                        content="Add new location parameter"
                        onClick={() => dispatch({ type: locationParametersActions.ADD_NEW_LOCATION_PARAMETER })}
                    />
                }
            />
            {state.showType === locationParametersActions.ADD_NEW_LOCATION_PARAMETER &&
                <LocationParameterForm refetch={refetch} />
            }
            <ConfirmationModal
                open={state.showType === locationParametersActions.DELETE_LOCATION_PARAMETER}
                header="Delete parameter"
                content="Are you sure you want to delete parameter?"
                onConfirm={() => deleteLocationParameter()}
                onDismiss={() => dispatch({ type: locationParametersActions.CLEAR_TYPE })}
            />
        </Fragment>
    );
}

export default LocationParameters;