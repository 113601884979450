import NMService from "@services/nm.service";
import { useQuery } from "@tanstack/react-query";

export const useFetchAvailableModules = ({
    probeid,
    select,
    options
} = {}) => {
    const query = useQuery({
        queryKey: ['getAvailableModules', probeid],
        queryFn: () => 
            NMService.getAvailableModules(probeid)
                .then(r => r.data)
                .catch(e => []),
        enabled: !!probeid,
        staleTime: Infinity,
        select,
        ...options
    })
    return { ...query, data: query.data ?? [] }
}