import { yupResolver } from '@hookform/resolvers/yup';
import { useFormFields } from '@hooks/useFormFields';
import NMService from '@services/nm.service';
import { addGlobalMessageAtom } from '@store/globalMessage';
import { locationParametersActions, locationParametersAtom } from '@store/locationParameters';
import { useSetAtom } from 'jotai';
import { Fragment } from 'react';
import { useForm } from 'react-hook-form';
import { Form, Header } from 'semantic-ui-react';
import * as yup from 'yup';

const locationParamsFormSchema = yup.object().shape({
    name: yup.string().trim()
        .required("Name is required.")
        .matches(/^[a-zA-Z0-9-._~]*$/, 'Name is not valid (a-zA-Z0-9-._~).'),
    description: yup.string().trim()
        .required("Description is required."),

})

const LocationParameterForm = (props) => {
    const addGlobalMessage = useSetAtom(addGlobalMessageAtom);
    const dispatch = useSetAtom(locationParametersAtom);
    const formOptions = {
        resolver: yupResolver(locationParamsFormSchema),
        defaultValues: {
            name: "",
            description: "",
        }
    };

    const { register, setValue, watch, handleSubmit, formState: { errors } } = useForm(formOptions);
    const { renderInput } = useFormFields({ register, errors, setValue, watch });

    const onSubmit = async (values) => {
        await NMService.addLocationParam(values)
            .then(() => {
                addGlobalMessage({
                    header: 'Location parameter is created!',
                    content: `Location parameter is successfully created.`,
                    type: 'positive',
                })
                dispatch({ type: locationParametersActions.CLEAR_TYPE })
                props.refetch();
            }).catch(e => null)
    };

    return (
        <Fragment>
            <Header as="h4" dividing content='Add new location parameter' />
            <Form onSubmit={handleSubmit(onSubmit)}>
                <Form.Group>
                    {renderInput("Name", "name", { width: 4 })}
                    {renderInput("Description", "description", { width: 8 })}
                </Form.Group>
                <Form.Group>
                    <Form.Button
                        size="small"
                        type='submit'
                        primary
                        content="Add"
                    />
                    <Form.Button
                        size="small"
                        type="button"
                        content="Cancel"
                        onClick={() => dispatch({ type: locationParametersActions.CLEAR_TYPE })}
                    />
                </Form.Group>
            </Form>
        </Fragment>
    );
}

export default LocationParameterForm;
