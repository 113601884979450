import { yupResolver } from '@hookform/resolvers/yup';
import { useFetchLocationParameters } from '@hooks/useFetchLocationParameters';
import { useFetchLocations } from '@hooks/useFetchLocations';
import { useFormFields } from '@hooks/useFormFields';
import NMService from '@services/nm.service';
import { addGlobalMessageAtom } from '@store/globalMessage';
import { locationsActions, locationsAtom } from '@store/locations';
import { useAtom, useSetAtom } from 'jotai';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Form, Grid, Header } from 'semantic-ui-react';
import * as yup from 'yup';
import LocationMap from './LocationMap';
import LocationParametersForm from './LocationParametersForm';

const locationParamsFormSchema = yup.object().shape({
    name: yup.string().trim()
        .required("Name is required.")
        .min(2, "Name length should be at least 2 characters.")
        .max(128, "Name cannot exceed more than 128 characters.")
        .matches(/^[a-zA-Z0-9%_.,; /+\u00C0-\u024F\u1E00-\u1EFF-]{2,}$/, 'Name can contain letters, numbers, %_.,;'),
    description: yup.string().trim()
        .required("Description is required.")
        .min(2, "Description length should be at least 2 characters.")
        .max(255, "Description cannot exceed more than 255 characters.")
        .matches(/^[a-zA-Z0-9%_.,; /+\u00C0-\u024F\u1E00-\u1EFF-]{2,}$/, 'Description can contain letters, numbers, %_.;'),
    latitude: yup.number()
        .min(-90, "Latitude must be greater than or equal to -90")
        .max(90, "Latitude must be less than or equal to 90"),
    longitude: yup.number()
        .min(-180, "Latitude must be  greater than or equal to -180")
        .max(180, "Latitude must be less than or equal to 180")
})

const LocationsForm = (props) => {
    const addGlobalMessage = useSetAtom(addGlobalMessageAtom);
    const [state, dispatch] = useAtom(locationsAtom);

    const formOptions = { resolver: yupResolver(locationParamsFormSchema) };

    const {
        data: locationParams = [],
    } = useFetchLocationParameters({
        options: {
            refetchOnMount: 'always'
        },
        select: e => e.map(e => ({key: e.name, text: `${e.name} (${e.description})`, value: e.name}))
    });

    const { handleSubmit, reset, register, setValue, getValues, watch, formState: { errors } } = useForm(formOptions);
    const { renderInput } = useFormFields({ register, errors, setValue, watch });

    const {
        refetch: refetchlocationList
    } = useFetchLocations({})

    const updateLocationParams = (data) => {onSubmit(({...getValues(), data: data}))}

    const onSubmit =  (data) => {
        let message = undefined
        let payload = {
            ...data,
            name: data.name.trim(),
            description: data.description.trim(),
        }
        if (state.selectedLocation?.id) {
            message = 'Location was successfully updated'
            payload = {...payload, id: state.selectedLocation.id }
        }
        if (!("data" in payload)) {
            payload  = {...payload, data: state.selectedLocation.data }
        }
        NMService.updateLocation((payload)).then(() =>
            addGlobalMessage({
                header: message ? message : 'New location successfully added',
                content: message ? message : 'New location successfully added',
                type: 'positive',
            }))
            .then(() => {
                dispatch({ type: locationsActions.CLEAR_TYPE })
                refetchlocationList();
            }).catch(e => null)
    };

    useEffect(() => {
        if (Object.keys(state.selectedLocation).length > 0) {
            const { name, description, latitude, longitude } = state.selectedLocation;
            reset({ name, description, latitude, longitude });
        } else {
            reset({ name: "", description: "", longitude: 15.966568, latitude: 45.815399 })
        }
    }, [state.selectedLocation, reset]);

    return (
        <>
            <Header as="h4" dividing>
                Basic location data
            </Header>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <Grid>
                    <Grid.Column width={6}>
                        <Grid.Row>
                            {renderInput("Location name", "name")}
                            {renderInput("Description", "description")}
                        </Grid.Row>
                        <Grid.Row style={{ marginTop: '2rem', marginBottom: '1rem' }}>
                            {renderInput("Latitude", "latitude", { inputType: "number", decimal: true })}
                            {renderInput("Longitude", "longitude", { inputType: "number", decimal: true })}
                        </Grid.Row>
                    </Grid.Column>
                    <Grid.Column width={10}>
                        {watch('latitude') !== undefined && watch('longitude') !== undefined && <LocationMap setValue={setValue} watch={watch}/>}
                    </Grid.Column>
                </Grid>
                {state.selectedLocation?.id &&
                    <Grid>
                        <Grid.Column width={6}>
                            <LocationParametersForm
                                parameters={locationParams}
                                id={state.selectedLocation.id}
                                data={state.selectedLocation?.data.rawjsondata}
                                post={updateLocationParams}
                                close={() => dispatch({ type: locationsActions.CLEAR_TYPE })}
                            />
                        </Grid.Column>
                    </Grid>
                }
                {!!!state.selectedLocation?.id &&
                    <Form.Group style={{ marginTop: '1rem' }}>
                        <Form.Button
                            size="small"
                            type='submit'
                            primary
                            content="Add"
                        />
                        <Form.Button
                            size="small"
                            type="button"
                            content="Cancel"
                            onClick={() => dispatch({ type: locationsActions.CLEAR_TYPE })}
                        />
                    </Form.Group>
                }
            </Form>
        </>
    );
}

export default LocationsForm;