import ConfirmationModal from "@components/ConfirmationModal";
import CustomHeader from "@components/CustomHeader";
import CustomTable from "@components/CustomTable";
import DateFormat from "@components/DateFormat";
import { useFetchAutoProvisioningKeywords } from "@hooks/useFetchAutoProvisioningKeywords";
import NMService from "@services/nm.service";
import { autoProvisionActions, autoProvisionAtom } from "@store/autoProvision";
import { addGlobalMessageAtom } from "@store/globalMessage";
import { useAtom, useSetAtom } from "jotai";
import { useEffect, useMemo } from "react";
import { Button } from "semantic-ui-react";
import AutoProvisioningForm from "./AutoProvisioningForm";

const AutoProvisioningConfig = () => {
    const [state, dispatch] = useAtom(autoProvisionAtom);
    const addGlobalMessage = useSetAtom(addGlobalMessageAtom);

    const {
        data,
        isLoading,
        isFetching,
        refetch
    } = useFetchAutoProvisioningKeywords({})

    const onDeleteKeyword = async () => {
        await NMService.deleteAutoProvisioningKeyword(state.selectedKeyword).then(() =>
            addGlobalMessage({
                header: `Auto-provisioning keyword is deleted!`,
                content: `Auto-provisioning keyword is successfully deleted.`,
                type: "positive",
            }))
        .catch(e => null)
        .finally(() => refetch())
    }

    const columns = useMemo(
        () => [
            {
                id: "keyword",
                Header: "Keyword",
                accessor: "keyword"
            },
            {
                id: "created",
                Header: "Created",
                accessor: (d) => <DateFormat timestamp={d.created} />
            },
            {
                id: "validto",
                Header: "Valid to",
                accessor: (d) => <DateFormat timestamp={d.validto} />
            },
        ],
        []
    );

    const tableHooks = (hooks) => {
        hooks.visibleColumns.push((columns) => [
            ...columns,
            {
                Header: () => null,
                id: "actions",
                Cell: ({ row }) =>
                    <div style={{ textAlign: 'center' }}>
                        <Button
                            circular
                            icon="trash"
                            type="button"
                            onClick={() => dispatch({
                                type: autoProvisionActions.DeleteKeyword,
                                value: row.original.keyword
                            })}
                        />
                    </div>,
                width: 2,
                disableGlobalFilter: true,
                disableFilters: true,
            },
        ]);
    };

    useEffect(() => {
        if (!isLoading && !isFetching) {
            dispatch({ type: autoProvisionActions.UpdateData })
        }
    }, [refetch, dispatch, isLoading, isFetching])

    return (
        <>
            <CustomHeader
                title='Auto-provisioning'
                toggle={() => dispatch({ type: autoProvisionActions.ToggleSegment })}
                show={state.showType}
            />
            {state.showType &&
                <CustomTable
                    data={data}
                    columns={columns}
                    hideSearch={true}
                    tableHooks={tableHooks}
                    footerButton={() =>
                        <Button
                            primary
                            size="tiny"
                            floated="right"
                            content="Add new keyword"
                            onClick={() => dispatch({ type: autoProvisionActions.AddNewKeyword })}
                        />
                    }
                />}

            {state.showType === autoProvisionActions.AddNewKeyword && (
                <AutoProvisioningForm refetch={refetch}/>
            )}

            {state.showType === autoProvisionActions.DeleteKeyword &&
                <ConfirmationModal
                    open={true}
                    header="Delete auto-provisioning keyword"
                    content="Are you sure you want to delete auto-provisioning keyword?"
                    onConfirm={() => onDeleteKeyword()}
                    onDismiss={() => dispatch({ type: autoProvisionActions.CloseForm })}
                />}
        </>
    );
}

export default AutoProvisioningConfig;
