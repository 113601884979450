import RenderObject from '@components/renderObject/RenderObject';
import { PermissionsGate } from '@layouts/PermissionGate';
import NMService from '@services/nm.service';
import { useQuery } from '@tanstack/react-query';
import { adjustConfig } from '@utils/configurator/adjustConfig';
import { adjustModel } from '@utils/configurator/adjustModel';
import { prepareConfig } from '@utils/configurator/prepareConfig';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Button, Form, Grid } from 'semantic-ui-react';

const Configurator = (props) => {
    const [model, setModel] = useState({});
    const methods = useForm();

    const {
        data: templateModuleData = {},
        isFetching: templateModuleIsFetching,
        refetch: refetchTemplateModuleData,
    } = useQuery({
        queryKey: ['getNMTemplateModule', props.templateid, props.moduleid, props.instanceid],
        queryFn: () =>
                NMService.getNMTemplateModule(props.templateid, props.moduleid, props.instanceid)
                .then(r => r.data)
                .catch(e => {}),
        enabled: !!props.templateid && !!props.moduleid && !!props.instanceid,
    });

    const onSubmit = (values) => {
        const config = prepareConfig(values);

        if (_.isEqual(config, prepareConfig(JSON.parse(templateModuleData.config || '{}')))) {
            props.noConfigChangeMessage()
            return
        }

        let templatedata = {
            templateid: props.templateid,
            moduleid: props.moduleid,
            instanceid: props.instanceid,
            config: JSON.stringify(config),
        };

        NMService.updateNMTemplateModuleConfig(templatedata).then(() => {
            props.formSubmitted()
            refetchTemplateModuleData()
        }).catch(e => null)
    };

    useEffect(() => {
        if (templateModuleData && !templateModuleIsFetching) {
            const config = JSON.parse(templateModuleData.config)
            const adjustedConfig = adjustConfig(config);
            methods.reset(adjustedConfig);
        }
    }, [templateModuleData, templateModuleIsFetching, methods]);

    useEffect(() => {
        if (props.model) {
            const updatedModel = adjustModel(props.model);
            for (const name in updatedModel) {
                if (updatedModel[name]?.values === 'dynamic') {
                    delete updatedModel[name]
                }
            }
            setModel(updatedModel);
        }
    }, [props.model]);

    return (
        <React.Fragment>
            {Object.keys(model).length > 0 && (
                <FormProvider {...methods}>
                    <Form style={{ padding: '0' }} onSubmit={methods.handleSubmit(onSubmit)}>
                        <Grid>
                            <Grid.Row>
                                <Grid.Column>
                                    <RenderObject
                                        {...{
                                            inline: false,
                                            parentName: undefined,
                                            model,
                                            schema: model
                                        }}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row style={{ padding: '0.5rem 1rem' }} verticalAlign="middle">
                                <Grid.Column width={6}>
                                    <Form.Field align="left">
                                        <PermissionsGate hasPermission={props.hasPermission}>
                                            <Button
                                                type="submit"
                                                size="small"
                                                primary
                                                content='Update'
                                                disabled={_.isEqual(methods.watch(), prepareConfig(JSON.parse(templateModuleData.config || '{}')))}
                                            />
                                        </PermissionsGate>
                                        <PermissionsGate hasPermission={props.hasPermission}>
                                            <Button
                                                type="button"
                                                size="small"
                                                onClick={refetchTemplateModuleData}
                                                content='Reset'
                                            />
                                        </PermissionsGate>
                                        <PermissionsGate  hasPermission={props.hasPermission}>
                                            <Button
                                                size="small"
                                                type="button"
                                                onClick={props.switchView}
                                                content='Switch view'
                                            />
                                        </PermissionsGate>
                                    </Form.Field>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Form>
                </FormProvider>
            )}
        </React.Fragment>
    );
};

export default Configurator;
