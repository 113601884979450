import ConfirmationModal from "@components/ConfirmationModal";
import { PermissionsGate } from "@layouts/PermissionGate";
import NMService from "@services/nm.service";
import { editProbeAtom } from "@store/editProbe";
import { addGlobalMessageAtom } from "@store/globalMessage";
import { useAtomValue, useSetAtom } from "jotai";
import React, { useEffect, useState } from "react";
import { Button, Checkbox, Grid, Popup, Select, Table } from "semantic-ui-react";

function NamespaceInterfaceTable(props) {
    const addGlobalMessage = useSetAtom(addGlobalMessageAtom);
    const probeAtomState = useAtomValue(editProbeAtom);

    const allNamespaces =
        props.all
            ?.map((e) => (e.name === props.data?.[0]?.namespace ? "default" : e.name))
            .sort((a, b) => (a.toLowerCase() > b.toLowerCase() ? 1 : b.toLowerCase() > a.toLowerCase() ? -1 : 0))
            .map((e) => ({ text: e, value: e, key: e })) || [];
    const [interfaces, setInterfaces] = useState([]);
    const [newNs, setNewNs] = useState({ intf: undefined, newNS: undefined });

    useEffect(() => {
        if (props.data) { setInterfaces(props.data) }
    }, [props.data]);

    const onChangeInterfaceEnable = (values) =>
        NMService.postInterfaceConfig(props.nodeId, values).then((r) =>
                addGlobalMessage({
                    header: `Update interface configuration`,
                    content: 'Interface configuration was successfully updated.',
                    type: "positive",
                })).then(() =>  props.updateNamespacesConfig()).catch(e => null)

    const moveInterfaceToNewNamespace = () =>
        NMService.postInterfaceConfig(props.nodeId, { ...(props.data[newNs.intf] || {}), namespace: newNs.newNS }).then((r) =>
                addGlobalMessage({
                    header: `Updated interface cofiguration`,
                    content: `Interface successfully updated.`,
                    type: "positive",
                })).then(() => {
                    props.updateNamespacesConfig()
                    setNewNs({ intf: undefined, newNS: undefined })
                }).catch(e => null)

    return (
        <>
            <Table celled compact>
                <Table.Header>
                    <Table.Row textAlign="center">
                        <Table.HeaderCell width="3">Name</Table.HeaderCell>
                        <Table.HeaderCell width="3">Alias</Table.HeaderCell>
                        <Table.HeaderCell width="2">Address</Table.HeaderCell>
                        <Table.HeaderCell width="2">Peer</Table.HeaderCell>
                        <Table.HeaderCell width="2">MAC</Table.HeaderCell>
                        <Table.HeaderCell width="2">Enabled</Table.HeaderCell>
                        <Table.HeaderCell width="2">Actions</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {interfaces.length > 0 ?
                        (interfaces.sort((a, b) => a.name.toLowerCase() < b.name.toLowerCase() ? -1 : a.name.toLowerCase() > b.name.toLowerCase() ? 1 : 0)
                            .map((e, index) => (
                                <Table.Row key={`${e.name}_${index}`} textAlign="center">
                                    <Table.Cell>
                                        {e.name}
                                        {e.parent && "@" + e.parent}
                                    </Table.Cell>
                                    <Table.Cell>{e.alias}</Table.Cell>
                                    <Table.Cell>
                                        {e.dhcp ? "DHCP" : e.address.length === 0 ? "-" : e.address.map((a, index) =>
                                            <React.Fragment key={`${a}_${index}`}>
                                                {a}
                                                <br />
                                            </React.Fragment>)}
                                    </Table.Cell>
                                    <Table.Cell>
                                        {e.dhcp ? "DHCP" : e.peer.length === 0 ? "-" : e.peer.map((a, index) => (
                                            <div key={`${a}_${index}`}>{a}<br /></div>
                                        ))}
                                    </Table.Cell>
                                    <Table.Cell>
                                        {e.mac ? e.mac : e.macprefix ? e.macprefix : e.macmask ? e.macmask : "-"}
                                    </Table.Cell>
                                    <Table.Cell>
                                        <PermissionsGate hasPermission={probeAtomState?.permissions?.editbasicdata}>
                                            <Checkbox
                                                toggle
                                                defaultChecked={!e.disabled}
                                                onClick={() => onChangeInterfaceEnable({ ...e, disabled: !e.disabled })}
                                            />
                                        </PermissionsGate>
                                    </Table.Cell>
                                    <Table.Cell style={{ padding: "10px 0 10px 10px" }} >
                                        <PermissionsGate hasPermission={probeAtomState?.permissions?.editbasicdata}>
                                            <Button
                                                style={{ margin: "0 1px" }}
                                                circular
                                                icon="settings"
                                                size="mini"
                                                onClick={() => props.toggleForm("edit", e)}
                                            />
                                        </PermissionsGate>
                                        <Popup
                                            content={
                                                <Grid>
                                                    <Grid.Row style={{ padding: "0.5rem 0" }}>
                                                        <Grid.Column width={4}>
                                                            Gateway:
                                                        </Grid.Column>
                                                        <Grid.Column>
                                                            {e.gateway ? e.gateway : "-"}
                                                        </Grid.Column>
                                                    </Grid.Row>
                                                    <Grid.Row style={{ padding: "0.5rem 0" }}>
                                                        <Grid.Column width={4}>DNS:</Grid.Column>
                                                        {e.dns.length !== 0 ? (
                                                            <Grid.Column>
                                                                {e.dns.map((e, i) => (
                                                                    <React.Fragment key={i}>
                                                                        {e}
                                                                        <br />
                                                                    </React.Fragment>
                                                                ))}
                                                            </Grid.Column>
                                                        ) : (<Grid.Column>-</Grid.Column>)}
                                                    </Grid.Row>
                                                    <Grid.Row style={{ padding: "0.5rem 0" }}>
                                                        <Grid.Column width={4}>DHCP:</Grid.Column>
                                                        <Grid.Column>
                                                            {e.dhcp ? (
                                                                <>
                                                                    VCID:&nbsp;{e["dhcp-vendorclassidentifier"] !== "" ? e["dhcp-vendorclassidentifier"] : "-"}
                                                                    <br />
                                                                    CID:&nbsp;{e["dhcp-clientid"] !== "" ? e["dhcp-clientid"] : "-"}
                                                                </>
                                                            ) : ("✖")}
                                                        </Grid.Column>
                                                    </Grid.Row>
                                                </Grid>
                                            }
                                            flowing
                                            hideOnScroll
                                            position="top right"
                                            on={"click"}
                                            trigger={
                                                <Button
                                                    style={{ margin: "0 1px" }}
                                                    circular
                                                    size="mini"
                                                    icon="info"
                                                />
                                            }
                                        />

                                        {(e.name.includes(".") || e.name.startsWith("macvtap")) && (
                                            <>
                                                <PermissionsGate hasPermission={probeAtomState?.permissions?.editbasicdata}>
                                                    <Button
                                                        style={{ margin: "0 1px" }}
                                                        circular
                                                        icon="trash"
                                                        size="mini"
                                                        onClick={() => props.toggleDeleteModal(true, e)}
                                                    />
                                                </PermissionsGate>

                                                {allNamespaces.length !== 0 && (
                                                    <PermissionsGate hasPermission={probeAtomState?.permissions?.editbasicdata}>
                                                        <Button
                                                            style={{ margin: "0 1px" }}
                                                            circular
                                                            icon="move"
                                                            size="mini"
                                                            onClick={() =>
                                                                setNewNs((p) => ({ ...p, intf: index }))
                                                            }
                                                        />
                                                    </PermissionsGate>
                                                )}
                                            </>
                                        )}
                                        &emsp;
                                    </Table.Cell>
                                </Table.Row>
                            ))
                    ) : (
                        <Table.Row textAlign="center">
                            <Table.Cell colSpan="100%">No data available in table</Table.Cell>
                        </Table.Row>
                    )}
                </Table.Body>

                <Table.Footer>
                    <Table.Row>
                        <Table.HeaderCell colSpan="7" style={{ padding: "0.5rem" }}>
                            <PermissionsGate hasPermission={probeAtomState?.permissions?.editbasicdata}>
                                <Button
                                    primary
                                    size="tiny"
                                    floated="right"
                                    onClick={() => props.toggleForm("add")}
                                    content="Add new interface"
                                />
                            </PermissionsGate>
                        </Table.HeaderCell>
                    </Table.Row>
                </Table.Footer>
            </Table>

            <ConfirmationModal
                open={newNs.intf !== undefined}
                header={`Move interface (${props.data?.[newNs.intf]?.name}) to another namespace`}
                content={
                    <>
                        Choose namespace:&emsp;
                        <Select
                            placeholder="Select namespace..."
                            options={allNamespaces}
                            selectOnBlur={false}
                            onChange={(e, { value }) => setNewNs((p) => ({ ...p, newNS: value }))}
                        />
                    </>}
                onConfirm={() => moveInterfaceToNewNamespace()}
                onDismiss={() => setNewNs({ intf: undefined, newNS: undefined })}
            />
        </>
    );
}

export default NamespaceInterfaceTable;
