import NMService from "@services/nm.service";
import { useQuery } from "@tanstack/react-query";

export const useFetchEventCollectorSources = ({
    select,
    options
}) => {
    const query = useQuery({
        queryKey: ['getECSources'],
        queryFn: () =>
            NMService.getECSources()
                .then(r => r.data)
                .catch(e => []),
        staleTime: Infinity,
        select,
        ...options
    })
    return { ...query, data: query.data ?? [] }
}