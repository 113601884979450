import CustomTable from "@components/CustomTable";
import { useMemo, useState } from "react";
import { Button } from "semantic-ui-react";
import DestinationForm from "./DestinationForm";
import DestinationFilter from "./DestinationsFilter";

const DestinationTable = (props) => {
    const data = useMemo(() => props.data, [props.data]);
    const columns = useMemo(() => props.columns, [props.columns]);
    const [formData, setFormData] = useState(undefined);

    const tableHooks = (hooks) => {
        hooks.visibleColumns.push((columns) => [
            ...columns,
            {
                Header: () => null,
                id: "actions",
                Cell: ({ row }) =>
                    <div style={{ textAlign: 'center' }}>
                        <Button
                            circular
                            icon="settings"
                            onClick={() => setFormData({ type: "edit", data: row.original })}
                        />
                        <Button
                            circular
                            icon="trash"
                            onClick={() => props.delete(row.original.uuid, props.type)}
                        />
                    </div>,
                disableGlobalFilter: true,
                disableFilters: true,
            },
        ]);
    };

    return (
        <>
            <CustomTable
                data={data}
                columns={columns}
                advancedSearch={props.type === "db" ? () => <DestinationFilter filter={props.fetch} columns={props.columns} /> : null}
                tableHooks={tableHooks}
                warning={(e) => (props.errorRows || []).includes(e)}
                active={formData?.data?.uuid}
                footerButton={() =>
                    <Button
                        primary
                        type="button"
                        size="tiny"
                        floated="right"
                        content={props.type === "db" ? "Add new entry" : "Import file to database"}
                        onClick={props.type === "db" ? () => setFormData({ type: "add", data: {} }) : props.import}
                        disabled={props.error}
                    />
                }
            />
            {formData?.type && (
                <DestinationForm
                    data={formData}
                    columns={columns}
                    add={(e) => {
                        props.addEntry(e, props.type);
                        setFormData(undefined);
                    }}
                    close={() => setFormData(undefined)}
                    validation={props.validation}
                />
            )}
        </>
    );
};

export default DestinationTable;
