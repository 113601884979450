
import CustomTable from '@components/CustomTable';
import { useMemo } from 'react';
import { Button, Icon } from 'semantic-ui-react';

const DhcpOptionTable = (props) => {
    const data = useMemo(() => props.data, [props.data]);
    const columns = useMemo(
        () => [
            {
                id: 'name',
                Header: 'Name',
                accessor: 'name',
            },
            {
                id: 'code',
                Header: 'Code',
                accessor: 'code',
                Cell: (d) => <div style={{ textAlign: 'center' }}>{d.value}</div>,
            },
            {
                id: 'enabled',
                Header: 'Enabled',
                accessor: (d) => <Icon color={d.enabled ? "green" : "black"} name={d.enabled ? "checkmark" : "close"} />,
                Cell: (d) => <div style={{ textAlign: 'center' }}>{d.value}</div>,
            }
        ],
        []
    );

    const tableHooks = (hooks) => {
        hooks.visibleColumns.push((columns) => [
            ...columns,
            {
                Header: () => null,
                id: 'actions',
                Cell: ({ row }) =>
                    <div style={{ textAlign: 'center' }}>
                        <Button
                            disabled={row.original.isProtected}
                            circular
                            icon="trash"
                            onClick={() => props.toggle(row.original, false, true)}
                        />
                    </div>,
                disableGlobalFilter: true,
                disableFilters: true,
                width: 1,
            },
        ]);
    };

    return (
        <CustomTable
            data={data}
            columns={columns}
            tableHooks={tableHooks}
            footerButton={props.addNew}
        />
    );
};

export default DhcpOptionTable;
