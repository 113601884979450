import NMService from "@services/nm.service";
import { useQuery } from "@tanstack/react-query";

export const useFetchEventCollectorSource = ({
    uuid,
    select,
    options
}) => {
    const query = useQuery({
        queryKey: ['getECSource', uuid],
        queryFn: () =>
            NMService.getECSource(uuid)
                .then(r => r.data)
                .catch(e => {}),
        staleTime: Infinity,
        select,
        ...options
    })
    return { ...query, data: query.data ?? {} }
}