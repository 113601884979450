import { useFormFields } from "@hooks/useFormFields";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { Form, Header } from "semantic-ui-react";
import { InterfaceValidation } from "../NetworkManagment/networkValidation";

export default function VPNForm(props) {
    const typeOptions = [{ text: "wireguard", value: "wireguard", key: "wireguard" }];
    const {
        register,
        setValue,
        getValues,
        reset,
        watch,
        handleSubmit,
        formState: { errors },
    } = useForm();

    const { renderInput, renderDropdown } = useFormFields({ register, errors, setValue, watch, getValues });

    const onSubmit = () => {
        props.post(getValues());
    };

    useEffect(() => {
        if (props.type === "new") {
            reset({
                name: "",
                peer: "",
                type: "wireguard",
                address: "",
                allowedIps: "",
                peerPublicKey: "",
            });
        } else {
            reset(props.data);
        }
    }, [props.type, props.data, reset]);

    return (
        <>
            <Header
                as="h3"
                dividing
                content={props.type === "new" ? "Add new VPN" : "Edit VPN data"}
                style={{ marginTop: "2rem" }}
            />
            <Form onSubmit={handleSubmit(onSubmit)} style={{ paddingLeft: "1.5rem" }}>
                <Form.Group widths={4}>
                    {renderInput("Name", "name")}
                    {renderInput("Peer", "peer", { validate: InterfaceValidation.validateIPwPeer })}
                    {renderDropdown("Type", "type", typeOptions, { disabled: true })}
                </Form.Group>
                <Form.Group widths={4}>
                    {renderInput("Address", "address", { validate: InterfaceValidation.validateIPAddr })}
                    {renderInput("Allowed IPs", "allowedIps", { validate: InterfaceValidation.validateIPAddr })}
                    {renderInput("Peer public key", "peerPublicKey")}
                </Form.Group>

                <Form.Group style={{ paddingTop: "2rem" }}>
                    <Form.Button type="submit" content={props.type === "edit" ? "Update" : "Save"} primary />
                    <Form.Button type="button" content="Close" onClick={props.close} />
                </Form.Group>
            </Form>
        </>
    );
}
