import NMService from "@services/nm.service";
import { addGlobalMessageAtom } from "@store/globalMessage";
import { useSetAtom } from "jotai";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Breadcrumb, Divider, Label, Popup } from "semantic-ui-react";
import ObjectDataTable from "./ObjectDataTable";

const ObjectData = () => {
    const addGlobalMessage = useSetAtom(addGlobalMessageAtom);
    const [dbContent, setDbContent] = useState([]);

    const obj2TextHelper = (obj) =>
        Object.entries(obj)
            .map((elem, i) => elem[0] + ": " + elem[1])
            .join("\n");

    const obj2Text = useCallback((obj, type) => {
        try {
            return obj
                .map((elem) => (typeof elem === "object" ? obj2TextHelper(elem) : elem))
                .join("\n");
        } catch {
            if (type === "publickeys") {
                return Object.entries(obj).map((elem, i) => (
                    <React.Fragment key={i}>
                        {elem[0] + ": "}
                        <Popup
                            style={{ maxWidth: "900px", wordBreak: "break-all" }}
                            trigger={<Label as="a" content="..." size="mini" />}
                            position="bottom right"
                            on="click"
                            content={elem[1]}
                        />
                        <br />
                    </React.Fragment>
                ));
            } else {
                return obj2TextHelper(obj);
            }
        }
    }, []);

    const columns = useMemo(
        () => [
            {
                id: "name",
                Header: "Name",
                accessor: "name",
            },
            {
                id: "objectgroup",
                Header: "Object group",
                accessor: "objectgroup",
            },
            {
                id: "objecttype",
                Header: "Object type",
                accessor: "objecttype",
            },
            {
                id: "properties",
                Header: "Properties",
                accessor: (e) => obj2Text(e.properties),
                disableSortBy: true,
            },
            {
                id: "tags",
                Header: "Tags",
                accessor: (e) => e.tags ? e.tags.join(", ") : "",
            },
            {
                id: "value",
                Header: "Value",
                accessor: (e) =>
                    e.objecttype === "string" || e.objecttype === "int"
                        ? e.value
                        : obj2Text(JSON.parse(e.value), e.objectgroup),
            },
        ],
        [obj2Text]
    );

    const fetchObjectDataTable = (filter = "") =>
        NMService
            .getObjectServiceObjectTable(filter)
            .then((response) => setDbContent(response.data))
            .catch(e => null);

    const addEntry = (entry) =>
        NMService
            .postObjectServiceObjectEntry(entry)
            .then((r) => 
                    addGlobalMessage({
                        header: entry.uuid
                            ? "Successfully updated entry"
                            : "Successfully added new entry",
                        content: entry.uuid
                            ? "Successfully updated entry"
                            : "Successfully added new entry",
                        type: "positive",
                    })).catch(e => null)
            .finally((r) => fetchObjectDataTable());

    const deleteEntry = (uuid) =>
        NMService
            .deleteObjectServiceObjectEntry(uuid)
            .then((r) => 
                    addGlobalMessage({
                        header: "Successfully deleted entry",
                        content: "Successfully deleted entry",
                        type: "positive",
                    })).catch(e => null)
            .finally((r) => fetchObjectDataTable());

    useEffect(() => {
        fetchObjectDataTable();
    }, []);

    return (
        <>
            <Breadcrumb style={{ marginTop: "1rem" }}>
                <Breadcrumb.Section>Object service</Breadcrumb.Section>
                <Breadcrumb.Divider>/</Breadcrumb.Divider>
                <Breadcrumb.Section active>Object data</Breadcrumb.Section>
            </Breadcrumb>
            <Divider />
            <ObjectDataTable
                data={dbContent}
                columns={columns}
                delete={deleteEntry}
                addEntry={addEntry}
                fetch={fetchObjectDataTable}
            />
        </>
    );
};

export default ObjectData;
