import CustomTable from "@components/CustomTable";
import NMService from "@services/nm.service";
import { addGlobalMessageAtom } from "@store/globalMessage";
import { useSetAtom } from "jotai";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Breadcrumb, Button, Divider } from "semantic-ui-react";
import MLAlarmsForm from "./MLAlarmForm";

const MLAlarms = () => {
    const addGlobalMessage = useSetAtom(addGlobalMessageAtom);
    const [data, setData] = useState([]);
    const [initialData, setInitialData] = useState([]);
    const [showForm, setShowForm] = useState(false);

    const columns = useMemo(
        () => [
            {
                id: "status",
                Header: "Status (error)",
                accessor: (d) => `${d.status} (${d.errordesc})`,
            },
            {
                id: "modulename",
                Header: "Module name",
                accessor: d => d.config?.module,
            },
            {
                id: "hwidinstanceid",
                Header: "HWID / Instance ID",
                accessor: (d) => d.config?.hwid + " / " + d.config?.instanceid,
            },
            {
                id: "datafilters",
                Header: "Data filters",
                accessor: (d) => Object.entries(d.config?.datafilters || {}).map((e) => e[0] + ": " + e[1] + "\n"),
            },
            {
                id: "algorithm",
                Header: "Algorithm",
                accessor: d => d.config?.algorithm?.name,
            },
        ], []);

    const activateModel = (modelID) =>
        NMService.MLActivateModel(modelID).then((response) => {
                if (response.data.hasOwnProperty("error")) {
                    addGlobalMessage({
                        header: "Error occurred",
                        content: response.data.error.message || "",
                        type: "warning",
                    })
                } else if (response.data.rv === "OK") {
                    addGlobalMessage({
                        header: "Successfully activated ML model",
                        content: "Successfully activated ML model",
                        type: "positive",
                    })
                }
            })
            .catch(e => null)
            .finally(() => fetchAll())

    const deactivateModel = (modelID) =>
        NMService.MLDeactivateModel(modelID).then((response) => {
                if (response.data.hasOwnProperty("error")) {
                    addGlobalMessage({
                        header: "Error occurred",
                        content: response.data.error.message || "",
                        type: "warning",
                    })
                } else if (response.data.rv === "OK") {
                    addGlobalMessage({
                        header: "Successfully deactivated ML model",
                        content: "Successfully deactivated ML model",
                        type: "positive",
                    })
                }
            })
            .catch(e => null)
            .finally(() => fetchAll())

    const removeModel = (modelID) =>
        NMService.MLRemoveModel(modelID).then((response) => {
                if (response.data.hasOwnProperty("error")) {
                    addGlobalMessage({
                        header: "Error occurred",
                        content: response.data.error.message || "",
                        type: "warning",
                    })
                } else if (response.data.rv === "OK") {
                    addGlobalMessage({
                        header: "Successfully removed ML model",
                        content: "Successfully removed ML model",
                        type: "positive",
                    })
                }
            })
            .catch(e => null)
            .finally(() => fetchAll())

    const fetchAll = useCallback(() =>
        NMService.getMLAllConfigs().then((response) => {
            if (response.data.hasOwnProperty("error")) {
                addGlobalMessage({
                    header: "Error while fetching models",
                    content: response.data.error.message || "",
                    type: "negative",
                })
            } else {
                setInitialData(response.data.rv || []);
            }
        }).catch(e => null), []); //eslint-disable-line

    const postConfig = (data) =>
        NMService.MLlearn(data).then((response) => {
                if (response.data.hasOwnProperty("error")) {
                    addGlobalMessage({
                        header: "Error while adding new ML model",
                        content: response.data.error.message || "",
                        type: "warning",
                    })
                } else if (response.data.rv === "OK") {
                    addGlobalMessage({
                        header: "Successfully added new ML model!",
                        content: "Successfully added new ML model!",
                        type: "positive",
                    })
                }
            })
            .catch(e => null)
            .finally(() => {
                fetchAll();
                setShowForm(false);
            })

    useEffect(() => {
        setData(initialData);
    }, [initialData]);

    const tableHooks = (hooks) => {
        hooks.visibleColumns.push((columns) => [
            ...columns,
            {
                Header: () => null,
                id: "actions",
                Cell: ({ row }) =>
                    <div style={{ textAlign: 'center' }}>
                        <Button
                            disabled={row.original.status !== "learned"}
                            circular
                            icon="check"
                            size="small"
                            title='Activate model'
                            onClick={() => activateModel(row.original.id)}
                        />
                        <Button
                            disabled={row.original.status !== "active"}
                            circular
                            icon="close"
                            size="small"
                            title='Deactivate model'
                            onClick={() => deactivateModel(row.original.id)}
                        />
                        <Button
                            disabled={row.original.status === "learning"}
                            circular
                            icon="trash"
                            size="small"
                            title='Remove model'
                            onClick={() => removeModel(row.original.id)}
                        />
                    </div>,
                width: 2,
                disableGlobalFilter: true,
                disableFilters: true,
            },
        ]);
    };

    

    useEffect(() => {
        fetchAll();
    }, [fetchAll]);

    return (
        <>
            <Breadcrumb style={{ marginTop: '1rem' }}>
                <Breadcrumb.Section>Alarms</Breadcrumb.Section>
                <Breadcrumb.Divider>/</Breadcrumb.Divider>
                <Breadcrumb.Section active>ML alarming</Breadcrumb.Section>
            </Breadcrumb>
            <Divider clearing />
            <CustomTable
                columns={columns}
                data={data}
                tableHooks={tableHooks}
                fixed={true}
                footerButton={() => 
                    <Button
                        primary={!showForm}
                        floated="right"
                        type="button"
                        size="tiny"
                        content={showForm ? "Close form" : "Add new model"}
                        onClick={() => setShowForm((p) => !p)}
                    />
                }
            />
            {showForm && <MLAlarmsForm submit={postConfig} />}
        </>
    );
};

export default MLAlarms;
