import { strftime } from '@utils/strftime';
import { useRef } from 'react';
import { TimestampThresholds } from '../config';

function DateFormat(props) {
    let displaytimestamp = useRef();
    let color = useRef();

    if (props.timestamp != null) {
        let timestamp = new Date(Date.parse(props.timestamp));
        displaytimestamp.current = strftime('%Y-%m-%d %H:%M:%S', timestamp);
        if (props.threshold != null) {
            let currentDate = new Date();

            const colorArr = [];
            for (let i = 0; i < TimestampThresholds.length; i++) {
                if (TimestampThresholds[i].seconds < currentDate - timestamp) {
                    colorArr.push(TimestampThresholds[i].color);
                    break;
                }
            }
            color.current = colorArr;
        }
    }

    if (color == null) {
        return <span>{displaytimestamp.current}</span>;
    } else {
        return <span style={{ color: color.current }}>{displaytimestamp.current}</span>;
    }
}

export default DateFormat;