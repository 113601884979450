import NMService from "@services/nm.service";
import { useCallback, useEffect } from "react";
import { useForm } from "react-hook-form";
import { Divider, Dropdown, Form, Grid, Header, Icon, Label } from "semantic-ui-react";

const MLAlarmForm = (props) => {
    const {
        handleSubmit,
        register,
        setValue,
        getValues,
        reset,
        watch,
        formState: { errors },
    } = useForm();

    const fetchAlgorithms = useCallback(() => {
        NMService.getMLalgorithms().then((response) => {
            let algs = [];
            for (const alg of response.data.rv || []) {
                algs.push({
                    key: alg.id,
                    value: alg.name,
                    text: alg.name,
                    description: alg.description,
                });
            }
            setValue("algorithms", algs);
        }).catch(e => null)
    }, [setValue]);

    const fetchAllNodeModules = useCallback(() => {
        NMService.getAllNodeModules().then((response) => {
            let modules = [];
            for (const e of response.data) {
                modules.push({
                    instanceid: e.instanceid,
                    moduleid: e.moduleid,
                    version: e.version,
                });
            }
            setValue(
                "modules",
                modules.map((e) => ({
                    key: Object.values(e).join("|"),
                    value: Object.values(e).join("|"),
                    text: Object.values(e).join("/"),
                }))
            );
        }).catch(e => null)
    }, [setValue]);

    const fetchNodesHwid = useCallback(() => {
        NMService.getNodes().then((response) => {
            let hwids = [];
            for (const e of response.data) {
                hwids.push({
                    key: e.hwid,
                    value: e.id + "|" + e.hwid,
                    text: `${e.id} - ${e.name} (${e.hwid})`,
                });
            }
            setValue("hwids", hwids);
        }).catch(e => null)
    }, [setValue]);

    const fetchNodeModulesFromID = (nodeid) => {
        NMService.getNodeModules(nodeid).then((response) => {
            let modules = [];
            for (const e of response.data) {
                const key = e.instanceid + "|" + e.moduleid + "|" + e.version;
                modules.push({
                    key: key,
                    value: key,
                    text: `${e.instanceid} (${e.moduleid} ${e.version})`,
                });
            }
            setValue("nodemodules", modules);
        }).catch(e => null)
    };

    const fetchSWModuleDetails = useCallback(
        (moduleid, version) => {
            NMService.getSWModuleDetails(moduleid, version).then((response) => {
                const resultData = JSON.parse(response.data.resultmodel);
                let options = [];
                Object.entries(resultData).forEach((e) =>
                    e[1].inalarm
                        ? options.push({
                              key: e[0],
                              value: e[0],
                              text: e[0],
                              description: e[1].description,
                              unit: e[1].unit,
                          })
                        : null
                );
                setValue("datatoreadoptions", options);
                setValue(
                    "datafiltersoptions",
                    options.filter((e) => resultData[e.key].mlfilter)
                );
            }).catch(e => null)
        },
        [setValue]
    );

    const validateContent = (id, value) => {
        if (!value || value?.length === 0) {
            return "Please enter value";
        }
        return undefined;
    };

    const dropdownRow = (id, Header, condition, options, multiple = false) =>
        condition && (
            <Grid.Row verticalAlign="middle" style={{ padding: "0.5rem 0 0 0" }}>
                <Grid.Column width={2}>
                    <label align="right">{Header}</label>
                </Grid.Column>
                <Grid.Column width={7}>
                    <Form.Field error={Boolean(errors[id])}>
                        <Dropdown
                            {...register(id, {
                                validate: (value) => validateContent(id, value),
                            })}
                            onChange={(e, { value }) => {
                                setValue(id, value);
                                if (id === "hwid") {
                                    reset((prev) => ({
                                        ...prev,
                                        module: "",
                                        algorithm: "",
                                        datatoread: "",
                                        datafilters: [],
                                    }));
                                } else if (id === "module") {
                                    reset((prev) => ({
                                        ...prev,
                                        algorithm: "",
                                        datatoread: "",
                                        datafilters: [],
                                    }));
                                }
                                try {
                                    let v = value.split("|");
                                    if (id === "hwid") {
                                        fetchNodeModulesFromID(v[0]);
                                    } else if (id === "module") {
                                        fetchSWModuleDetails(v[1], v[2]);
                                    }
                                } catch {
                                    return;
                                }
                            }}
                            placeholder="Select from dropdown..."
                            selection
                            multiple={multiple}
                            selectOnBlur={false}
                            value={watch(id)}
                            options={options || []}
                        />
                    </Form.Field>
                </Grid.Column>
            </Grid.Row>
        );

    const onSubmit = () => {
        const v = getValues("module").split("|");
        const config = {
            hwid: getValues("hwid").split("|")[1],
            instanceid: v[0],
            moduleversion: v[2],
            module: v[1],
            datatoread: [getValues("datatoread")],
            datafilters: Object.fromEntries(
                getValues("datafilters").map((e) => [e, getValues("datafilters" + e)])
            ),
            algorithm: {
                name: getValues("algorithm"),
                learnconfig: "",
            },
        };
        props.submit(config);
    };

    useEffect(() => {
        reset((prev) => ({
            ...prev,
            hwid: "",
            module: "",
            algorithm: "",
            datatoread: "",
            datafilters: [],
        }));
        fetchNodesHwid();
        fetchAllNodeModules();
        fetchAlgorithms();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fetchAlgorithms, fetchNodesHwid, fetchAllNodeModules]);

    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <Header
                as="h4"
                style={{ marginTop: "1.5rem", marginBottom: "0" }}
                content="Add new model"
            />
            <Divider />
            <Grid>
                {dropdownRow("hwid", "Node", true, watch("hwids"))}
                {dropdownRow("module", "Module", watch("hwid"), watch("nodemodules"))}
                {dropdownRow("algorithm", "Algorithm", watch("module"), watch("algorithms"))}
                {dropdownRow(
                    "datatoread",
                    "Data to read",
                    watch("module"),
                    watch("datatoreadoptions")
                )}
                {dropdownRow(
                    "datafilters",
                    "Data filters",
                    watch("module"),
                    watch("datafiltersoptions"),
                    true
                )}
                {watch("datafilters")?.map((e, i) => (
                    <Grid.Row key={i} verticalAlign="middle" style={{ padding: "0.5rem 0 0 0" }}>
                        <Grid.Column width={2} />
                        <Grid.Column width={1}>
                            <Icon
                                name="trash alternate"
                                link
                                color="red"
                                onClick={() =>
                                    setValue(
                                        "datafilters",
                                        getValues("datafilters").filter((elem) => elem !== e)
                                    )
                                }
                            />
                        </Grid.Column>
                        <Grid.Column width={2}>{e}</Grid.Column>
                        <Grid.Column width={4}>
                            <Form.Field error={Boolean(errors["datafilters" + e])}>
                                <input
                                    {...register("datafilters" + e, {
                                        validate: (value) => validateContent(e, value),
                                    })}
                                    onChange={(elem) =>
                                        setValue("datafilters" + e, elem.target.value)
                                    }
                                    placeholder={e}
                                    type="text"
                                />
                            </Form.Field>
                        </Grid.Column>
                        <Grid.Column width={5}>
                            {errors["datafilters" + e] &&
                                errors["datafilters" + e].type === "validate" && (
                                    <Label
                                        basic
                                        color="red"
                                        pointing="left"
                                        content={errors["datafilters" + e].message}
                                    />
                                )}
                        </Grid.Column>
                    </Grid.Row>
                ))}
                <Grid.Row>
                    <Grid.Column width={2} />
                    <Grid.Column width={2}>
                        <Form.Button primary type="submit" content="Submit" />
                    </Grid.Column>
                    <Grid.Column width={2}>
                        <Form.Button
                            type="reset"
                            content="Reset"
                            onClick={() =>
                                reset((prev) => ({
                                    ...prev,
                                    hwid: "",
                                    module: "",
                                    algorithm: "",
                                    datatoread: "",
                                    datafilters: [],
                                }))
                            }
                        />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Form>
    );
};

export default MLAlarmForm;
