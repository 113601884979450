import CustomHeader from '@components/CustomHeader';
import RenderObject from '@components/renderObject/RenderObject';
import { PermissionsGate } from '@layouts/PermissionGate';
import NMService from '@services/nm.service';
import { addGlobalMessageAtom } from '@store/globalMessage';
import { swModuleAtom } from '@store/swModule';
import { adjustConfig } from '@utils/configurator/adjustConfig';
import { adjustModel } from '@utils/configurator/adjustModel';
import { prepareConfig } from '@utils/configurator/prepareConfig';
import { useAtomValue, useSetAtom } from 'jotai';
import { useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Button, Form, Grid } from 'semantic-ui-react';


const Configurator = (props) => {
    const addGlobalMessage = useSetAtom(addGlobalMessageAtom);
    const { hasPermission } = useAtomValue(swModuleAtom);
    const [showForm, setShowForm] = useState(false);
    const [model, setModel] = useState({});
    const moduleid = useMemo(() => props.id, [props.id]);
    const version = useMemo(() => props.version, [props.version]);
    const methods = useForm({ shouldUnregister: true });

    useEffect(() => {
        if (props.model) {
            const updatedModel = adjustModel(props.model);
            setModel(updatedModel);
        }
    }, [props.model]);

    useEffect(() => {
        let config = {};
        try {
            config = JSON.parse(props.config);
        } catch {
            config = {};
        }

        const initconfig = adjustConfig(config);
        methods.reset(initconfig);
    }, [props.config, methods]);

    const onSubmit = async (values) => {
        const config = prepareConfig(values);
        const data = {
            id: moduleid,
            version: version,
            commonconfig: JSON.stringify(config),
        };
        await NMService.updateSWModuleCommonConfig(data).then((response) => {
            addGlobalMessage({
                header: 'Common configuration was updated!',
                content: `Common configuration was successfully updated.`,
                type: 'positive',
            })
        }).catch(e => null)
    };

    return (
        <>
            <CustomHeader
                title='Common configuration'
                toggle={() => setShowForm(p => !p)}
                show={showForm}
            />
            {showForm && Object.keys(model).length > 0 && (
                <FormProvider {...methods}>
                    <Form style={{ padding: '0' }} onSubmit={methods.handleSubmit(onSubmit)}>
                        <Grid className={'moduleGrid'} padded>
                            <Grid.Row>
                                <Grid.Column width={3}>
                                    <label style={{ paddingRight: '1rem', fontWeight: 600 }}>Module:</label>
                                    {moduleid}
                                </Grid.Column>
                                <Grid.Column width={2}>
                                    <label style={{ paddingRight: '1rem', fontWeight: 600 }}>Version:</label>
                                    {version}
                                </Grid.Column>
                                <Grid.Column width={7} />
                            </Grid.Row>

                            <Grid.Row>
                                <Grid.Column>
                                    <RenderObject
                                        {...{
                                            inline: false,
                                            parentName: undefined,
                                            model,
                                            schema: model,
                                            hasPermision: hasPermission
                                        }}
                                    />
                                </Grid.Column>
                            </Grid.Row>

                            <Grid.Row style={{ padding: '0.5rem 0' }} verticalAlign="middle">
                                <Grid.Column width={6}>
                                    <Form.Field align="left">
                                        <PermissionsGate hasPermission={hasPermission}>
                                            <Button type="submit" size="small" primary>
                                                Update
                                            </Button>
                                        </PermissionsGate>
                                    </Form.Field>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Form>
                </FormProvider>
            )}
        </>
    );
};

export default Configurator;