import DateFormat from '@components/DateFormat';
import { locationsActions, locationsAtom } from '@store/locations';
import { useAtom } from 'jotai';
import { Fragment } from 'react';
import { Grid, Header, Icon, Table, TableHeader } from 'semantic-ui-react';

const LocationInfo = () => {
    const [state, dispatch] = useAtom(locationsAtom);
    const c = { padding: "3px" }
    const h = { ...c, backgroundColor: "#f9fafb", fontWeight: "700" }

    return (
        <Fragment>
            <Header as="h4" dividing>
                Location info
                <Icon
                    name="close"
                    link
                    style={{ float: "right" }}
                    onClick={() => dispatch({ type: locationsActions.CLEAR_TYPE })}
                />
            </Header>
            <Table celled structured size="small" compact>
                <TableHeader>
                    <Table.Row textAlign="center">
                        <Table.HeaderCell style={c} width={1}>ID</Table.HeaderCell>
                        <Table.HeaderCell style={c} width={3}>Name</Table.HeaderCell>
                        <Table.HeaderCell style={c} width={3}>Description</Table.HeaderCell>
                        <Table.HeaderCell style={c} width={3}>Coordinates</Table.HeaderCell>
                        <Table.HeaderCell style={c} width={3}>Created</Table.HeaderCell>
                    </Table.Row>
                </TableHeader>
                <Table.Body>
                    <Table.Row textAlign="center">
                        <Table.Cell style={c}>{state.selectedLocation.id}</Table.Cell>
                        <Table.Cell style={c}>{state.selectedLocation.name}</Table.Cell>
                        <Table.Cell style={c}>{state.selectedLocation.description}</Table.Cell>
                        <Table.Cell style={c}>{state.selectedLocation.latitude}/{state.selectedLocation.longitude}</Table.Cell>
                        <Table.Cell style={c}>{<DateFormat timestamp={state.selectedLocation.created}/>}</Table.Cell>
                    </Table.Row>
                </Table.Body>
            </Table>
            {Object.keys(JSON.parse(state.selectedLocation.data.rawjsondata)).length !== 0 &&
                <Grid>
                    <Grid.Row>
                        <Grid.Column textAlign='center' width={3}>Location data</Grid.Column>
                        <Grid.Column textAlign='center' width={8}>
                            <Table celled structured compact size="small" attached="top">
                                <Table.Body>
                                    {Object.entries(JSON.parse(state.selectedLocation.data.rawjsondata)).map((e, i) => 
                                        <Table.Row key={i} textAlign="center">
                                            <Table.Cell style={h} width={4}>{e[0]}</Table.Cell>
                                            <Table.Cell style={c}>{e[1]}</Table.Cell>
                                        </Table.Row>
                                        )}
                                </Table.Body>
                            </Table>
                        </Grid.Column>
                        <Grid.Column width={5}/>
                    </Grid.Row>
                </Grid>
            }
        </Fragment>
    );
}

export default LocationInfo;
