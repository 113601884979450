import AlarmForm from '@components/AlarmForm';
import NMService from '@services/nm.service';
import { addGlobalMessageAtom } from '@store/globalMessage';
import { useQuery } from '@tanstack/react-query';
import { parseObject, splitRule } from '@utils/alarmUtils';
import { useSetAtom } from 'jotai';
import { useCallback, useState } from 'react';
import { Breadcrumb, Divider } from 'semantic-ui-react';
import AlarmsTable from './AlarmsTable';


const Alarms = () => {
    const addGlobalMessage = useSetAtom(addGlobalMessageAtom);
    const [state, setState] = useState({
        formType: undefined,
        selectedAlarmId: undefined,
    });

    const {
        data: alarmList = [],
        isLoading: alarmListIsLoading,
        refetch: alarmListRefetch
    } = useQuery({
        queryKey: ['getAllAlarmRules'],
        queryFn: () =>
            NMService.getAllAlarmRules()
                .then(r => r.data)
                .catch(e => []),
        select: (data) => data ?? [],
    });

    const {
        data: moduleList = [],
        isLoading: moduleListIsLoading,
    } = useQuery({
        queryKey: ['inventory', 'modules'],
        queryFn: () =>
            NMService.getModuleList()
                .then(r => r.data)
                .catch(e => []),
        select: (data) => {
            return (
                data?.map((module) => {
                    const { id, version, resultmodel } = module;
                    return {
                        id,
                        version,
                        resultmodel: [].concat(parseObject(JSON.parse(resultmodel), '', 0, null, '$')),
                    };
                }) ?? []
            );
        },
    });

    const toggleForm = useCallback((alarmId = undefined, formType = undefined) => {
        setState((prevState) => ({
            ...prevState,
            formType: formType,
            selectedAlarmId: alarmId,
        }));
    }, []);

    const submitForm = (data) => {
        state.selectedAlarmId = undefined;
        NMService.addAlarmRule(data).then(async () => {
            await alarmListRefetch();
            toggleForm();
            if (state.formType === 'edit') {
                addGlobalMessage({
                    header: 'Alarm configuration was updated!',
                    content: 'Alarm configuration was successfully updated.',
                    type: 'positive',
                })
            } else {
                addGlobalMessage({
                    header: 'Alarm configuration was created!',
                    content: 'Alarm configuration was successfully created.',
                    type: 'positive',
                })
            }
        }).catch(e => []);
    };

    const formattedDefaultValues = (formType) => {
        switch (formType) {
            case 'edit': {
                const selectedAlarm = (alarmList || [])?.find((e) => e.id === state.selectedAlarmId);
                if (!selectedAlarm) { break }

                const { rule: ruleStr, ...rest } = selectedAlarm;
                const initconfig = {
                    ...rest,
                    module: rest.module + '-' + rest.version,
                    rule: splitRule(ruleStr),
                };
                delete initconfig.version;

                try {
                    initconfig.rule = initconfig.rule.map(({ value, ...rest }) => ({
                        value: value.replace(/"/g, ''),
                        ...rest,
                    }));
                } catch {
                    initconfig.rule = [];
                }
                return initconfig;
            }
            default:
                break;
        }

        return {
            scope: 'none',
            rule: [],
            severity: 4,
            correlationtimebefore: 300,
            correlationtimeafter: 300,
        };
    };

    return (alarmListIsLoading || moduleListIsLoading) ? null : (
        <>
            <Breadcrumb>
                <Breadcrumb.Section>Alarms</Breadcrumb.Section>
                <Breadcrumb.Divider>/</Breadcrumb.Divider>
                <Breadcrumb.Section active>Node alarms</Breadcrumb.Section>
            </Breadcrumb>
            <Divider clearing />
            <AlarmsTable
                data={alarmList}
                resultModels={moduleList}
                toggleForm={toggleForm}
                selectedAlarmId={state.selectedAlarmId}
                refetch={alarmListRefetch}
            />

            {state.formType && (
                <AlarmForm
                    key={`::alarm-form-${state.selectedAlarmId}`}
                    type={state.formType}
                    defaultValues={formattedDefaultValues(state.formType)}
                    toggleForm={toggleForm}
                    submitForm={submitForm}
                />
            )}
        </>
    );
};

export default Alarms;