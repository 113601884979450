import { PermissionsGate } from '@layouts/PermissionGate';
import { formatRuleDefinition } from '@utils/alarmUtils';
import { useMemo } from 'react';
import { Button, Table } from 'semantic-ui-react';

function ModuleAlarmTable(props) {
    const data = useMemo(() => props.data, [props.data]);
    const resultModels = useMemo(() => props.resultModels, [props.resultModels]);
    const hasPermission = props.hasPermission;

    return (
        <Table celled compact size='small'>
            <Table.Header>
                <Table.Row textAlign="center">
                    <Table.HeaderCell width="2">Scope</Table.HeaderCell>
                    <Table.HeaderCell width="4">Definition</Table.HeaderCell>
                    <Table.HeaderCell width="4">Custom message</Table.HeaderCell>
                    <Table.HeaderCell width="1">Interval</Table.HeaderCell>
                    <Table.HeaderCell width="2">Module</Table.HeaderCell>
                    <Table.HeaderCell width="2">Severity</Table.HeaderCell>
                    <Table.HeaderCell width="1"></Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {data.length > 0 ? (
                    data.map((alarm, index) => (
                        <Table.Row key={`${alarm.id}_${index}`} textAlign="center">
                            <Table.Cell>{alarm.scope}</Table.Cell>
                            <Table.Cell>{formatRuleDefinition(alarm, resultModels)}</Table.Cell>
                            <Table.Cell>{alarm.message}</Table.Cell>
                            <Table.Cell>{alarm.interval}</Table.Cell>
                            <Table.Cell>{alarm.module}</Table.Cell>
                            <Table.Cell>{alarm.severity}</Table.Cell>
                            <Table.Cell textAlign="left">
                                &emsp;
                                <PermissionsGate hasPermission={hasPermission}>
                                    <Button
                                        circular
                                        icon="settings"
                                        size="tiny"
                                        onClick={() => props.toggleForm(alarm.id, 'ext-edit')}
                                    />
                                </PermissionsGate>
                            </Table.Cell>
                        </Table.Row>
                    ))
                ) : (
                    <Table.Row textAlign="center">
                        <Table.Cell colSpan="100%">No data available in table</Table.Cell>
                    </Table.Row>
                )}
            </Table.Body>

            <Table.Footer>
                <Table.Row>
                    <Table.HeaderCell colSpan="7" style={{ padding: '0.5rem' }}>
                        <PermissionsGate hasPermission={hasPermission}>
                            <Button primary size="tiny" floated="right" onClick={() => props.toggleForm(undefined, 'ext-add')}>
                                Add new alarm
                            </Button>
                        </PermissionsGate>
                    </Table.HeaderCell>
                </Table.Row>
            </Table.Footer>
        </Table>
    );
}

export default ModuleAlarmTable;
