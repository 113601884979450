import { yupResolver } from '@hookform/resolvers/yup';
import NMService from '@services/nm.service';
import { addGlobalMessageAtom } from '@store/globalMessage';
import { localUserActions, localUsersAtom } from '@store/localUser';
import { useAtom, useSetAtom } from 'jotai';
import { Fragment } from 'react';
import { useForm } from 'react-hook-form';
import { Button, Divider, Form, Grid, Header, Input, Label } from 'semantic-ui-react';
import * as yup from 'yup';

const formSchema = yup.object().shape({
    password: yup.string()
        .required("Password is required")
        .min(8, "Password length should be at least 8 characters")
        .max(64, "Password cannot exceed more than 64 characters"),
    confirmPwd: yup.string()
        .required("Confirm Password is required")
        .min(8, "Password length should be at least 8 characters")
        .max(64, "Password cannot exceed more than 64 characters")
        .oneOf([yup.ref("password")], "Passwords do not match")
});


const ChangePassword = () => {
    const formOptions = { resolver: yupResolver(formSchema) }
    const { register, setValue, handleSubmit, formState: { errors } } = useForm(formOptions);

    const addGlobalMessage = useSetAtom(addGlobalMessageAtom);
    const [state, dispath] = useAtom(localUsersAtom);

    const onSubmit = async (values) => {
        const { password } = values;

        await NMService.changePasswordForUser({
            username: state.selectedUser,
            password: password,
        }).then(
            () => {
                dispath({ type: localUserActions.RESET });
                addGlobalMessage({
                    header: 'User password is updated!',
                    content: `User password is successfully updated.`,
                    type: 'positive',
                })
            }
        ).catch(e => null)
    }

    return (
        <Fragment>
            <Header as="h4" style={{ marginTop: '2rem', marginBottom: '0' }}>
                Change password
            </Header>
            <Divider clearing style={{ marginTop: '0.5rem', marginBottom: '1rem' }} />

            <Form className="basic segment" onSubmit={handleSubmit(onSubmit)}>
                <Grid>
                    <Grid.Row verticalAlign="middle">
                        <Grid.Column width={2}>Password</Grid.Column>
                        <Grid.Column width={6}>
                            <Form.Field align="right">
                                <Input
                                    {...register('password')}
                                    placeholder="Password"
                                    type="password"
                                    onChange={(_, { name, value }) => {
                                        setValue(name, value);
                                    }}
                                />
                            </Form.Field>
                        </Grid.Column>
                        <Grid.Column width={4}>
                            {errors.password && (
                                <Label basic color="red" pointing="left">
                                    {errors.password?.message}
                                </Label>
                            )}
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row verticalAlign="middle">
                        <Grid.Column width={2}>Password (again)</Grid.Column>
                        <Grid.Column width={6}>
                            <Form.Field align="right">
                                <Input
                                    {...register('confirmPwd')}
                                    placeholder="Password"
                                    type="password"
                                    onChange={(_, { name, value }) => {
                                        setValue(name, value);
                                    }}
                                />
                            </Form.Field>
                        </Grid.Column>
                        <Grid.Column width={4}>
                            {errors.confirmPwd && (
                                <Label basic color="red" pointing="left">
                                    {errors.confirmPwd?.message}
                                </Label>
                            )}
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width={2}></Grid.Column>
                        <Grid.Column width={6}>
                            <Form.Field align='left'>
                                <Button
                                    primary
                                >
                                    Save
                                </Button>
                                <Button
                                    type='button'
                                    onClick={() => dispath({ type: localUserActions.RESET })}
                                >
                                    Cancel
                                </Button>
                            </Form.Field>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Form>
        </Fragment>
    );
}

export default ChangePassword;
