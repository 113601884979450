import { useFormFields } from "@hooks/useFormFields";
import { useCallback, useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { Divider, Form, Grid, Icon } from "semantic-ui-react";

const ObjectDataFilter = (props) => {
    const columns = useMemo(
        () => [
            { id: "name", Header: "Name" },
            { id: "objectgroup", Header: "Object group" },
            { id: "objecttype", Header: "Object type" },
            { id: "tag", Header: "Tag" },
        ], []);
    const { handleSubmit, register, setValue, getValues, reset, watch, formState: { errors } } = useForm();
    const { renderInput } = useFormFields({ register, errors, setValue, watch });

    const onSubmit = (values) => {
        let filterStr = "";
        let ch = "?";
        for (const val in values) {
            if (val !== "properties") {
                if (values[val]) {
                    filterStr += ch + val + "=" + values[val];
                    ch = "&";
                }
            }
        }
        if (values["properties"]) {
            if (values["properties"]?.length !== 0) {
                for (let obj of values["properties"]) {
                    if (obj.key.trim() !== "" && obj.value.trim() !== "") {
                        filterStr += ch + "properties_" + obj.key + "=" + obj.value;
                        ch = "&";
                    }
                }
                let props = values["properties"].filter((e) => e.key.trim() !== "" && e.value.trim() !== "")
                setValue("properties", props.length === 0 ? [{ key: "", value: "" }] : props);
            }
        }
        props.filter(filterStr);
    };

    const init = useCallback(() => {
        reset({ ...columns.map((e) => e.id).reduce((a, v) => ({ ...a, [v]: "" }), {}), properties: [{ key: "", value: "" }]});
    }, [reset, columns]);

    useEffect(() => {
        init();
    }, [init]);

    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <Divider />
            <Grid>
                {columns.map(({ id, Header }) => (
                    <Grid.Row key={id} verticalAlign="middle" style={{ padding: "0.5rem 0 0 0" }}>
                        <Grid.Column width={2}>
                            <label align="right">{Header}</label>
                        </Grid.Column>
                        <Grid.Column width={5}>
                            {renderInput(Header, id, { hideLabel: true, notRequired: true })}
                        </Grid.Column>
                    </Grid.Row>
                ))}
                {watch("properties")?.map((elem, i) => (
                    <Grid.Row key={i} verticalAlign="middle" style={{ padding: "0.5rem 0 0 0" }}>
                        <Grid.Column width={2}>
                            {i === 0 && (
                                <label align="right">
                                    Properties
                                    <Icon
                                        name="plus"
                                        color="blue"
                                        link
                                        style={{ marginLeft: "1rem" }}
                                        onClick={() =>
                                            setValue("properties", [
                                                ...getValues("properties"),
                                                { key: "", value: "" },
                                            ])
                                        }
                                    />
                                </label>
                            )}
                        </Grid.Column>
                        <Grid.Column width={5}>
                            {renderInput('Key', "properties[" + i + "].key", { hideLabel: true, notRequired: true })}
                        </Grid.Column>
                        <Grid.Column width={5}>
                            {renderInput('Value', "properties[" + i + "].value", { hideLabel: true, notRequired: true })}
                        </Grid.Column>
                        <Grid.Column>
                            {i !== 0 && (
                                <Icon
                                    name="trash alternate"
                                    link
                                    color="red"
                                    onClick={() => setValue("properties", getValues("properties").filter((e, idx) => idx !== i))}
                                />
                            )}
                        </Grid.Column>
                    </Grid.Row>
                ))}
                <Grid.Row>
                    <Grid.Column width={2} />
                    <Grid.Column width={2}>
                        <Form.Button primary type="submit" content="Filter" />
                    </Grid.Column>
                    <Grid.Column width={2}>
                        <Form.Button
                            type="reset"
                            content="Reset"
                            onClick={() => {
                                props.filter();
                                init();
                            }}
                        />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Form>
    );
};

export default ObjectDataFilter;
