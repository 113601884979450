import AlarmForm from '@components/AlarmForm';
import { useFetchAlarmRulesForInstance } from '@hooks/useFetchAlarmRulesForInstance';
import { useFetchModules } from '@hooks/useFetchModules';
import { useFetchProbeDetails } from '@hooks/useFetchProbeDetails';
import NMService from '@services/nm.service';
import { editProbeAtom } from '@store/editProbe';
import { addGlobalMessageAtom } from '@store/globalMessage';
import { parseObject, splitRule } from '@utils/alarmUtils';
import { useAtomValue, useSetAtom } from 'jotai';
import { useCallback, useMemo, useState } from 'react';
import MLConfig from '../AssignedModules/MLConfig';
import ModuleAlarmTable from './ModuleAlarmsTable';

const ModuleAlarms = (props) => {
    const addGlobalMessage = useSetAtom(addGlobalMessageAtom);
    const probeAtomState = useAtomValue(editProbeAtom);

    const { moduleid, instanceid, version } = useMemo(() => props.moduleData, [props.moduleData]);
    const [state, setState] = useState({
        formType: undefined,
        selectedAlarmId: undefined,
    });

    const {
        data: probeDetails,
    } = useFetchProbeDetails({
        probeid: props.probeId
    });

    const {
        data: alarmList,
        isLoading: alarmListIsLoading,
        refetch: alarmListRefetch
    } = useFetchAlarmRulesForInstance({
        moduleid,
        version,
        hwid: probeDetails.hwid,
        instanceid
    });

    const {
        data: moduleList,
        isLoading: moduleListIsLoading,
    } = useFetchModules({
        select: (data) => {
            return (
                data?.map((module) => {
                    const { id, version, resultmodel } = module;
                    return {
                        id,
                        version,
                        resultmodel: [].concat(parseObject(JSON.parse(resultmodel), '', 0, null, '$')),
                    };
                }) ?? []
            );
        },
    });

    const toggleForm = useCallback((alarmId = undefined, formType = undefined) => {
        setState((prevState) => ({
            ...prevState,
            formType: formType,
            selectedAlarmId: alarmId,
        }));
    }, []);

    const submitForm = async (data) => {
        await NMService.addAlarmRule(data).then(async () => {
            await alarmListRefetch();
            toggleForm();
            if (state.formType === 'ext-edit') {
                addGlobalMessage({
                    header: 'Alarm configuration was updated!',
                    content: 'Alarm configuration was successfully updated.',
                    type: 'positive',
                }
                );
            } else {
                addGlobalMessage({
                    header: 'Alarm configuration was created!',
                    content: 'Alarm configuration was successfully created.',
                    type: 'positive',
                }
                );
            }
        }).catch(e => null)
    };

    const formattedDefaultValues = (formType) => {
        switch (formType) {
            case 'ext-edit': {
                const selectedAlarm = alarmList?.find((e) => e.id === state.selectedAlarmId);
                if (!selectedAlarm) { break }

                const { rule: ruleStr, ...rest } = selectedAlarm;
                const initconfig = {
                    ...rest,
                    module: rest.module + '-' + rest.version,
                    rule: splitRule(ruleStr),
                };
                delete initconfig.version;

                try {
                    initconfig.rule = initconfig.rule.map(({ value, ...rest }) => ({
                        value: value.replace(/"/g, ''),
                        ...rest,
                    }));
                } catch {
                    initconfig.rule = [];
                }
                return initconfig;
            }
            case 'ext-add': {
                return {
                    module: moduleid,
                    version: version,
                    hwid: probeDetails.hwid,
                    instanceid: instanceid,
                    rule: [],
                    severity: 4,
                    scope: 'instance',
                };
            }
            default:
                break;
        }

        return {
            scope: 'none',
            rule: [],
            severity: 4,
        };
    };

    return (alarmListIsLoading || moduleListIsLoading) ? null : (
        <>
            <ModuleAlarmTable
                moduleData={props.moduleData}
                data={alarmList}
                resultModels={moduleList}
                hasPermission={probeAtomState?.permissions?.assignmodules || props.hasPermission}
                toggleForm={toggleForm}
            />
            <MLConfig
                nodedata={probeDetails}
                data={props.moduleData}
            />
            {state.formType && (
                <AlarmForm
                    key={`::alarm-form-${state.selectedAlarmId}`}
                    type={state.formType}
                    defaultValues={formattedDefaultValues(state.formType)}
                    hasPermission={probeAtomState?.permissions?.assignmodules || props.hasPermission}
                    toggleForm={toggleForm}
                    submitForm={submitForm}
                />
            )}
        </>
    );
};

export default ModuleAlarms;