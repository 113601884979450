import { useFetchResponders } from '@hooks/useFetchResponders';
import React, { useRef, useState } from 'react';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { Checkbox, Dropdown, Form, Grid, Icon, Input, Label, Popup } from 'semantic-ui-react';
import { evaluateWhen, getError, validateLeaf } from './utils/utils';


const renderArrayLabel = (name, schema) => {
    const skipLabeling = !Object.values(schema).some((e) => e.type === 'list' || e.type === 'object');
    if (skipLabeling) {
        return (
            <>
                <Grid.Column width={3}></Grid.Column>
                {Object.keys(schema).map((e) => (
                    <Grid.Column width={schema[e].render.size} key={`array_label_${name}_${e}`}>
                        <div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{schema[e].render.label}</div>
                    </Grid.Column>
                ))}
            </>
        );
    }
    return null;
};

const RenderArray = ({ elem, name, model, hasPermision = false }) => {
    const { control } = useFormContext();
    const { fields, append, remove } = useFieldArray({
        control,
        name: name,
    });
    const render = model.render;

    const getInitConfig = (model) => {
        if (model.subtype === 'object') {
            let initConfig = {};
            Object.entries(model.schema).map(([key, value]) => {
                if (value.type === 'list') {
                    if (value.subtype === 'object') {
                        return getInitConfig(value.schema);
                    }
                    return initConfig[key] = [];
                } else if (value.type === 'object') {
                    return initConfig[key] = {};
                } else if (value.type === 'boolean') {
                    return initConfig[key] = false;
                } else {
                    return initConfig[key] = '';
                }
            });
            return initConfig;
        }
    };

    return (
        <Grid>
            {render.subtype === 'single'
                ? [
                    <Grid.Row verticalAlign="middle" key={`array-header-${name}`} style={{ padding: '0.5rem 0' }}>
                        <Grid.Column width={2} textAlign="right">
                            <label align="right">{render.label}</label>
                        </Grid.Column>
                        <Grid.Column width={1} textAlign="left">
                            <Icon
                                link
                                name="plus"
                                color='blue'
                                onClick={() => {
                                    append({ var: '' });
                                }} />
                        </Grid.Column>
                    </Grid.Row>,
                    fields.map((field, index) => (
                        <Grid.Row verticalAlign="middle" key={field.id}>
                            <Grid.Column width={3} textAlign="right">
                                <label align="right" style={{ paddingRight: '1rem' }}>
                                    {`${render.itemLabel}  ${index + 1}`}
                                </label>
                                <Icon
                                    link
                                    name="trash alternate"
                                    color='blue'
                                    onClick={() => remove(index)}
                                />
                            </Grid.Column>
                            <Grid.Column width={13}>
                                <RenderObject
                                    {...{
                                        inline: render.inline,
                                        parentName: `${name}.${index}`,
                                        model: model.schema,
                                        hasPermision,
                                        skipLabeling: true,
                                    }}
                                />
                            </Grid.Column>
                        </Grid.Row>
                    )),
                ]
                : [
                    <Grid.Row verticalAlign="middle" key={`array-header-${name}`} style={{ padding: '0.5rem 0' }}>
                        <Grid.Column width={2} textAlign="right">
                            <label align="right">{render.label}</label>
                        </Grid.Column>
                        <Grid.Column width={1} textAlign="left">
                            <Icon
                                link
                                name="plus"
                                color='blue'
                                onClick={() => {
                                    append(getInitConfig(model));
                                }} 
                            />
                        </Grid.Column>
                    </Grid.Row>,
                    render.inline && fields.length > 0 && (
                        <Grid.Row verticalAlign="middle" key={`array-labels-${name}`} style={{ padding: '0' }}>
                            {renderArrayLabel(name, model.schema)}
                        </Grid.Row>
                    ),
                    fields.map((field, index) => (
                        <Grid.Row verticalAlign="middle" key={field.id} style={{ padding: '0.5rem 0' }}>
                            <Grid.Column width={3} textAlign="right">
                                <label align="right" style={{ paddingRight: '1rem' }}>
                                    {`${render.itemLabel}  ${index + 1}`}
                                </label>
                                <Icon
                                    link
                                    name="trash alternate"
                                    color='blue'
                                    onClick={() => remove(index)}
                                />
                            </Grid.Column>
                            {render.inline === false ? (
                                <Grid.Column width={13}>
                                    <RenderObject
                                        {...{
                                            inline: false,
                                            parentName: `${name}.${index}`,
                                            model: model.schema,
                                            hasPermision,
                                            skipLabeling: false,
                                        }}
                                    />
                                </Grid.Column>
                            ) : (
                                <RenderObject
                                    {...{
                                        parentName: `${name}.${index}`,
                                        inline: true,
                                        model: model.schema,
                                        hasPermision,
                                        skipLabeling: true,
                                    }}
                                />
                            )}
                        </Grid.Row>
                    )),
                ]}
        </Grid>
    );
};

const RenderInputField = ({ elem, name, model, skipLabeling = false, hasPermision = false }) => {
    const { register, getValues, formState } = useFormContext();
    const [showSecret, setShowSecret] = useState(false);
    const { description, render, ...validationParams } = model;
    const error = getError(name, formState.errors);
    let defaultValue = validationParams.default ?? validationParams.minValue ?? validationParams.minvalue ?? validationParams.maxValue ?? validationParams.maxvalue ?? "";
    let initialValue = getValues(name) ?? defaultValue;
    if (render.subtype === 'number') {
        initialValue = parseInt(initialValue || 0);
    } else if (render.subtype === 'float') {
        initialValue = parseFloat(initialValue || 0);
    }

    return (
        <>
            {!skipLabeling && render.label && (
                <Form.Group style={{ marginBottom: '0' }}>
                    <Form.Field></Form.Field>
                    <Form.Field style={{ paddingLeft: !description ? '0' : '1.75rem' }}>{render.label}</Form.Field>
                    <Form.Field></Form.Field>
                </Form.Group>
            )}
            <Form.Group style={{ marginBottom: '0' }}>
                <Form.Field>
                    {description && (
                        <Popup
                            content={description}
                            on={'hover'}
                            wide
                            trigger={<Icon size="small" circular color="black" name="info" />}
                        />
                    )}
                </Form.Field>
                <Form.Field error={Boolean(error?.type)} style={{ padding: '0' }} width={16}>
                    <Input labelPosition={render.unit ? 'right' : null} icon={render.subtype === 'password'} label={!!render.unit}>
                        <input
                            {...register(name, {
                                validate: (value) => validateLeaf(value, validationParams),
                                ...(render.subtype === 'number' && { setValueAs: (v) => parseInt(v === null || v === "" ? defaultValue : v) }),
                                ...(render.subtype === 'float' && { setValueAs: (v) => parseFloat(v === null || v === "" ? defaultValue : v) }),
                            })}
                            step={render.subtype === "float" ? "any" : null}
                            placeholder={render.placeholder || render.label}
                            type={showSecret ? "text" : render.subtype === "float" ? "number" : render.subtype}
                            defaultValue={initialValue}
                        />
                        {render.subtype === 'password' && <Icon disabled={!hasPermision} name={showSecret ? "eye slash" : "eye"} link onClick={() => setShowSecret((p) => !p)} />}
                        {render.unit && <Label basic>{render.unit}</Label>}
                    </Input>
                </Form.Field>
                <Form.Field>
                    {error?.type && (
                        <Popup
                            content={error.message}
                            on={'hover'}
                            wide
                            trigger={<Icon name="warning" color="red" style={{ margin: '0' }} />}
                        />
                    )}
                </Form.Field>
            </Form.Group>
        </>
    );
};

const RenderSelectField = ({ nodeid, moduleid, elem, name, model, skipLabeling = false }) => {
    const { getValues, control, formState } = useFormContext();
    const options = useRef([])
    const { description, render, values, responderSchema, ...validationParams } = model;
    const error = getError(name, formState.errors);

    const {
        isFetching
    } = useFetchResponders({
        probeid: nodeid,
        moduleid,
        enabled: values === 'dynamic',
        select: (i) => {
            if (responderSchema !== undefined) {
                options.current = i.map(e => {
                    let value;
                    let description = "";
                    if (Object.entries(responderSchema.value).length === 0) {
                        value = e.destination
                        description = e.destination
                    } else {
                        for (const [idx, [x, y]] of Object.entries(responderSchema.value).entries()) {
                            value = {...value, [x]: !!y ? e[y] : ''}
                            description += (!!y && !!e[y]) ? (idx === 0 ? "" : "/") + e[y] : ""
                        }
                        value = JSON.stringify(value)
                    }
                    return {
                            key: e.name + e.instanceid,
                            text: `${e.name}${!!e.instanceid ? "/" + e.instanceid : ""} - ${description}`,
                            value: value
                        }})
            }
        }
    });

    if (values !== "dynamic") {
        options.current = values.map((e) => ({
            key: e.name,
            value: e.value,
            text: e.value,
        }))
    }

    return isFetching ? null : (
        <>
            {!skipLabeling && render.label && (
                <Form.Group style={{ marginBottom: '0' }}>
                    <Form.Field></Form.Field>
                    <Form.Field style={{ paddingLeft: !description ? '0' : '1.75rem' }}>{render.label}</Form.Field>
                    <Form.Field></Form.Field>
                </Form.Group>
            )}
            <Form.Group style={{ marginBottom: '0' }}>
                <Form.Field>
                    {description && (
                        <Popup
                            content={description}
                            on={'hover'}
                            wide
                            trigger={<Icon size="small" circular color="black" name="info" />}
                        />
                    )}
                </Form.Field>
                <Form.Field error={Boolean(error?.type)} style={{ padding: '0' }} width={16}>
                    <Input>
                        <Controller
                            name={name}
                            control={control}
                            defaultValue={values === "dynamic" ? getValues(name) || [] : String(getValues(name))}
                            rules={{ validate: (value) => validateLeaf(value, validationParams) }}
                            render={({ field: { ref, ...field } }) => (
                                <Dropdown
                                    {...field}
                                    fluid
                                    selection
                                    multiple={values === "dynamic" || render.multiple === true}
                                    placeholder={render.placeholder || render.label}
                                    onChange={(_, { value }) => field.onChange(value)}
                                    options={options.current}
                                />
                            )}
                        />
                    </Input>
                </Form.Field>
                <Form.Field>
                    {error?.type && (
                        <Popup
                            content={error.message}
                            on={'hover'}
                            wide
                            trigger={<Icon name="warning" color="red" style={{ margin: '0' }} />}
                        />
                    )}
                </Form.Field>
            </Form.Group>
        </>
    );
};

const RenderObject = ({
    nodeid,
    moduleid,
    inline,
    parentName,
    model,
    skipLabeling = true,
    hasPermision = false
}) => {
    const { register, setValue, getValues, control, formState, watch } = useFormContext();

    const renderObject = () => {
        let elements = [];

        for (const elem in model) {
            if (!model[elem].hasOwnProperty('type') || !model[elem].hasOwnProperty('render')) {
                break;
            }

            const { description, type, render } = model[elem];
            let name = elem;
            if (parentName) {
                name = `${parentName}.${elem}`;
            }

            let whenCondition = true;
            if (model[elem].hasOwnProperty('when') && model[elem].when) {
                const values = watch();
                whenCondition = evaluateWhen(model[elem].when, values);
            }

            if (!whenCondition) {
                continue;
            }

            if (type === 'object') {
                elements.push(
                    <Grid key={name}>
                        <Grid.Row /* style={{ padding: '0.5rem 0' }} */>
                            {render.label && (
                                <Grid.Column width={2} textAlign="right" verticalAlign="middle">
                                    <label align="right">{render.label}</label>
                                </Grid.Column>
                            )}
                            {render.inline === false ? (
                                <Grid.Column width={render.label ? 14 : 16}>
                                    <RenderObject
                                        {...{
                                            nodeid,
                                            moduleid,
                                            inline: false,
                                            parentName: name,
                                            model: model[elem].schema,
                                            hasPermision,
                                            skipLabeling: false
                                        }}
                                    />
                                </Grid.Column>
                            ) : (
                                <RenderObject
                                    {...{
                                        nodeid,
                                        moduleid,
                                        inline: true,
                                        parentName: name,
                                        model: model[elem].schema,
                                        hasPermision,
                                        skipLabeling: false
                                    }}
                                />
                            )}
                        </Grid.Row>
                    </Grid>
                );
            } else if (render.type === 'list') {
                elements.push(
                    <RenderArray
                        key={name}
                        {...{
                            nodeid,
                            moduleid,
                            elem,
                            name,
                            model: model[elem],
                            hasPermision
                        }}
                    />
                );
            } else if (render.type === 'input') {
                if (inline) {
                    elements.push(
                        <Grid.Column key={name} width={render.size}>
                            <RenderInputField
                                {...{
                                    elem,
                                    name,
                                    model: model[elem],
                                    hasPermision,
                                    skipLabeling
                                }}
                            />
                        </Grid.Column>
                    );
                } else {
                    elements.push(
                        <Grid key={name}>
                            <Grid.Row verticalAlign="middle" style={{ padding: '0.5rem 0' }}>
                                {!parentName && (
                                    <Grid.Column width={2} textAlign="right">
                                        <label align="right">{render.label}</label>
                                    </Grid.Column>
                                )}
                                <Grid.Column width={render.size}>
                                    <RenderInputField
                                        key={name}
                                        {...{
                                            elem,
                                            name,
                                            model: model[elem],
                                            hasPermision,
                                            skipLabeling
                                        }}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    );
                }
            } else if (render.type === 'select') {
                if (inline) {
                    elements.push(
                        <Grid.Column key={name} width={render.size}>
                            <RenderSelectField
                                {...{
                                    nodeid,
                                    moduleid,
                                    elem,
                                    name,
                                    model: model[elem],
                                    skipLabeling
                                }}
                            />
                        </Grid.Column>
                    );
                } else {
                    elements.push(
                        <Grid key={name}>
                            <Grid.Row verticalAlign="middle" style={{ padding: '0.5rem 0' }}>
                                {!parentName && (
                                    <Grid.Column width={2} textAlign="right">
                                        <label align="right">{render.label}</label>
                                    </Grid.Column>
                                )}
                                <Grid.Column width={render.size}>
                                    <RenderSelectField
                                        {...{
                                            nodeid,
                                            moduleid,
                                            elem,
                                            name,
                                            model: model[elem],
                                            skipLabeling
                                        }}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    );
                }
            } else if (render.type === 'checkbox') {
                if (inline) {
                    elements.push(
                        <Grid.Column key={name} width={render.size}>
                            <Form.Group style={{ marginBottom: '0' }}>
                                <Form.Field>
                                    {description && (
                                        <Popup
                                            content={description}
                                            on={'hover'}
                                            wide
                                            trigger={<Icon size="small" circular color="black" name="info" />}
                                        />
                                    )}
                                </Form.Field>
                                <Form.Field style={{ padding: '0' }} width={16}>
                                    <Controller
                                        name={name}
                                        control={control}
                                        defaultValue={Boolean(getValues(name))}
                                        render={({ field }) => (
                                            <Checkbox
                                                label={Boolean(parentName) && render.label}
                                                onChange={(e, { checked }) => field.onChange(checked)}
                                                checked={field.value}
                                            />
                                        )}
                                    />
                                </Form.Field>
                            </Form.Group>
                        </Grid.Column>
                    );
                } else {
                    elements.push(
                        <Grid key={name}>
                            <Grid.Row verticalAlign="middle" style={{ padding: '0.5rem 0' }}>
                                {!parentName && (
                                    <Grid.Column width={2} textAlign="right">
                                        <label align="right">{render.label}</label>
                                    </Grid.Column>
                                )}
                                <Grid.Column width={!parentName ? 14 : 16}>
                                    <Form.Group style={{ marginBottom: '0' }}>
                                        <Form.Field>
                                            {description && (
                                                <Popup
                                                    content={description}
                                                    on={'hover'}
                                                    wide
                                                    trigger={<Icon size="small" circular color="black" name="info" />}
                                                />
                                            )}
                                        </Form.Field>
                                        <Form.Field style={{ padding: '0' }} width={16}>
                                            <Controller
                                                name={name}
                                                control={control}
                                                defaultValue={Boolean(getValues(name))}
                                                render={({ field }) => (
                                                    <Checkbox
                                                        label={Boolean(parentName) && render.label}
                                                        onChange={(e, { checked }) => field.onChange(checked)}
                                                        checked={field.value}
                                                    />
                                                )}
                                            />
                                        </Form.Field>
                                    </Form.Group>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    );
                }
            }
        }
        return elements;
    };

    return (
        <React.Fragment>
            {renderObject(inline, parentName, model, register, setValue, getValues, control, formState.errors)}
        </React.Fragment>
    );
};

export default RenderObject;
