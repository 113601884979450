import { useUserInfo } from '@hooks/useAuth';
import { useFetchSwModuleDetails } from '@hooks/useFetchSwModuleDetails';
import { addSwModulePermissionAtom, swModuleAtom } from '@store/swModule';
import { useAtomValue, useSetAtom } from 'jotai';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Breadcrumb, Divider, Grid, Header, Icon, List } from 'semantic-ui-react';
import { getPermission } from '../../helpers/userPermission';
import Configurator from './Configurator';
import ResultModel from './ResultModel';
import VisualizationDashTemplates from './VisualizationDashTemplates';
import VisualizationPanelTemplates from './VisualizationPanelTemplates';


const SWModuleDetails = (props) => {
    const { name: moduleid, version  } = useParams();
    const { userdata: { role: userRole } } = useUserInfo();

    const setSwModulePermission = useSetAtom(addSwModulePermissionAtom);
    const { hasPermission } = useAtomValue(swModuleAtom);

    const { data, isLoading } = useFetchSwModuleDetails({
        moduleid: moduleid, 
        version: version, 
        select: data => {
            let configModel = null;
            try {
                configModel = JSON.parse(data.configmodel);
            } catch {
                configModel = {};
            }
            return {
                moduleData: data,
                configModel: configModel,
                metapackage: data.data.metapackage
            };
        },
        options: { enabled: !!moduleid && !!version }
    });

    useEffect(() => {
        const hasPermission = getPermission( { userRole, requiredRole: 'nodeadmin' });
        setSwModulePermission({ hasPermission });
    }, [setSwModulePermission, userRole]);

    if (isLoading) {
        return null;
    }

    return (
        <React.Fragment>
            <Breadcrumb style={{ marginTop: '1rem' }}>
                <Breadcrumb.Section>Modules</Breadcrumb.Section>
                <Breadcrumb.Divider>/</Breadcrumb.Divider>
                <Breadcrumb.Section active>{data.moduleData?.id}</Breadcrumb.Section>
            </Breadcrumb>

            <Grid style={{ margin: '1rem 0' }}>
                <Grid.Row verticalAlign="middle">
                    <Grid.Column width={4}>
                        <Header as="h4">
                            <span>
                                <label
                                    style={{
                                        textAlign: 'left',
                                        marginRight: '1rem',
                                    }}
                                >
                                    <Icon
                                        name="angle double right"
                                        style={{ marginRight: '0.5rem' }}
                                    />
                                    ID:
                                </label>
                                {data.moduleData?.id}
                            </span>
                        </Header>
                    </Grid.Column>
                    <Grid.Column width={4}>
                        <Header as="h4">
                            <span>
                                <label
                                    style={{
                                        textAlign: 'left',
                                        marginRight: '1rem',
                                    }}
                                >
                                    <Icon
                                        name="angle double right"
                                        style={{ marginRight: '0.5rem' }}
                                    />
                                    VERSION:
                                </label>
                                {data.moduleData?.version}
                            </span>
                        </Header>
                    </Grid.Column>
                    <Grid.Column width={8}>
                        <Header as="h4">
                            <span>
                                <label
                                    style={{
                                        textAlign: 'left',
                                        marginRight: '1rem',
                                    }}
                                >
                                    <Icon
                                        name="angle double right"
                                        style={{ marginRight: '0.5rem' }}
                                    />
                                    DESCRIPTION:
                                </label>
                                {data.moduleData?.description}
                            </span>
                        </Header>
                    </Grid.Column>
                </Grid.Row>
                {(data.moduleData?.data?.metapackage && data.moduleData?.data?.submodules) && <Grid.Row verticalAlign="middle">
                    <Grid.Column>
                        <Header as="h4" style={{ marginBottom: '0'}}>
                            <label
                                style={{
                                    textAlign: 'left',
                                    marginRight: '1rem',
                                }}
                            >
                                <Icon
                                    name="angle double right"
                                    style={{ marginRight: '0.5rem' }}
                                />
                                Submodules:
                            </label>
                        </Header>
                        <List bulleted style={{ marginTop: '0.5rem', marginLeft: '42px'}}>
                            {data.moduleData?.data.submodules.length > 0 && data.moduleData?.data.submodules.map((e, index) => (
                                <List.Item key={`${e}_${index}`}>{e}</List.Item>
                            ))}
                        </List>
                    </Grid.Column>
                </Grid.Row>}
            </Grid>

            {!data.metapackage && <>
                <Configurator
                    id={data.moduleData?.id}
                    version={data.moduleData?.version}
                    config={data.moduleData?.commonconfig}
                    model={data.configModel}ž
                    hasPermission={hasPermission || false}
                />
                {data.moduleData?.id && (
                    <VisualizationDashTemplates
                        moduleId={data.moduleData?.id}
                        version={data.moduleData?.version}
                    />
                )}
                {data.moduleData?.id && (
                    <VisualizationPanelTemplates
                        moduleId={data.moduleData?.id}
                        version={data.moduleData?.version}
                    />
                )}
            </>}
            <ResultModel
                headerText="Module data (raw format)"
                model={data.moduleData?.data}
            />
            {!data.metapackage && <>
                <ResultModel
                    headerText="Configuration model (raw format)"
                    model={data.moduleData?.configmodel}
                />
                <ResultModel
                    headerText="Result model (raw format)"
                    model={data.moduleData?.resultmodel}
                />

                {/* <MLModuleModel moduleID={data.moduleData?.id} /> */}

                <Divider hidden style={{ margin: '1rem 0' }}></Divider>
            </>}
        </React.Fragment>
    );
}

export default SWModuleDetails