import { useFetchTemplateDetails } from '@hooks/useFetchTemplateDetails';
import { useFormFields } from '@hooks/useFormFields';
import NMService from '@services/nm.service';
import { addGlobalMessageAtom } from '@store/globalMessage';
import { useQueryClient } from '@tanstack/react-query';
import NMFieldValidator from '@utils/NMFieldValidator';
import { useSetAtom } from 'jotai';
import { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { Divider, Form, Header, Icon } from 'semantic-ui-react';
import AssignedModules from './AssignedModules/AssignedModules';

const TemplateForm = (props) => {
    const addGlobalMessage = useSetAtom(addGlobalMessageAtom);
    const queryClient = useQueryClient();
    const tagsInfo = "Up to 10 comma separated tags matching: /^[a-zA-Z0-9\u00C0-\u017F.-]{1,20}$/"
    const templateId = useMemo(() => props.templateId, [props.templateId]);

    const {
        register,
        reset,
        setValue,
        handleSubmit,
        watch,
        setError,
        clearErrors,
        formState: { errors },
    } = useForm({ defaultValues: { name: "", description: "", tags: "" }});

    const { renderInput } = useFormFields({ register, errors, setValue, watch });

    const {
        data: templateDetails,
        isLoading: templateDetailsIsLoading,
        isFetched: templateDetailsIsFetched
    } = useFetchTemplateDetails({ templateId })

    const onSubmit = (values) => {
        let tags = [];
        if (values.tags) {
            tags = values.tags.split(",").map((e) => e.trim()).filter((e) => e !== "");
            tags = Array.from(new Set(tags))
            if (!tags.every(e => /^[a-zA-Z0-9\u00C0-\u017F.-]{1,20}$/.test(e)) || tags.length > 10) {
                setError('tags', { type: 'validate', message: tagsInfo })
                return
            }
        }
        clearErrors('tags');

        const data = { ...values, tags };
        NMService.updateNMTemplate(data).then(() =>
            addGlobalMessage({
                type: 'positive',
                header: 'Template info was updated',
                content: 'Template info was updated',
            })
        ).then(r => queryClient.invalidateQueries(['getNMTemplates']))
        .catch(e => null)
    }

    useEffect(() => {
        if (templateDetailsIsFetched) {
            const { id, name, description, tags } = templateDetails;
            reset({
                id,
                name,
                description,
                tags: tags.join(', ')
            })
        }
    }, [templateDetailsIsFetched, templateDetails, reset])

    return templateDetailsIsLoading ? null : (
        <>
            <Header as="h4" style={{ marginTop: '2rem', marginBottom: '0' }}>
                Template details
                <Icon
                    link
                    name="close"
                    style={{ float: "right" }}
                    onClick={() => props.onDismiss()}
                />
            </Header>
            <Divider style={{ marginTop: '0.5rem', marginBottom: '0.5rem' }} />
            <Form onSubmit={handleSubmit(onSubmit)}>
                <div style={{ margin: "2rem" }}>
                    <Form.Group widths={3}>
                        {renderInput("Name", "name", { validate: (v) => NMFieldValidator.validateString(v, /^[a-zA-Z0-9!@#%^&*._-]{1,64}$/, 1, 64, true)})}
                        {renderInput("Description", "description", { notRequired: true })}
                        {renderInput("Tags", "tags", { notRequired: true , infoText: tagsInfo })}
                    </Form.Group>
                    <Form.Group>
                        <Form.Button type='submit' content='Update' primary />
                    </Form.Group>
                </div>
            </Form>
            <AssignedModules templateId={templateId} />
        </>
    );
}

export default TemplateForm;
