import ConfirmationModal from "@components/ConfirmationModal";
import { useAuth } from "@hooks/useAuth";
import { useFetchSuspendedMeasurements } from "@hooks/useFetchSuspendedMeasurements";
import { useFetchTenants } from "@hooks/useFetchTenants";
import NMService from "@services/nm.service";
import { addGlobalMessageAtom } from "@store/globalMessage";
import { InstaceActions, multiTenantAtom } from "@store/multiTenant";
import NMFieldValidator from "@utils/NMFieldValidator";
import { useAtom, useSetAtom } from "jotai";
import { useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { Button, Icon, Input, Label } from "semantic-ui-react";
import MultiTenantForm from "./MultiTenantForm";
import MultiTenantTable from "./MultiTenantTable";


const MultiTenant = () => {
    const navigate = useNavigate();
    const { hidrateUserData } = useAuth();
    const [state, dispatch] = useAtom(multiTenantAtom);
    const addGlobalMessage = useSetAtom(addGlobalMessageAtom);
    const suspendMinutesRef = useRef();
    suspendMinutesRef.current = '';

    const {
        setError,
        clearErrors,
        formState: { errors },
    } = useForm({ });

    const {
        data,
        isLoading: tenantListIsLoading,
        isFetching: tenantListIsFetching,
        refetch: refetchTenantList
    } = useFetchTenants({});

    const onSetTenant = async () => {
        if (!state.selectedTenantUuid) {
            return;
        }

        await NMService.setTenant({ tenantid: state.selectedTenantUuid }).then((response) => {
            hidrateUserData(response.data);
            navigate("/")
        }).catch(e => null)
    }

    const onDeleteTenant = async () => {
        if (!state.selectedTenantUuid) {
            return;
        }

        await NMService.deleteTenant(state.selectedTenantUuid).then(() => {
            addGlobalMessage({
                header: "Tenant is deleted!",
                content: "Tenant was successfully deleted.",
                type: 'positive',
            })
            refetchTenantList();
        }).catch(e => null)
    }

    const onActivateTenant = async () => {
        if (!state.selectedTenantUuid) {
            return;
        }
        await NMService.activateTenant(state.selectedTenantUuid).then(() => {
            addGlobalMessage({
                header: "Tenant is activated!",
                content: "Tenant was successfully activated.",
                type: 'positive',
            })
            refetchTenantList();
        }).catch(e => null)
    }

    const onDeactivateTenant = async () => {
        if (!state.selectedTenantUuid) {
            return;
        }
        await NMService.deactivateTenant(state.selectedTenantUuid).then(() => {
            addGlobalMessage({
                header: "Tenant is deactivated!",
                content: "Tenant was successfully deactivated.",
                type: 'positive',
            })
            refetchTenantList();
        }).catch(e => null)
    }

    useEffect(() => {
        if (tenantListIsFetching) {
            return;
        }
        dispatch({ type: InstaceActions.DeselectTenant })
        
    }, [refetchTenantList, tenantListIsFetching, dispatch,]);

    const {
        data: suspendMeasurements,
        refetch: refetchSuspendedMeasurements
    } = useFetchSuspendedMeasurements({
        params: {
            unit: 'SYSTEM'
        },
        options: {
            refetchOnMount: 'always',
        }
    });

    const toggleSuspendMeasurements = () => {

        let params = {
            duration: Number(suspendMinutesRef.current),
            suspend: !suspendMeasurements?.suspend,
            unit: "system",
        }

        if (!suspendMeasurements?.suspend) {
            let validatorMessage = NMFieldValidator.validateInt(suspendMinutesRef.current, 0, undefined, true)
            if (validatorMessage != null) {
                setError('suspendMeasurements', {message: validatorMessage, type: "manual"});
                return;
            }
        } else {
            delete params.duration
        }

        NMService.suspendMeasurements(params).then((r) =>
            addGlobalMessage({
                header: r.data?.message ? r.data.message : `Successfully ${suspendMeasurements?.suspend ? 'resumed' : 'suspended'} measurements!`,
                content: r.data?.message ? r.data.message : `Successfully ${suspendMeasurements?.suspend ? 'resumed' : 'suspended'} measurements for the system.`,
                type: r.data?.message ? 'warning' : 'positive',
            })
        ).catch(e => null)
            .finally(() =>
            {
                dispatch({ type: InstaceActions.DeselectTenant });
                suspendMinutesRef.current = '';
                refetchSuspendedMeasurements();
                clearErrors('suspendMeasurements');
            })
    };

    return (tenantListIsLoading ? null :
        <>
            <MultiTenantTable
                data={data}
                suspendMeasurements={suspendMeasurements}
                suspendMeasurementsButton={
                    <Button type="button"
                            size="small"
                            onClick={() => dispatch({ type: InstaceActions.SuspendMeasurements })} >
                        <Icon name={suspendMeasurements?.suspend ? "play" : "pause"} /> {suspendMeasurements?.suspend ? 'Resume' : 'Suspend'} measurements
                    </Button>
                }
                newTenantButton={
                    <Button
                        type="button"
                        primary
                        size="small"
                        onClick={() => dispatch({ type: InstaceActions.AddNewTenant })}
                        content="Add new tenant"
                    />
                }
            />
            {[InstaceActions.AddNewTenant, InstaceActions.EditTenant].includes(state.showType) &&
                <MultiTenantForm />
            }

            {state.showType === InstaceActions.SuspendMeasurements &&
                <ConfirmationModal
                    open={true}
                    header={`${suspendMeasurements?.suspend ? "Resume" : "Suspend"} measurements`}
                    content={
                        <>
                            Are you sure you want to {suspendMeasurements?.suspend ? "resume" : "suspend"} measurements for the entire system?<br/>
                            <pre style={{ color: "red" }}>
                                    This action will {suspendMeasurements?.suspend ? "resume" : "suspend"} all measurements for the entire system.
                                </pre>
                            {!suspendMeasurements?.suspend && <Input>
                                <input
                                    placeholder={'Suspend time [minutes]'}
                                    type={'number'}
                                    min={0}
                                    style = {{width: 200}}
                                    onChange={(e) => suspendMinutesRef.current = e.target.value}
                                />

                                {errors.suspendMeasurements?.type === "manual" && (
                                    <Label basic color="red" pointing="left" content={errors.suspendMeasurements.message}/>
                                )}
                            </Input>}
                        </>}
                    onConfirm={toggleSuspendMeasurements}
                    onDismiss={() => {
                        dispatch({ type: InstaceActions.DeselectTenant });
                        suspendMinutesRef.current = '';
                        clearErrors('suspendMeasurements');
                        refetchSuspendedMeasurements();
                    }}
                />
            }

            {state.showType === InstaceActions.DeleteTenant &&
                <ConfirmationModal
                    open={true}
                    header="Delete tenant"
                    content="Are you sure you want to delete tenant?"
                    onConfirm={() => onDeleteTenant()}
                    onDismiss={() => dispatch({ type: InstaceActions.DeselectTenant })}
                />
            }

            {state.showType === InstaceActions.ActivateTenant &&
                <ConfirmationModal
                    open={true}
                    header="Tenant activation"
                    content="Are you sure you want to activate tenant?"
                    onConfirm={() => onActivateTenant()}
                    onDismiss={() => dispatch({ type: InstaceActions.DeselectTenant })}
                />
            }

            {state.showType === InstaceActions.DeActivateTenant &&
                <ConfirmationModal
                    open={true}
                    header="Delete deactivation"
                    content="Are you sure you want to deactivate tenant?"
                    onConfirm={() => onDeactivateTenant()}
                    onDismiss={() => dispatch({ type: InstaceActions.DeselectTenant })}
                />
            }

            {state.showType === InstaceActions.SetTenant &&
                <ConfirmationModal
                    open={true}
                    header="Set tenant"
                    content="Are you sure you want to set tenant?"
                    onConfirm={() => onSetTenant()}
                    onDismiss={() => dispatch({ type: InstaceActions.DeselectTenant })}
                />
            }
        </>
    )
}

export default MultiTenant;