import NMService from "@services/nm.service";
import { useQuery } from "@tanstack/react-query";

export const useFetchProbeModule = ({
    probeid,
    moduleid,
    instanceid,
    enabled = true,
    select,
    options
} = {}) => {
    const query = useQuery({
        queryKey: ['getNodeModule', probeid, moduleid, instanceid],
        queryFn: () =>
            NMService.getNodeModule(probeid, moduleid, instanceid)
                .then(r => r.data)
                .catch(e => {}),
        enabled: enabled && !!probeid && !!moduleid && !!instanceid,
        staleTime: Infinity,
        select,
        ...options
    })
    return { ...query, data: query.data ?? {} }
}