import { useFormFields } from '@hooks/useFormFields';
import NMService from '@services/nm.service';
import { useEffect, useMemo } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { Form, Header, Icon } from 'semantic-ui-react';
import { customDhcpV4Options } from '../utils/custom-dhcpv4-options';

const IPV4_REGEX = /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/;
const IPV4_PREFIX_REGEX = /^((25[0-5]|(2[0-4]|1\d|[1-9]|)\d)(\.)){3}(25[0-5]|(2[0-4]|1\d|[1-9]|)\d){1}[/](?:[0-9]|1[0-9]|2[0-9]|3[0-2])$/;

const DhcpSubnetForm = (props) => {
    const data = useMemo(() => props.data, [props.data])
    const {
        handleSubmit,
        register,
        setValue,
        control,
        watch,
        reset,
        formState: { errors },
    } = useForm({
        defaultValues: props.data,
        mode: 'onSubmit',
    });
    const { renderInput, renderDropdown, renderCheckbox } = useFormFields({ register, errors, setValue, watch });

    const {
        fields: relayIpAddressFields,
        remove: relayIpAddressRemove,
        append: relayIpAddressAppend,
    } = useFieldArray({ control, name: 'relay.ip-addresses' });

    const {
        fields: optDataFields,
        remove: optDataRemove,
        append: optDataAppend,
    } = useFieldArray({ control, name: 'optiondata' });

    const { fields: poolFields, remove: poolRemove, append: poolAppend } = useFieldArray({ control, name: 'pools' });

    const getOptionDataOptions = () => {
        return customDhcpV4Options.map((option) => ({
            key: option.code,
            value: option.code,
            text: `${option.name} ${option.code}`,
        }));
    };

    const onSubmit = (values) => {
        let { enabled, relay, pools, optiondata, ...rest } = values;
        let data = { ...rest };

        data['relay'] = relay['ip-addresses'].map((e) => e.var).filter((e) => e);
        data['pools'] = pools.map((e) => `${e.startIpAddress}-${e.endIpAddress}`).filter((e) => e);
        data['enabled'] = enabled ? 1 : 0;
        data['optiondata'] = JSON.stringify(optiondata)

        NMService.addOrUpdateDhcpSubnet(data).then(() => props.updateData()).catch(e => null)
    };

    useEffect(() => {
        if (data) reset(data)
    }, [data, reset])

    return (
        <Form onSubmit={handleSubmit(onSubmit)} className="basic segment" style={{ padding: '1rem' }}>
            <Header dividing as='h4' style={{ margin: '2rem 0 1.5rem 0' }}>{props.type === 'add' ? 'Add new subnet' : 'Update subnet'}</Header>
            {renderCheckbox("Enable subnet", "enabled")}
            {renderInput('Subnet address', 'subnet', { width: 4, notRequired: true, validate: e => IPV4_PREFIX_REGEX.test(e) ? undefined : 'Invalid IPv4 address with prefix' })}

            <Header dividing as='h4' style={{ margin: '2rem 0 1.5rem 0' }}>Relay IP addresses</Header>
            <label align="right">Add new relay</label>
            <Icon
                name="plus"
                link
                style={{ marginLeft: '1rem', color: '#4183c4' }}
                onClick={() => relayIpAddressAppend({ var: '' })}
            />
            {relayIpAddressFields.map((relayIpAddressField, relayIpAddressIndex) => (
                <Form.Group key={relayIpAddressField.id} style={{ margin: '1rem 0 0 3rem' }}>
                    <Icon
                        link
                        name="trash alternate"
                        style={{ color: '#4183C4', position: 'relative', marginTop: '1.75rem' }}
                        onClick={() => relayIpAddressRemove(relayIpAddressIndex)}
                    />
                    {renderInput('Relay agent IP address', `relay.ip-addresses.${relayIpAddressIndex}.var`, { width: 4, validate: e => IPV4_REGEX.test(e) ? undefined : 'Invalid IPv4 address' })}
                </Form.Group>
            ))}

            <Header dividing as='h4' style={{ margin: '2rem 0 1.5rem 0' }}>Pools</Header>
            <label align="right">Add new pool</label>
            <Icon
                name="plus"
                link
                style={{ marginLeft: '1rem', color: '#4183c4' }}
                onClick={() => poolAppend({ startIpAddress: '', endIpAddress: ''})}
            />
            {poolFields.map((poolField, poolIndex) => (
                <Form.Group key={poolField.id} style={{ margin: '1rem 0 0 3rem' }}>
                    <Icon
                        link
                        name="trash alternate"
                        style={{ color: '#4183C4', position: 'relative', marginTop: '1.75rem' }}
                        onClick={() => poolRemove(poolIndex)}
                    />
                    {renderInput('Start IP address', `pools.${poolIndex}.startIpAddress`, { width: 4, validate: e => IPV4_REGEX.test(e) ? undefined : 'Invalid IPv4 address' })}
                    {renderInput('End IP address', `pools.${poolIndex}.endIpAddress`, { width: 4, validate: e => IPV4_REGEX.test(e) ? undefined : 'Invalid IPv4 address' })}
                </Form.Group>
            ))}

            <Header dividing as='h4' style={{ margin: '2rem 0 1.5rem 0' }}>Options data</Header>
            <label align="right">Add new option</label>
            <Icon
                name="plus"
                link
                style={{ marginLeft: '1rem', color: '#4183c4' }}
                onClick={() => optDataAppend({ code: '', data: '', space: 'dhcpv4', 'csv-format': true })}
            />
            {optDataFields.map((optDataField, optDataIndex) => (
                <Form.Group key={optDataField.id} style={{ margin: '1rem 0 0 3rem' }}>
                    <Icon
                        link
                        name="trash alternate"
                        style={{ color: '#4183C4', position: 'relative', marginTop: '1.75rem' }}
                        onClick={() => optDataRemove(optDataIndex)}
                    />
                    {renderDropdown('Option code/name', `optiondata.${optDataIndex}.code`, getOptionDataOptions(), { width: 5 })}
                    {renderDropdown('Space', `optiondata.${optDataIndex}.space`, [{ key: 'dhcpv4', value: 'dhcpv4', text: 'dhcpv4' }], { width: 3 })}
                    {renderDropdown('CSV format', `optiondata.${optDataIndex}.csv-format`, [{ key: 'true', value: true, text: 'true' }, { key: 'false', value: false, text: 'false' }], { width: 3 })}
                    {renderInput('Data', `optiondata.${optDataIndex}.data`, { width: 5 })}
                </Form.Group>
            ))}
            <Form.Group style={{ margin: '3rem 0 1rem 0' }}>
                <Form.Button size="small" type="submit" primary content={props.type === 'add' ? 'Add' : 'Update'}/>
                <Form.Button size="small" type="button" onClick={() => props.toggle()} content='Cancel'/>
            </Form.Group>

        </Form>
    );
};

export default DhcpSubnetForm;
