import styled from 'styled-components';

const Button = styled.button`
    border: none;
    background-color: inherit;
    color: #2185d0;
    opacity: ${props => props.disabled && '0.45'};
    text-decoration: none;
    font-size: 1rem;
    line-height: 1.4285em;
    cursor: pointer;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    font-weight: ${props => props.selected && 'bold'};
`

export const AnchorStyleButton = ({ children, ...rest }) => {
    return (
        <Button type="button" {...rest}>{children}</Button>
    )
}