import CustomHeader from '@components/CustomHeader';
import { useFetchGroups } from '@hooks/useFetchGroups';
import { useFetchProbeDetails } from '@hooks/useFetchProbeDetails';
import { useFormFields } from '@hooks/useFormFields';
import NMService from '@services/nm.service';
import { addGlobalMessageAtom } from '@store/globalMessage';
import { useSetAtom } from 'jotai';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Form } from 'semantic-ui-react';

const ProbePermissions = (props) => {
    const addGlobalMessage = useSetAtom(addGlobalMessageAtom);
    const [showForm, setShowForm] = useState(false);

    const { register, reset, handleSubmit, watch, formState: { errors }, setValue } = useForm();
    const { renderCheckbox, renderDropdown } =  useFormFields({ register, errors, setValue, watch });

    const {
        data: probeDetails,
        isLoading: probeDetailsIsLoading,
        isFetching: probeDetailsIsFetching,
        refetch: refetchProbeDetails
    } = useFetchProbeDetails({ probeid: props.probeId });

    const {
        data: groupList = [],
    } = useFetchGroups({
        select: e => (e || []).map(({ name }, i) => ({ key: `${name}-${i}`, text: name, value: name })),
        options: { refetchOnMount: 'always' }
    });

    const onSubmit = (values) =>
        NMService.updateNodePermissions(props.probeId, { group: values.groups, permissions: values.permissions })
            .then(() => addGlobalMessage({
                            header: 'Node permisssions were updated!',
                            content: `Node permisssion were successfully updated.`,
                            type: 'positive',
                        }))
            .then(() => refetchProbeDetails())
            .catch(e => null)

    useEffect(() => {
        if (!probeDetailsIsLoading && !probeDetailsIsFetching)
            reset({ groups: probeDetails.groups, permissions: probeDetails.permissions })
    }, [probeDetails, probeDetailsIsLoading, probeDetailsIsFetching]); // eslint-disable-line

    return (
        <>
            <CustomHeader title='Node permissions' toggle={() => setShowForm(p => !p)} show={showForm} />
            {probeDetailsIsLoading || probeDetailsIsFetching ? null : 
                <Form onSubmit={handleSubmit(onSubmit)} hidden={!showForm} style={{ margin: '2rem 1rem 1rem 1rem' }}>
                    <Form.Group>
                        <Form.Field width={2}>Assign to groups</Form.Field>
                        {renderDropdown('Groups', 'groups', groupList, { notRequired: true, multiple: true, hideLabel: true, width: 5 })}
                    </Form.Group>
                    {[{accessor: 'editbasicdata', header: 'Edit basic data'},
                    {accessor: 'assignmodules', header: 'Assign modules'},
                    {accessor: 'editpermissions', header: 'Change permissions'}
                    ].map((e, i)=>
                        <Form.Group key={i} style={{ marginTop: '2rem' }}>
                            <Form.Field width={2}>{e.header}</Form.Field>
                            {renderCheckbox("owner", `permissions.${e.accessor}`, { width: 2, radio: true, checked: 'owner' })}
                            {renderCheckbox("group admin", `permissions.${e.accessor}`, { width: 2, radio: true, checked: 'groupadmin' })}
                            {renderCheckbox("members", `permissions.${e.accessor}`, { width: 2, radio: true, checked: 'groupall' })}
                            {renderCheckbox("anyone", `permissions.${e.accessor}`, { width: 2, radio: true, checked: 'any' })}
                        </Form.Group>)}
                    <Form.Group>
                        <Form.Button type='submit' size='small' primary content='Update'/>
                        <Form.Button type='button' size='small' content='Reset' onClick={() => reset({ groups: probeDetails.groups, permissions: probeDetails.permissions })}/>
                    </Form.Group>
                </Form>}
        </>
    );
}

export default ProbePermissions;