import { useFetchSwModuleDetails } from '@hooks/useFetchSwModuleDetails';
import { addGlobalMessageAtom } from '@store/globalMessage';
import { useAtom, useSetAtom } from 'jotai';
import React, { useState } from 'react';
import SnmpModule from '../../managedNodeEdit/ModuleConfig/SnmpModule';
import { templateModulesHasPermission } from './AssignedModules';
import Configurator from './Configurator';
import RawConfigModule from './RawModuleConfig';

const ModuleConfig = (props) => {
    const addGlobalMessage = useSetAtom(addGlobalMessageAtom);
    const [modulesHasPermission] = useAtom(templateModulesHasPermission);
    const { templateid, moduleid, instanceid, version } = props.data;
    const [state, setState] = useState({
        topFrameworks: [],
        renderAsRawJson: true,
    })

    const switchView = () => {
        setState((prevState) => ({
            ...prevState,
            renderAsRawJson: !prevState.renderAsRawJson
        }));
    }

    const formSubmitted = () => {
        addGlobalMessage({
            header: 'Template configuration was updated!',
            content: `Template configuration was successfully updated.`,
            type: 'positive',
        });
    }

    const {
        data: swModuleDetails,
        isLoading: swModuleDetailsLoading
    } = useFetchSwModuleDetails({
        moduleid,
        version,
        select: (data) => {
            let configModel = {};
            try {
                configModel = JSON.parse(data.configmodel);
            } catch {
                configModel = {};
            }

            let commonConfig = {};
            try {
                commonConfig = JSON.parse(data.commonconfig);
            } catch {
                commonConfig = {};
            }

            return { ...data, configmodel: configModel, commonconfig: commonConfig }
        },
        options: {
            enabled: !!moduleid && !!version,
            initialData: {},
        }
    })

    if (swModuleDetailsLoading) {
        return null;
    }

    return (
        <React.Fragment>
            {state.renderAsRawJson ? (moduleid !== 'snmp' ?
                <Configurator
                    templateid={templateid}
                    moduleid={moduleid}
                    instanceid={instanceid}
                    version={version}
                    model={swModuleDetails.configmodel}
                    config={swModuleDetails.commonconfig}
                    hardlinked={false}
                    hasPermission={modulesHasPermission}
                    switchView={switchView}
                    formSubmitted={formSubmitted}
                    dismissForm={props.dismissForm}
                    noConfigChangeMessage={props.noConfigChangeMessage}
                /> :
                <SnmpModule
                    template={true}
                    probeid={templateid}
                    moduleid={moduleid}
                    instanceid={instanceid}
                    version={version}
                    hasPermission={modulesHasPermission}
                    switchView={switchView}
                    formSubmitted={formSubmitted}
                    noConfigChangeMessage={props.noConfigChangeMessage}
                />
            ) : (
                <RawConfigModule
                    templateid={templateid}
                    moduleid={moduleid}
                    instanceid={instanceid}
                    version={version}
                    hardlinked={false}
                    hasPermission={modulesHasPermission}
                    switchView={switchView}
                    formSubmitted={formSubmitted}
                    dismissForm={props.dismissForm}
                    noConfigChangeMessage={props.noConfigChangeMessage}
                />
            )}
        </React.Fragment>
    );
}

export default ModuleConfig;
