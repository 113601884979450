import ConfirmationModal from '@components/ConfirmationModal';
import CustomHeader from '@components/CustomHeader';
import NMService from '@services/nm.service';
import { addGlobalMessageAtom } from '@store/globalMessage';
import { useSetAtom } from 'jotai';
import { useCallback, useEffect, useReducer } from 'react';
import { Breadcrumb, Divider } from 'semantic-ui-react';
import CreateSnapshot from './CreateSnapshot';
import SnapshotTable from './SnapshotTable';

const reducer = (state, action) => {
    switch (action.type) {
        case 'updateState':
            return { ...state, ...action.payload };
        default:
            throw new Error();
    }
};

const ConfigActions = () => {
    const addGlobalMessage = useSetAtom(addGlobalMessageAtom);
    const [state, dispatchLocal] = useReducer(reducer, {
        snapshotList: [],
        selectedSnapshot: {},
        showSegment: false,
        showForm: false,
        showDeleteModal: false,
        showRollbackModal: false,
    });

    const toggleSegment = () => dispatchLocal({ type: 'updateState', payload: { showSegment: !state.showSegment, showForm: false } })

    const onRollbackConfiguration = () =>
        !state.selectedSnapshot?.id ? null : 
        NMService.applyNodeSnapshot(state.selectedSnapshot.id).then(() =>
            addGlobalMessage({
                header: 'Configuration rolled back successfully!',
                content: 'Configuration rolled back successfully!',
                type: 'positive',
            })
        ).then(() => fetchNodeSnapshots())
        .catch(e => null);

    const onDeleteSnapshot = () =>
        !state.selectedSnapshot?.id ? null :
        NMService.deleteNodeSnapshot(state.selectedSnapshot.id).then(() =>
            addGlobalMessage({
                header: 'Snapshot deleted successfully!',
                content: 'Snapshot deleted successfully!',
                type: 'positive',
            })).then(() => fetchNodeSnapshots()).catch(e => null)

    const fetchNodeSnapshots = useCallback(async () => {
        await NMService.getNodeSnapshots().then((response) => {
            const sortedSnapshotList = response.data.sort((d1, d2) => {
                const date1 = new Date(d1.created).getTime();
                const date2 = new Date(d2.created).getTime();
                return date1 < date2 ? 1 : (date1 > date2 ? -1 : 0)
            })
            dispatchLocal({
                type: 'updateState',
                payload: {
                    snapshotList: sortedSnapshotList,
                    showForm: false,
                    showDeleteModal: false,
                    showRollbackModal: false,
                    selectedSnapshot: {},
                },
            });
        }).catch(e => null);
    }, []);

    useEffect(() => {
        fetchNodeSnapshots();
    }, [fetchNodeSnapshots]);

    return (
        <>
            <Breadcrumb style={{ marginTop: '1rem' }}>
                <Breadcrumb.Section>Configuration</Breadcrumb.Section>
                <Breadcrumb.Divider/>
                <Breadcrumb.Section active>Global configuration</Breadcrumb.Section>
            </Breadcrumb>
            <Divider clearing style={{ marginBottom: '1.5rem' }} />
            <CustomHeader
                title='Snapshot & Rollback configuration'
                toggle={() => toggleSegment()}
                show={state.showSegment}
            />

            {state.showSegment && (
                <SnapshotTable
                    data={state.snapshotList}
                    showDeleteModal={(data) =>
                        dispatchLocal({
                            type: 'updateState',
                            payload: {
                                showDeleteModal: true,
                                showForm: false,
                                selectedSnapshot: data,
                            },
                        })}
                    showRollbackModal={(data) =>
                        dispatchLocal({
                            type: 'updateState',
                            payload: {
                                showRollbackModal: true,
                                showForm: false,
                                selectedSnapshot: data,
                            },
                        })}
                    showForm={() =>
                        dispatchLocal({
                            type: 'updateState',
                            payload: { showForm: true },
                        })}
                />
            )}

            <ConfirmationModal
                open={state.showSegment && state.showRollbackModal}
                header="Rollback configuration"
                content='Are you sure you want to rollback configuration?'
                onConfirm={() => onRollbackConfiguration()}
                onDismiss={() => dispatchLocal({ type: 'updateState', payload: { showRollbackModal: false, selectedSnapshot: {} }})}
            />
            <ConfirmationModal
                open={state.showSegment && state.showDeleteModal}
                header="Delete snapshot"
                content='Are you sure you want to delete snapshot?'
                onConfirm={() => onDeleteSnapshot()}
                onDismiss={() => dispatchLocal({ type: 'updateState', payload: { showDeleteModal: false, selectedSnapshot: {} }})}
            />

            {state.showSegment && state.showForm && (
                <CreateSnapshot
                    hideForm={() => dispatchLocal({ type: 'updateState', payload: { showForm: false }})}
                    updateData={fetchNodeSnapshots}
                />
            )}
        </>
    );
};

export default ConfigActions;