import { useFormFields } from "@hooks/useFormFields";
import { PermissionsGate } from "@layouts/PermissionGate";
import NMService from "@services/nm.service";
import { editProbeAtom } from "@store/editProbe";
import { addGlobalMessageAtom } from "@store/globalMessage";
import { useAtomValue, useSetAtom } from "jotai";
import { useForm } from "react-hook-form";
import { Form } from "semantic-ui-react";
import { RouteValidation } from "./networkValidation";

function NetworkRouteForm(props) {
    const addGlobalMessage = useSetAtom(addGlobalMessageAtom);
    const probeAtomState = useAtomValue(editProbeAtom);
    const { handleSubmit, register, watch, setValue, formState: { errors } } = useForm({ defaultValues: { metric: 100 } });
    const { renderInput } = useFormFields({ register, errors, setValue, watch });

    const onSubmit = (values) => {
        if (!!watch('via')) { delete values.dev } else { delete values.via }
        NMService.postRouteConfig(props.nodeId, { ...values, namespace: values.namespace ? values.namespace : "default" }).then((r) =>
                addGlobalMessage({
                    header: "Add route configuration",
                    content: "Route successfully added",
                    type: "positive",
                })
        ).then(() => {
            props.toggleForm();
            props.updateNamespacesConfig();
        }).catch(e => null)
    }

    return (
        <Form onSubmit={handleSubmit(onSubmit)} className="basic segment" style={{ padding: "0 1rem" }}>
            <Form.Group widths={5}>
                {renderInput('Destination', 'destination', { validate: (v) => RouteValidation.validateDestination(v) })}
                {renderInput('Via', 'via', { disabled: !!watch("dev"), notRequired: true, validate: (v) => RouteValidation.validateVia(v, !!watch('dev')) })}
                {renderInput('Device', 'dev', { notRequired: true, disabled: !!watch("via"), validate: (v) => RouteValidation.validateDev(v, !!watch('via')) })}
                {renderInput('Metric', 'metric', { min: 0, max: 65536, inputType: 'number' })}
                {renderInput('Namespace', 'namespace', { notRequired: true })}
            </Form.Group>
            <Form.Group style={{ paddingLeft: "0.5rem" }}>
                <PermissionsGate hasPermission={probeAtomState?.permissions?.editbasicdata}>
                    <Form.Button type="submit" size="small" primary content="Save" />
                </PermissionsGate>
                <PermissionsGate hasPermission={probeAtomState?.permissions?.editbasicdata}>
                    <Form.Button type="button" size="small" onClick={() => props.toggleForm()} content="Cancel" />
                </PermissionsGate>
            </Form.Group>
        </Form>
    );
}

export default NetworkRouteForm;
