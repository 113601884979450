import NMService from "@services/nm.service";
import { useQuery } from "@tanstack/react-query";

export const useFetchTemplateDetails = ({ 
    templateId, 
    select, 
    options 
} = {}) => {
    const query = useQuery({
        queryKey: ['getNMTemplateDetails', templateId],
        queryFn: () =>
            NMService.getNMTemplateDetails(templateId)
                .then(r => r.data)
                .catch(e => {}),
        enabled: !!templateId,
        select,
        ...options
    })
    return { ...query, data: query.data ?? {} }
}