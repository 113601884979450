import NMService from "@services/nm.service";
import { useQuery } from "@tanstack/react-query";

export const useFetchModules = ({ 
    select, 
    options 
} = {}) => {
    const query = useQuery({
        queryKey: ['modules'],
        queryFn: () =>
            NMService.getModuleList()
            .then(r => r.data)
            .catch(e => []),
        placeholderData: (data) => data ?? [],
        staleTime: Infinity,
        select,
        ...options
    })
    return { ...query, data: query.data ?? [] }
}