import CustomTable from '@components/CustomTable';
import { strftime } from '@utils/strftime';
import { useMemo } from 'react';
import { Button } from 'semantic-ui-react';

const SnapshotTable = (props) => {
    const data = useMemo(() => props.data, [props.data]);
    const columns = useMemo(
        () => [
            {
                id: 'name',
                Header: 'Name',
                accessor: 'name',
            },
            {
                id: 'description',
                Header: 'Description',
                accessor: 'description',
            },
            {
                id: 'created',
                Header: 'Created',
                accessor: (d) => strftime('%Y-%m-%d %H:%M:%S', new Date(Date.parse(d.created))),
            },
        ],
        []
    );

    const tableHooks = (hooks) => {
        hooks.visibleColumns.push((columns) => [
            ...columns,
            {
                Header: () => null,
                id: 'actions',
                Cell: ({ row }) =>
                    <div style={{ textAlign: 'center' }}>
                        <Button
                            circular
                            icon="history"
                            onClick={props.showRollbackModal.bind(null, row.original)}
                        />
                        <Button
                            circular
                            icon="trash"
                            onClick={props.showDeleteModal.bind(null, row.original)}
                        />
                    </div>,
                disableGlobalFilter: true,
                disableFilters: true,
                width: 2,
            },
        ]);
    };

    return (
        <CustomTable
            data={data}
            columns={columns}
            tableHooks={tableHooks}
            footerButton={() =>
                <Button
                    floated='right'
                    type="button"
                    primary
                    size='tiny'
                    content="Create new snapshot"
                    onClick={props.showForm.bind(null)}
                />
            }
        />
    );
};

export default SnapshotTable;
