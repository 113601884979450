import { uuidv4 } from "@utils/uuidv4";
import { atom } from "jotai";

const createMessage = (message, autoDismiss = true) => ({
    ...message,
    meta: {
        id: `${uuidv4()}`,
        added: new Date(),
        autoDismiss
    }
});

const globalMessagesAtom = atom([]);

export const addGlobalMessageAtom = atom(
    null,
    (_get, set, { autoDismiss = true, ...message }) => {
        set(
            globalMessagesAtom,
            (messages) => [
                ...messages,
                createMessage(message, autoDismiss),
            ],
        );
    },
);

export const getGlobalMessagesAtom = atom((get) => get(globalMessagesAtom));

export const removeGlobalMessageAtom = atom(
    null,
    (_get, set, id) => {
        set(
            globalMessagesAtom,
            (messages) => messages.filter((message) => message.meta.id !== id),
        );
    },
);

export const removeGlobalAllMessagesAtom = atom(
    null,
    (_get, set) => {
        set(
            globalMessagesAtom,
            [],
        );
    },
);

