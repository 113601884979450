import NMService from "@services/nm.service";
import { useQuery } from "@tanstack/react-query";

export const useFetchSuspendedMeasurements = ({
    params,
    select, 
    options 
} = {}) => {
    const query = useQuery({
        queryKey: ['getSuspendedMeasurements', params.unit, params.nodeId, params.id],
        queryFn: () =>
            NMService.getsuspendedMeasurements(params)
                .then(r => r.data)
                .catch(e => []),
        staleTime: Infinity,
        placeholderData: (data) => data ?? [], 
        select,
        ...options
    })
    return { ...query, data: query.data ?? [] }
}