import { useFetchProbeModule } from '@hooks/useFetchProbeModule';
import { PermissionsGate } from '@layouts/PermissionGate';
import { addGlobalMessageAtom } from '@store/globalMessage';
import { useSetAtom } from 'jotai';
import _ from 'lodash';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Button, Form, Grid, TextArea } from 'semantic-ui-react';

function RawConfig(props) {
    const addGlobalMessage = useSetAtom(addGlobalMessageAtom);
    const probeid = props.probeid;
    const moduleid = props.moduleid;
    const instanceid = props.instanceid;
    const hardlinked = props.hardlinked;
    const { handleSubmit, control, reset } = useForm();

    const {
        data: nodeModuleData,
        isFetching: nodeModuleIsFetching,
    } = useFetchProbeModule({
        probeid,
        moduleid,
        instanceid,
        select: (data) => {
            if (data && data.hasOwnProperty('config')) {
                return JSON.stringify(JSON.parse(data.config), null, 4);
            }
        },
        options: {
            refetchOnMount: "always",
        },
    });

    const onSubmit = (values) => {
        try {
            values.config = JSON.stringify(JSON.parse(values.config))
        } catch {
            addGlobalMessage({
                header: 'JSON not valid.',
                content: 'Module configuration not updated.',
                type: 'negative',
            })
            return
        }

        if (_.isEqual(JSON.parse(values.config), JSON.parse(nodeModuleData))) {
            props.noConfigChangeMessage()
            return
        }

        let data = {
            nodeid: props.probeid,
            moduleid: props.moduleid,
            instanceid: props.instanceid,
            config: values.config,
        };
        props.onFormSubmit(data);
    };

    useEffect(() => {
        if (nodeModuleData && !nodeModuleIsFetching) {
            try {
                reset({ config: nodeModuleData });
            } catch {
                reset({})
            }
        }
    }, [nodeModuleData, nodeModuleIsFetching, reset]);

    return (
        <Form style={{ padding: '0 0 0 0' }} onSubmit={handleSubmit(onSubmit)}>
            <Grid>
                <Grid.Row>
                    <Grid.Column>
                        <Form.Field>
                            <Controller
                                name="config"
                                control={control}
                                render={({ field: { ref, ...field } }) => (
                                    <TextArea
                                        style={hardlinked ? { cursor: 'default' } : {}}
                                        {...field}
                                        ref={ref}
                                        required
                                        rows={15}
                                    />
                                )}
                            />
                        </Form.Field>
                    </Grid.Column>
                </Grid.Row>
                {!hardlinked ?
                    <Grid.Row style={{ paddingLeft: '1rem' }}>
                        <Grid.Column width={6}>
                            <Form.Field align="left">
                                <PermissionsGate hasPermission={props.hasPermission}>
                                    <Button
                                        size="small"
                                        primary
                                        content='Update'
                                    />
                                </PermissionsGate>
                                <PermissionsGate hasPermission={props.hasPermission}>
                                    <Button
                                        size="small"
                                        type="button"
                                        onClick={() => reset({ config: nodeModuleData })}
                                        content='Reset'
                                    />
                                </PermissionsGate>
                                <PermissionsGate hasPermission={props.hasPermission}>
                                    <Button
                                        size="small"
                                        type="button"
                                        onClick={props.switchView}
                                        content='Switch view'
                                    />
                                </PermissionsGate>
                            </Form.Field>
                        </Grid.Column>
                    </Grid.Row> : null}
            </Grid>
        </Form>
    );
}

export default RawConfig;
