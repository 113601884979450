import CustomTable from '@components/CustomTable';
import { ADMIN } from '@constants/roles';
import { useGetGlobalPermission } from '@hooks/useGetGlobalPermission';
import { PermissionsGate } from '@layouts/PermissionGate';
import { snmpActions, snmpAtom } from '@store/snmpModule';
import { useAtom } from 'jotai';
import { useMemo } from 'react';
import { Button } from 'semantic-ui-react';


function SnmpTable(props) {
    const hasPermision = useGetGlobalPermission(ADMIN);
    const [, dispatch] = useAtom(snmpAtom);
    const { AddHost, EditHost, DeleteHost } = snmpActions;

    const data = useMemo(() => props.data, [props.data]);
    const columns = useMemo(
        () => [
            {
                id: 'name',
                Header: 'Name',
                accessor: 'name',
                width: 2,
            },
            {
                id: 'host',
                Header: 'Address',
                accessor: 'host',
                width: 2,
            },
            {
                id: 'group',
                Header: 'Group',
                accessor: 'group',
                width: 3,
            },
            {
                id: 'security_profile',
                Header: 'Security profile',
                accessor: 'security_profile',
                width: 2,
            },
            {
                id: 'check_ping',
                Header: 'Check ping',
                accessor: d => !!d.check_ping ? 'Yes' : 'No',
                width: 1,
            },
        ],
        [] //eslint-disable-line
    );

    const tableHooks = (hooks) => {
        hooks.visibleColumns.push((columns) => [
            ...columns,
            {
                Header: () => null,
                id: 'actions',
                Cell: ({ row }) =>
                    <div style={{ textAlign: 'center' }}>
                        <PermissionsGate hasPermission={hasPermision}>
                            <Button
                                circular
                                icon="edit"
                                type="button"
                                onClick={() => dispatch({ type: EditHost, value: row.original })}
                            />
                        </PermissionsGate>
                        <PermissionsGate hasPermission={hasPermision}>
                            <Button
                                circular
                                icon="trash"
                                type="button"
                                onClick={() => dispatch({ type: DeleteHost, value: row.original })}
                            />
                        </PermissionsGate>
                    </div>,
                disableGlobalFilter: true,
                disableFilters: true,
                width: 2,
            },
        ]);
    };

    return (
        <CustomTable
            data={data}
            columns={columns}
            tableHooks={tableHooks}
            footerButton={() =>
                <Button
                    floated='right'
                    primary
                    size='tiny'
                    type="button"
                    content='Add new host'
                    onClick={() => dispatch({ type: AddHost })}
                />
            }
        />
    );
}

export default SnmpTable;
