import { useFetchNotificationsChannelTemplates } from "@hooks/useFetchNotificationsChannelTemplates";
import NMService from "@services/nm.service";
import { addGlobalMessageAtom } from "@store/globalMessage";
import { useQuery } from "@tanstack/react-query";
import { useSetAtom } from "jotai";
import { useMemo, useState } from "react";
import { Dropdown, Header, Icon } from "semantic-ui-react";
import { EmailNotificationForm } from "./forms/EmailNotificationForm";
import { SMSNotificationForm } from "./forms/SMSNotificationForm";
import { SNMPNotificationForm } from "./forms/SNMPNotificationForm";

const NotificationForm = (props) => {
    const addGlobalMessage = useSetAtom(addGlobalMessageAtom);
    const id = useMemo(() => props.data?.id, [props.data?.id]);
    const data = useMemo(() => props.data, [props.data]);
    const [channeltype, setChannelType] = useState(props.data?.channeltype.id);

    const { data: templates } = useFetchNotificationsChannelTemplates({options: { refetchOnMount: "always", enabled: !!!id }});
    const {
        data: severityList = [],
        isLoading: severityIsFetching
    } = useQuery({
        queryKey: ['NMService', 'getAlarmSeverityList'],
        queryFn: () => NMService.getAlarmSeverityList()
                                .then(r => r.data)
                                .catch(e => []),
        select: (e) => e.map((e) => ({
                                        key: String(e.severity),
                                        value: e.severity,
                                        text: `${e.description} (${e.severity})`,
                                    }))
    });

    const channelTypeSwitch = () => {
        switch (channeltype) {
            case 1:
                return (
                    <SNMPNotificationForm
                        data={data}
                        severityList={severityList}
                        template={templates.find((e) => e.channeltype.id === channeltype)?.template || {}}
                        post={postNotificationConfig}
                        close={props.close}
                    />
                );
            case 2:
                return (
                    <EmailNotificationForm
                        data={data}
                        severityList={severityList}
                        template={templates.find((e) => e.channeltype.id === channeltype)?.template || {}}
                        post={postNotificationConfig}
                        close={props.close}
                    />
                );
            case 3:
                return (
                    <SMSNotificationForm
                        data={data}
                        severityList={severityList}
                        template={templates.find((e) => e.channeltype.id === channeltype)?.template || {}}
                        post={postNotificationConfig}
                        close={props.close}
                    />
                );
            default:
                return <></>;
        }
    };

    const postNotificationConfig = (values) => {
        values.template = Object.entries(values)
                                .filter(e =>  e[0].startsWith('alarm') || e[0].startsWith('clear'))
                                .map((e) => ({ [e[0].replaceAll("-", ".")]: e[1] }))
                                .reduce((e, k) => ({ ...e, ...k }), {})

        const { name, template, ...parameters } = Object.entries(values)
                                .filter(e => !e[0].startsWith('alarm') && !e[0].startsWith('clear'))
                                .map((e) => ({ [e[0].replaceAll("-", ".")]: e[1] }))
                                .reduce((e, k) => ({ ...e, ...k }), {});

        let data = {
            name: name,
            channeltype: {
                id: channeltype,
                name: props.channels.find((e) => e.value === channeltype)?.text || "Unknown",
            },
            parameters: parameters,
            template: template,
            valuechanges: {},
        };
        if (id) { data = { ...data, id: id } }
        NMService.postNotificationsChannel(data).then((r) =>
                addGlobalMessage({
                    header: "Notification channel added successfully",
                    content: "Notification channel added successfully",
                    type: "positive",
                })
            ).catch(e => null)
            .finally(() => props.close());
    };

    return (severityIsFetching ? null :
        <>
            <Header as="h3" dividing style={{ marginTop: "2rem" }}>
                {!id ? "Add new notification channel" : "Edit notification channel " + data?.channeltype.name}
                <span><Icon link name="close" style={{ float: "right" }} onClick={props.close} /></span>
            </Header>
            {!id && (
                <Dropdown
                    key={channeltype}
                    onChange={(e, { value }) => setChannelType(value)}
                    placeholder="Select channel..."
                    selection
                    selectOnBlur={false}
                    value={channeltype}
                    options={props.unused}
                    style={{ marginBottom: "2rem" }}
                />
            )}
            {channelTypeSwitch()}
        </>
    );
};

export default NotificationForm;
