import { useFetchLatestMeasurements } from "@hooks/useFetchLatestMeasurements";
import { useFetchProbeDetails } from "@hooks/useFetchProbeDetails";
import { useFetchSwModuleDetails } from "@hooks/useFetchSwModuleDetails";
import { useMemo } from "react";
import { Form, Grid, Popup } from "semantic-ui-react";

const MeasurementStatus = (props) => {
    const { moduleid, instanceid, version } = useMemo(() => props.data, [props.data]);

    const {
        data: probeDetails,
    } = useFetchProbeDetails({ probeid: props.probeId });

    const {
        data: resultModel,
    } = useFetchSwModuleDetails({
        moduleid,
        version,
        select: data => JSON.parse(data.resultmodel),
    });

    const {
        data: latestMeasurementsData,
    } = useFetchLatestMeasurements({
        hwid: probeDetails.hwid,
        moduleid,
        instanceid,
        options: {
            refetchOnMount: "always",
        },
    });

    const calculateSeenBefore = (allSeconds) => {
        let s = allSeconds < 0 ? "-" : "";
        allSeconds = Math.abs(allSeconds)
        let seconds = allSeconds % 60;
        let minutes = Math.floor(allSeconds / 60);
        let hours = Math.floor(minutes / 60);
        let days = Math.floor(hours / 24);
        minutes -= hours * 60;
        hours -= days * 24;
        return (
            s +
            (isNaN(days) || days === 0 ? "" : (days < 10 && days > 0 ? "0" : "") + days + `day${days % 10 === 1 && days !== 11 ? "" : "s"} `) +
            (isNaN(hours) ? "00" : (hours < 10 ? "0" : "") + hours) +
            ":" +
            (isNaN(minutes) ? "00" : (minutes < 10 ? "0" : "") + minutes) +
            ":" +
            (seconds < 10 ? "0" : "") +
            seconds
        );
    };

    return (latestMeasurementsData.length === 0) ? (
            <Form.Field>No recent measurement results available.</Form.Field>
        ) : (latestMeasurementsData.map((elem, i) => (
            <Popup
                key={i}
                on="click"
                style={{ minWidth: "550px" }}
                content={
                    <Grid columns={2} divided style={{ padding: "1rem" }}>
                        {Object.keys(resultModel).length !== 0 &&
                            Object.keys(elem.data).map((e) =>
                                resultModel[e]?.description ? (
                                    <Grid.Row key={e} style={{ padding: "5px" }}>
                                        <Grid.Column>
                                            {resultModel[e]?.description}
                                        </Grid.Column>
                                        <Grid.Column>
                                            {String(
                                                isNaN(elem.data[e])
                                                    ? elem.data[e]
                                                    : Number.isInteger(Number(elem.data[e]))
                                                    ? elem.data[e]
                                                    : Number(elem.data[e]).toFixed(2)
                                            ).trim()}&ensp;
                                            {resultModel[e]?.unit}
                                        </Grid.Column>
                                    </Grid.Row>
                                ) : null
                            )}
                    </Grid>
                }
                trigger={
                    <Form.Button
                        key={i}
                        type="button"
                        content={calculateSeenBefore(
                            Math.floor((new Date() - new Date(Date.parse(elem.ts))) / 1000)
                        )}
                    />
                }
            />
        ))
    );
};

export default MeasurementStatus;
