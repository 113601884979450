import NMFieldValidator from "@utils/NMFieldValidator";
import { useState } from "react";
import { Button, Icon, Input, Label, Modal } from "semantic-ui-react";

export default function TemplateClone(props) {
    const [name, setName] = useState("");
    const [nameError, setNameError] = useState("");

    const onApproveHandler = () => {
        const err = NMFieldValidator.validateString(name, /^[a-zA-Z0-9!@#%^&*._-]{1,64}$/, 1, 64, true);
        if (err) {
            setNameError(err);
            return;
        }
        props.onConfirm(name);
    };

    return (
        <Modal basic size="small" open>
            <Modal.Header>
                <Icon name="copy" />
                Clone template
            </Modal.Header>
            <Modal.Content style={{ paddingLeft: "6rem" }}>
                <p>Please enter template name</p>
                <Input
                    required
                    placeholder="Template name"
                    value={name}
                    onChange={(_, { value }) => setName(value)}
                />
                {nameError && (
                        <Label pointing="left" basic color="red">
                            {nameError}
                        </Label>
                        )}
            </Modal.Content>
            <Modal.Actions>
                <Button color="red"  inverted onClick={props.onDismiss}>
                    <Icon name="remove" />
                    Dismiss
                </Button>
                <Button color="green" inverted onClick={onApproveHandler}>
                    <Icon name="checkmark" />
                    Confirm
                </Button>
            </Modal.Actions>
        </Modal>
    );
}
