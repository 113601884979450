import CustomHeader from '@components/CustomHeader';
import { AnchorStyleButton } from '@layouts/AnchorStyleButton';
import NMService from '@services/nm.service';
import { addGlobalMessageAtom } from '@store/globalMessage';
import { useQueryClient } from '@tanstack/react-query';
import { useSetAtom } from 'jotai';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Grid, Icon, Segment, Table, TableHeader } from 'semantic-ui-react';


function ProbeData(props) {
    let { probeid } = useParams();
    const addGlobalMessage = useSetAtom(addGlobalMessageAtom);
    const queryClient = useQueryClient();

    const [showFormattedData, setShowFormattedData] = useState(true);
    const [showDataForm, setShowDataForm] = useState(props.unmanaged !== undefined);
    const [data, setData] = useState({});

    const c = { padding: "3px" }
    const h = { ...c, backgroundColor: "#f9fafb", fontWeight: "700" }
    const hasElems = (elem) => Object.entries(elem ?? {}).length !== 0;

    const prettyJSON = (data) => <Segment><pre>{JSON.stringify(data, null, 2)}</pre></Segment>

    const updateKeys = async () => {
        await NMService.updateNodeKeys(
            props.probeDetails.hwid
        ).then(() => {
            addGlobalMessage({
                header: 'Node keys are updated!',
                content: `The keys for node with ID "${probeid}" have been successfully updated.`,
                type: 'positive',
            })
            queryClient.invalidateQueries('getNodeDetails', String(probeid));
        }).catch(() => null);
    }

    const renderRow = (name, condition, func) =>
        condition && (
            <Grid.Row style={{ padding: "0.5rem 1rem" }}>
                <Grid.Column width={2}>{name}</Grid.Column>
                <Grid.Column width={14}>{func()}</Grid.Column>
            </Grid.Row>
        );

    const generalTable = () => (
        <Table celled structured size="small" compact>
            <TableHeader>
                <Table.Row textAlign="center">
                    <Table.HeaderCell style={c} width={3}>Main local IP address</Table.HeaderCell>
                    <Table.HeaderCell style={c} width={3}>OS distribution</Table.HeaderCell>
                    <Table.HeaderCell style={c}>OS info</Table.HeaderCell>
                </Table.Row>
            </TableHeader>
            <Table.Body>
                <Table.Row textAlign="center">
                    <Table.Cell style={c}>{data.localip ?? "Unknown"}</Table.Cell>
                    <Table.Cell style={c}>{data.distribution ?? "Unknown"}</Table.Cell>
                    <Table.Cell style={c}>{data.uname ?? "Unknown"}</Table.Cell>
                </Table.Row>
            </Table.Body>
        </Table>
    );

    const healthinfoTable = () => {
        const t = data.healthInfo.throttleInfo?.isThrottled === undefined ? false : true;
        const v = hasElems(data.healthInfo.voltage);
        const f = hasElems(data.healthInfo.frequency);
        const toMHz = (freq) => freq ? (freq / (1000 ** 2)).toFixed(2) : "Unknown"
        return (
            <Table celled structured size="small" compact>
                <TableHeader>
                    <Table.Row textAlign="center">
                        <Table.HeaderCell style={c} rowSpan={v || f ? '2' : '1'}>CPU temperature (°C)</Table.HeaderCell>
                        {t && <Table.HeaderCell style={c} rowSpan={v || f ? '2' : '1'}>CPU throttled</Table.HeaderCell>}
                        {v && <Table.HeaderCell style={c} colSpan='4'>Voltage (V)</Table.HeaderCell>}
                        {f && <Table.HeaderCell style={c} colSpan='3'>Frequency (MHz)</Table.HeaderCell>}
                    </Table.Row>
                    <Table.Row textAlign="center">
                        {v &&
                            <>
                                <Table.HeaderCell style={c}>Core</Table.HeaderCell>
                                <Table.HeaderCell style={c}>SDRAM_C</Table.HeaderCell>
                                <Table.HeaderCell style={c}>SDRAM_I</Table.HeaderCell>
                                <Table.HeaderCell style={c}>SDRAM_P</Table.HeaderCell>
                            </>
                        }
                        {f &&
                            <>
                                <Table.HeaderCell style={c}>Core</Table.HeaderCell>
                                <Table.HeaderCell style={c}>ARM</Table.HeaderCell>
                                <Table.HeaderCell style={c}>V3D</Table.HeaderCell>
                            </>
                        }
                    </Table.Row>
                </TableHeader>
                <Table.Body>
                    <Table.Row textAlign="center">
                        <Table.Cell style={c}>{data.healthInfo.systemTemperature?.cpuTemperature?.[0] ?? "Unknown"}</Table.Cell>
                        {t && <Table.Cell style={c}>{String(data.healthInfo.throttleInfo?.isThrottled)}</Table.Cell>}
                        {v &&
                            <>
                                <Table.Cell style={c}>{data.healthInfo.voltage.core ?? "Unknown"}</Table.Cell>
                                <Table.Cell style={c}>{data.healthInfo.voltage.sdram_c ?? "Unknown"}</Table.Cell>
                                <Table.Cell style={c}>{data.healthInfo.voltage.sdram_i ?? "Unknown"}</Table.Cell>
                                <Table.Cell style={c}>{data.healthInfo.voltage.sdram_p ?? "Unknown"}</Table.Cell>
                            </>
                        }
                        {f &&
                            <>
                                <Table.Cell style={c}>{toMHz(data.healthInfo.frequency.core)}</Table.Cell>
                                <Table.Cell style={c}>{toMHz(data.healthInfo.frequency.arm)}</Table.Cell>
                                <Table.Cell style={c}>{toMHz(data.healthInfo.frequency.v3d)}</Table.Cell>
                            </>
                        }
                    </Table.Row>
                </Table.Body>
            </Table>
        )
    }

    const memTable = () => {
        const parseMem = (mem) => Math.round(parseInt(mem.split()[0]) / 1024) + " MB";
        const parseDisk = (disk) => ((disk ?? 0) / (1024 ** 3)).toFixed(2) + " GB"

        return (
            <>
                {hasElems(data.mem) &&
                    <Table celled structured compact size="small" attached="top">
                        <Table.Body>
                            <Table.Row textAlign="center">
                                <Table.Cell style={h} width={1} rowSpan={2}>RAM</Table.Cell>
                                <Table.Cell style={h} width={5}>Total</Table.Cell>
                                <Table.Cell style={h} width={5}>Available</Table.Cell>
                                <Table.Cell style={h} width={5}>Free</Table.Cell>
                            </Table.Row>
                            <Table.Row textAlign="center">
                                <Table.Cell style={c}>{parseMem(data.mem.MemTotal)}</Table.Cell>
                                <Table.Cell style={c}>{parseMem(data.mem.MemAvailable)}</Table.Cell>
                                <Table.Cell style={c}>{parseMem(data.mem.MemFree)}</Table.Cell>
                            </Table.Row>
                        </Table.Body>
                    </Table>
                }
                {hasElems(data.disk?.["/"]) &&
                    <Table celled structured compact size="small" attached="bottom">
                        <Table.Body>
                            <Table.Row textAlign="center">
                                <Table.Cell style={h} width={1} rowSpan={2}>Disk</Table.Cell>
                                <Table.Cell style={h} width={5}>Total</Table.Cell>
                                <Table.Cell style={h} width={5}>Used</Table.Cell>
                                <Table.Cell style={h} width={5}>Free</Table.Cell>
                            </Table.Row>
                            <Table.Row textAlign="center">
                                <Table.Cell style={c}>{parseDisk(data.disk["/"].total)}</Table.Cell>
                                <Table.Cell style={c}>{parseDisk(data.disk["/"].used)}</Table.Cell>
                                <Table.Cell style={c}>{parseDisk(data.disk["/"].free)}</Table.Cell>
                            </Table.Row>
                        </Table.Body>
                    </Table>
                }
            </>
        )
    }

    const cpuTable = () => (
        <Table celled structured size="small" compact>
            <TableHeader>
                <Table.Row textAlign="center">
                    <Table.HeaderCell style={c}>Model</Table.HeaderCell>
                    <Table.HeaderCell style={c}>SBC model</Table.HeaderCell>
                    <Table.HeaderCell style={c}>SBC hardware</Table.HeaderCell>
                    <Table.HeaderCell style={c}>SBC revision</Table.HeaderCell>
                    <Table.HeaderCell style={c}>SBC serial</Table.HeaderCell>
                </Table.Row>
            </TableHeader>
            <Table.Body>
                <Table.Row textAlign="center">
                    <Table.Cell style={c}>{data.cpu.model} ({data.cpu.cores ? `${data.cpu.cores} core${Number(data.cpu.cores) !== 1 ? "s/" : "/"}` : ""}{data.cpu.threads ? `${data.cpu.threads} thread${Number(data.cpu.threads) !== 1 ? "s" : ""}` : ""})</Table.Cell>
                    <Table.Cell style={c}>{data.cpu.sbcmodel ?? "-"}</Table.Cell>
                    <Table.Cell style={c}>{data.cpu.sbchardware ?? "-"}</Table.Cell>
                    <Table.Cell style={c}>{data.cpu.sbcrevision ?? "-"}</Table.Cell>
                    <Table.Cell style={c}>{data.cpu.sbcserial ?? "-"}</Table.Cell>
                </Table.Row>
            </Table.Body>
        </Table>
    );

    const modulesTable = () => (
        <Table celled structured size="small" compact>
            <TableHeader>
                <Table.Row textAlign="center">
                    <Table.HeaderCell style={c}>Module ID / instance</Table.HeaderCell>
                    <Table.HeaderCell style={c}>Module version</Table.HeaderCell>
                    <Table.HeaderCell style={c}>Last modified</Table.HeaderCell>
                </Table.Row>
            </TableHeader>
            <Table.Body>
                {data.modules?.map(e =>
                    <Table.Row key={e.name} textAlign="center">
                        {/* <Table.Cell style={c}>{e.name.split(/\.(.*)/s)?.splice(0,2)?.join(" / ")}</Table.Cell> */}
                        <Table.Cell style={c}>{e.name.split(".")?.join(" / ")}</Table.Cell>
                        <Table.Cell style={c}>{e.version}</Table.Cell>
                        <Table.Cell style={c}>{e.configModified}</Table.Cell>
                    </Table.Row>
                )}
            </Table.Body>
        </Table>
    );

    const ifacesTable = () => (
        <Table celled structured size="small" compact>
            <TableHeader>
                <Table.Row textAlign="center">
                    <Table.HeaderCell style={c}>Namespace</Table.HeaderCell>
                    <Table.HeaderCell style={c}>Interface name</Table.HeaderCell>
                    <Table.HeaderCell style={c}>State</Table.HeaderCell>
                    <Table.HeaderCell style={c}>MAC</Table.HeaderCell>
                    <Table.HeaderCell style={c}>IP</Table.HeaderCell>
                </Table.Row>
            </TableHeader>
            {Object.entries(data.interfaces)?.map((iface, i) =>
                <Table.Body key={i}>
                    {Object.entries(iface[1]).map((e, i) => (
                        <Table.Row key={e[0]} textAlign="center">
                            {i === 0 && <Table.Cell style={c} rowSpan={Object.entries(iface[1]).length}>{iface[0]}</Table.Cell>}
                            <Table.Cell style={c}>{e[1].iface}</Table.Cell>
                            <Table.Cell style={c}>{e[1].up ? "up" : "down"}</Table.Cell>
                            <Table.Cell style={c}>{e[1].mac}</Table.Cell>
                            <Table.Cell style={c}>{e[1].ip}</Table.Cell>
                        </Table.Row>
                    ))}
                </Table.Body>)}
        </Table>
    );

    const keysTable = () => {
        const isSignKeyMatching =
            props.probeDetails?.signPublicKey ===
            props.registry?.keys?.signPublicKey;

        const isEncKeyMatching =
            props.probeDetails?.encPublicKey ===
            props.registry?.keys?.encPublicKey;

        return (
            <Table celled structured size="small" compact>
                <TableHeader>
                    <Table.Row textAlign="center">
                        <Table.HeaderCell style={c}>
                            Sign public key
                        </Table.HeaderCell>
                        <Table.HeaderCell style={c}>
                            Encryption public key
                        </Table.HeaderCell>
                        <Table.HeaderCell
                            style={c}
                            width={2}
                        ></Table.HeaderCell>
                    </Table.Row>
                </TableHeader>

                <Table.Body>
                    <Table.Row textAlign="center">
                        <Table.Cell style={c}>
                            <Icon
                                name={isSignKeyMatching ? "checkmark" : "close"}
                                color={isSignKeyMatching ? "green" : "red"}
                            />
                        </Table.Cell>
                        <Table.Cell style={c}>
                            <Icon
                                name={isEncKeyMatching ? "checkmark" : "close"}
                                color={isEncKeyMatching ? "green" : "red"}
                            />
                        </Table.Cell>
                        <Table.Cell style={c}>
                            {!(isSignKeyMatching && isEncKeyMatching) ? (
                                <AnchorStyleButton onClick={() => updateKeys()}>
                                    <Icon name="sync" />
                                    Update keys
                                </AnchorStyleButton>
                            ) : null}
                        </Table.Cell>
                    </Table.Row>
                </Table.Body>
            </Table>
        );
    };

    const timedateTable = () => {
        const checkIcon = (condition) => <Icon name={condition ? "checkmark" : "close"} color={condition ? "green" : "red"} />
        return (
            <Table celled structured size="small" compact>
                <TableHeader >
                    <Table.Row textAlign="center">
                        <Table.HeaderCell style={c}>Timezone</Table.HeaderCell>
                        <Table.HeaderCell style={c}>Local RTC</Table.HeaderCell>
                        <Table.HeaderCell style={c}>Can NTP</Table.HeaderCell>
                        <Table.HeaderCell style={c}>NTP</Table.HeaderCell>
                        <Table.HeaderCell style={c}>NTP sync</Table.HeaderCell>
                    </Table.Row>
                </TableHeader>
                <Table.Body>
                    <Table.Row textAlign="center">
                        <Table.Cell style={c}>{data.timedatectl.Timezone}</Table.Cell>
                        <Table.Cell style={c}>{checkIcon(data.timedatectl.LocalRTC === "yes")}</Table.Cell>
                        <Table.Cell style={c}>{checkIcon(data.timedatectl.CanNTP === "yes")}</Table.Cell>
                        <Table.Cell style={c}>{checkIcon(data.timedatectl.NTP === "yes")}</Table.Cell>
                        <Table.Cell style={c}>{checkIcon(data.timedatectl.NTPSynchronized === "yes")}</Table.Cell>
                    </Table.Row>
                </Table.Body>
            </Table>
        )
    }

    const renderChrony = () => (
        ["sources", "tracking"].map((e, i) =>
            hasElems(data.chrony?.[e]) &&
            <Grid.Row key={e} style={{ padding: "0.5rem 1rem" }}>
                <Grid.Column width={1}>{!i || !hasElems(data.chrony?.sources) ? "Chrony:" : ""}</Grid.Column>
                <Grid.Column width={1}>{e}</Grid.Column>
                <Grid.Column width={14}>{chronyTable(!i)}</Grid.Column>
            </Grid.Row>
        )
    )

    const chronyTable = (sources) => {
        const expoToFloat = (num) => {
            if (!String(num).includes("e")) {
                return num;
            } else {
                let numstr = String(num).split("e-")
                let exp = numstr[0].length - 2 - (numstr[0].charAt(0) === "-" ? 1 : 0);
                return num.toFixed(exp + Number(numstr[1]))
            }
        }
        return (
            <>
                {sources ?
                    <Table celled structured size="small" compact>
                        <TableHeader>
                            <Table.Row textAlign="center">
                                <Table.HeaderCell style={c}>Mode</Table.HeaderCell>
                                <Table.HeaderCell style={c}>State</Table.HeaderCell>
                                <Table.HeaderCell style={c}>IP</Table.HeaderCell>
                                <Table.HeaderCell style={c}>Stratum</Table.HeaderCell>
                                <Table.HeaderCell style={c}>Poll</Table.HeaderCell>
                                <Table.HeaderCell style={c}>Reach</Table.HeaderCell>
                                <Table.HeaderCell style={c}>Last RX</Table.HeaderCell>
                                <Table.HeaderCell style={c}>Offset1</Table.HeaderCell>
                                <Table.HeaderCell style={c}>Offset2</Table.HeaderCell>
                                <Table.HeaderCell style={c}>Error</Table.HeaderCell>
                            </Table.Row>
                        </TableHeader>
                        <Table.Body>
                            {data.chrony.sources.map((e, i) => (
                                <Table.Row key={i} textAlign="center">
                                    <Table.Cell style={c}>{e.mode}</Table.Cell>
                                    <Table.Cell style={c}>{e.state}</Table.Cell>
                                    <Table.Cell style={c}>{e.ip}</Table.Cell>
                                    <Table.Cell style={c}>{e.stratum}</Table.Cell>
                                    <Table.Cell style={c}>{e.poll}</Table.Cell>
                                    <Table.Cell style={c}>{e.reach}</Table.Cell>
                                    <Table.Cell style={c}>{e.lastrx}</Table.Cell>
                                    <Table.Cell style={c}>{e.offset1}</Table.Cell>
                                    <Table.Cell style={c}>{e.offset2}</Table.Cell>
                                    <Table.Cell style={c}>{e.error}</Table.Cell>
                                </Table.Row>
                            ))}
                        </Table.Body>
                    </Table>
                    :
                    <>
                        <Table attached="top" celled structured size="small" compact>
                            <TableHeader>
                                <Table.Row textAlign="center">
                                    <Table.HeaderCell style={c}>Ref ID</Table.HeaderCell>
                                    <Table.HeaderCell style={c}>IP</Table.HeaderCell>
                                    <Table.HeaderCell style={c}>Stratum</Table.HeaderCell>
                                    <Table.HeaderCell style={c}>Ref time</Table.HeaderCell>
                                    <Table.HeaderCell style={c}>System time</Table.HeaderCell>
                                    <Table.HeaderCell style={c}>Last offset</Table.HeaderCell>
                                    <Table.HeaderCell style={c}>RMS Offset</Table.HeaderCell>
                                </Table.Row>
                            </TableHeader>
                            <Table.Body>
                                <Table.Row textAlign="center">
                                    <Table.Cell style={c}>{data.chrony.tracking.referenceID}</Table.Cell>
                                    <Table.Cell style={c}>{data.chrony.tracking.ip}</Table.Cell>
                                    <Table.Cell style={c}>{data.chrony.tracking.stratum}</Table.Cell>
                                    <Table.Cell style={c}>{data.chrony.tracking.referenceTime}</Table.Cell>
                                    <Table.Cell style={c}>{expoToFloat(data.chrony.tracking.systemTime)}</Table.Cell>
                                    <Table.Cell style={c}>{data.chrony.tracking.lastOffset}</Table.Cell>
                                    <Table.Cell style={c}>{data.chrony.tracking.RMSOffset}</Table.Cell>
                                </Table.Row>
                            </Table.Body>
                        </Table>
                        <Table attached="bottom" celled structured size="small" compact>
                            <TableHeader>
                                <Table.Row textAlign="center">
                                    <Table.HeaderCell style={c}>Freq</Table.HeaderCell>
                                    <Table.HeaderCell style={c}>Residual freq</Table.HeaderCell>
                                    <Table.HeaderCell style={c}>Skew</Table.HeaderCell>
                                    <Table.HeaderCell style={c}>Root delay</Table.HeaderCell>
                                    <Table.HeaderCell style={c}>Root dispersion</Table.HeaderCell>
                                    <Table.HeaderCell style={c}>Update interval</Table.HeaderCell>
                                    <Table.HeaderCell style={c}>Leap status</Table.HeaderCell>
                                </Table.Row>
                            </TableHeader>
                            <Table.Body>
                                <Table.Row textAlign="center">
                                    <Table.Cell style={c}>{data.chrony.tracking.frequency}</Table.Cell>
                                    <Table.Cell style={c}>{data.chrony.tracking.residualFrequency}</Table.Cell>
                                    <Table.Cell style={c}>{data.chrony.tracking.skew}</Table.Cell>
                                    <Table.Cell style={c}>{data.chrony.tracking.rootDelay}</Table.Cell>
                                    <Table.Cell style={c}>{data.chrony.tracking.rootDispersion}</Table.Cell>
                                    <Table.Cell style={c}>{data.chrony.tracking.updateInterval}</Table.Cell>
                                    <Table.Cell style={c}>{data.chrony.tracking.leapStatus}</Table.Cell>
                                </Table.Row>
                            </Table.Body>
                        </Table>
                    </>
                }
            </>
        )
    }

    const mobilehatTable = () => (
        <>
            <Table attached="top" celled structured size="small" compact>
                <TableHeader >
                    <Table.Row textAlign="center">
                        <Table.HeaderCell style={c}>MSISDN</Table.HeaderCell>
                        <Table.HeaderCell style={c}>IMSI</Table.HeaderCell>
                        <Table.HeaderCell style={c}>CCID</Table.HeaderCell>
                        <Table.HeaderCell style={c}>IMEI</Table.HeaderCell>
                    </Table.Row>
                </TableHeader>
                <Table.Body>
                    <Table.Row textAlign="center">
                        <Table.Cell style={c}>{data.mobilehatdata.msisdn ?? "-"}</Table.Cell>
                        <Table.Cell style={c}>{data.mobilehatdata.imsi ?? "-"}</Table.Cell>
                        <Table.Cell style={c}>{data.mobilehatdata.ccid ?? "-"}</Table.Cell>
                        <Table.Cell style={c}>{data.mobilehatdata.imei ?? "-"}</Table.Cell>
                    </Table.Row>
                </Table.Body>
            </Table>
            <Table attached="bottom" celled structured size="small" compact>
                <TableHeader >
                    <Table.Row textAlign="center">
                        <Table.HeaderCell style={c}>CGMI</Table.HeaderCell>
                        <Table.HeaderCell style={c}>CGMM</Table.HeaderCell>
                        <Table.HeaderCell style={c}>CGMR</Table.HeaderCell>
                        <Table.HeaderCell style={c}>CQCNV</Table.HeaderCell>
                        <Table.HeaderCell style={c}>GCAP</Table.HeaderCell>
                    </Table.Row>
                </TableHeader>
                <Table.Body>
                    <Table.Row textAlign="center">
                        <Table.Cell style={c}>{data.mobilehatdata.cgmi ?? "-"}</Table.Cell>
                        <Table.Cell style={c}>{data.mobilehatdata.cgmm ?? "-"}</Table.Cell>
                        <Table.Cell style={c}>{data.mobilehatdata.cgmr ?? "-"}</Table.Cell>
                        <Table.Cell style={c}>{data.mobilehatdata.cqcnv ?? "-"}</Table.Cell>
                        <Table.Cell style={c}>{data.mobilehatdata.gcap ?? "-"}</Table.Cell>
                    </Table.Row>
                </Table.Body>
            </Table>
        </>
    );

    useEffect(() => {
        if (props.unmanaged) {
            setData({ ...props.unmanaged.data, mobilehatdata: props.unmanaged.mobilehatdata, healthInfo: props.unmanaged.healthInfo })
        } else if (probeid) {
            NMService.getRegistry()
                .then(r => r.data)
                .then(r => r.find((e) => e.nodeid === Number(probeid)))
                .then(r => setData(r ? { ...(r.data ?? {}), mobilehatdata: r.mobilehatdata, healthInfo: r.healthInfo } : {}))
                .catch(e => null)
        }
    }, [props.unmanaged, probeid]);

    return (
        <>
            <CustomHeader
                toggle={() => setShowDataForm(p => !p)}
                showCaret={!props.unmanaged}
                show={showDataForm}
                title={
                    <>
                        {props.unmanaged ? "Unmanaged node data" : "Node data"}
                        <span>
                            <Icon
                                style={{ padding: "0 0 0 1rem", zIndex: 5 }}
                                name="exchange"
                                color="blue"
                                onClick={(e) => {
                                    e.stopPropagation()
                                    setShowDataForm(true);
                                    setShowFormattedData(p => !p);
                                }}
                            />
                        </span>
                    </>
                }
            />
            {data && showDataForm && showFormattedData && (
                <Grid style={{ padding: '2rem 1rem' }}>
                    {renderRow("General info", true, generalTable)}
                    {renderRow("Health info", hasElems(data.healthInfo), healthinfoTable)}
                    {renderRow("Memory", hasElems(data.mem) || hasElems(data.disk), memTable)}
                    {renderRow("CPU", hasElems(data.cpu), cpuTable)}
                    {renderRow("Modules", hasElems(data.modules), modulesTable)}
                    {renderRow("Interfaces", hasElems(data.interfaces), ifacesTable)}
                    {renderRow("Timedate", hasElems(data.timedatectl), timedateTable)}
                    {renderRow("Keys", true, keysTable)}
                    {renderChrony()}
                    {renderRow("Mobile hat", hasElems(data.mobilehatdata), mobilehatTable)}
                </Grid>
            )}

            {showDataForm && !showFormattedData && prettyJSON(data)}
        </>
    );
}

export default (ProbeData);
