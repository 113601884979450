import Crontab from '@components/Crontab';
import { useFormFields } from '@hooks/useFormFields';
import { PermissionsGate } from '@layouts/PermissionGate';
import NMFieldValidator from '@utils/NMFieldValidator';
import _ from 'lodash';
import { useCallback, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { Form } from 'semantic-ui-react';

function RunningConfig(props) {
    const { templateid, moduleid, instanceid, version, runconfig } = useMemo(() => props.data, [props.data]);
    const defaultPeriodic = { randomoffset: 0, period: { minute: "*", hour: "*", dayOfMonth: "*", month: "*", dayOfWeek: "*" }}
    const {
        register,
        watch,
        reset,
        setValue,
        handleSubmit,
        formState: { errors },
    } = useForm({ defaultValues: { runmethod: 'ondemand', ...defaultPeriodic } });
    const { renderInput, renderDropdown } = useFormFields({ register, errors, setValue, watch });
    const runmethodWatch = watch('runmethod');
    const runMethodOptions = [
        { key: 'ondemand', value: 'ondemand', text: 'On demand' },
        { key: 'periodic', value: 'periodic', text: 'Periodic' },
        { key: 'asservice', value: 'asservice', text: 'As service' },
    ]

    const onSubmit = (values) => {
        if (values.runmethod !== 'periodic') {
            delete values.period
            delete values.randomoffset
        }

        if (_.isEqual(values, JSON.parse(runconfig))) {
            props.noConfigChangeMessage()
            return
        }

        let data = {
            templateid,
            moduleid,
            instanceid,
            version,
            runconfig: JSON.stringify(values),
        };
        props.onFormSubmit(data)
    };

    const resetValues = useCallback(() => {
        if (runconfig) {
            let conf = JSON.parse(runconfig)
            if (conf.runmethod !== 'periodic') {conf = ({ ...conf, ...defaultPeriodic })}
            reset(conf)
        }
    }, [runconfig, reset]) // eslint-disable-line

    useEffect(() => {
        resetValues()
    }, [resetValues])

    return (
        <Form onSubmit={handleSubmit(onSubmit)} style={{ margin: '0 2rem 0 2rem' }}>
            <Form.Group widths={6}>
                <Form.Field>Run method</Form.Field>
                {renderDropdown('Run method', 'runmethod', runMethodOptions, { hideLabel: true })}
            </Form.Group>
            {runmethodWatch === 'periodic' && (
                    <>
                        <Crontab errors={errors} register={register} setValue={setValue} watch={watch}/>
                        <Form.Group widths={6}>
                            <Form.Field>Max random offset time</Form.Field>
                            {renderInput('Max random offset time', 'randomoffset', { validate: (v) => NMFieldValidator.validateRandomoffset(v), unit: 's', hideLabel: true })}
                        </Form.Group>
                    </>
                )}
            <Form.Group>
                <PermissionsGate hasPermission={props.hasPermission}>
                    <Form.Button
                        size="small"
                        type="submit"
                        primary
                        content="Update"
                    />
                </PermissionsGate>
                <PermissionsGate hasPermission={props.hasPermission}>
                    <Form.Button
                        size="small"
                        type="button"
                        content="Reset"
                        onClick={() => resetValues()}
                    />
                </PermissionsGate>
            </Form.Group>
        </Form>
    );
}

export default RunningConfig;
